import React, { useEffect, useState } from "react";
import styled from "styled-components";

function RandingTimer({ children, ...props }) {
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [date, setDate] = useState(0);
  const [hours, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    try {
      const { deadline } = props;
      //const deadline = '2022-02-23 14:38:50';
      const deadline_arr = deadline.split(" ");
      const date = deadline_arr[0].split("-");
      setYear(date[0]);
      setDate(parseInt(date[2])>9 ? date[2] : '0'+date[2]);

      /**
       @월
       Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec
      */
      switch (parseInt(date[1])) {
        case 1: setMonth("Jan");
          break;
        case 2: setMonth("Feb");
          break;
        case 3: setMonth("Mar");
          break;
        case 4: setMonth("Apr");
          break;
        case 5: setMonth("May");
          break;
        case 6: setMonth("Jun");
          break;
        case 7: setMonth("Jul");
          break;
        case 8: setMonth("Aug");
          break;
        case 9: setMonth("Sep");
          break;
        case 10: setMonth("Oct");
          break;
        case 11: setMonth("Nov");
          break;
        case 12: setMonth("Dec");
          break;

        default:
          break;
      }
      let now = new Date();
      let arr = deadline.split(/[- :]/);
      let DeadLine = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);

      const _seconds = parseInt((DeadLine.getTime() - now.getTime()) / 1000);
      const _hours = parseInt(_seconds / 3600);
      const _minutes = parseInt((_seconds - _hours * 3600) / 60);

      setHour(_hours);
      setMinutes(_minutes);
      setSeconds(_seconds - (_hours * 3600 + _minutes * 60));
    } catch (error) {
      console.log(error);
    }
  }, [props]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          if (parseInt(hours === 0)) {
            clearInterval(countdown);
          } else {
            setHour(parseInt(hours) - 1);
          }
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [hours, minutes, seconds]);

  return (
    <Div {...props}>
      <FlexDiv type={1}>
        {month} {date}, {year}
      </FlexDiv>
      <FlexDiv type={2}>
        {hours < 10 ? `0${hours}` : hours}h :{" "}
        {minutes < 10 ? `0${minutes}` : minutes}m :{" "}
        {seconds < 10 ? `0${seconds}` : seconds}s
      </FlexDiv>
    </Div>
  );
}

const Div = styled.div`
  height: 50px;
  width: 160px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin : 0;
  border: 0.5px solid #ffffff;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
  }

  ${(props) =>
    props.type === "RandingPage" &&
    `
        margin : 20px;
        bottom : 0;
        right : 0;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
            border: 0.5px solid #000000;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            color : #ffffff;
            margin : 20px 0;
            border: 0.5px solid #ffffff;
        }
  `}
  
  ${(props) =>
    props.type === "Subnft" &&
    `
        color : #06C555;
        margin : 430px auto;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
            border: 1px solid #06C555;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 auto;
            position : absolute;
            border: 1px solid #06C555;
        }
  `}
  ${(props) =>
    props.type === "Mainnft" &&
    `
        color : #06C555;
        margin : 0px auto 0px auto;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            border: 1px solid #06C555;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            border: 1px solid #06C555;
        }
  `}


`;
const FlexDiv = styled.div`
  dlsplay: flex;
  ${(props) =>
    props.type === 1 &&
    `
        width : 130px;
        justify-content : flex-end;
        font-family : RBD;
        font-size : 10px;
        text-align : right;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 110px;
        }
    `}
  ${(props) =>
    props.type === 2 &&
    `
        width : 150px;
        font-family : RBD;
        font-size : 18px;
        text-align : center;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 14px;
            width : 130px;
        }
    `}
`;

export default RandingTimer;
