import React from 'react';
import styled from 'styled-components';
function TextDiv({children, ...props}) {
    return (
        <Div {...props}>
            {children}
        </Div>
    );
}
const Div = styled.div`
    margin : ${props=>props.margin || '0 auto'};
    width : 760px;
    font-family : ${props=>props.fontFamily || 'PoppinsRegular'};
    font-size : ${props=>props.fontSize};
    color : ${props=>props.color};
    width : ${props=>props.width};
    height : ${props=>props.height};
    margin : ${props=>props.margin};
    padding : ${props=>props.padding};
    display : ${props=>props.display};
    justify-content : ${props=>props.justify};
    align-items : ${props=>props.alignItems};
    flex-direction : ${props=>props.flexDirection};
    background-color : ${props=>props.background};
    text-align : ${props=>props.textAlign};


    ${props=>props.type==='title'&&`
    font-family : PoppinsMedium;
    font-size: 48px;
    line-height: 72px;
    color: #EEEEEE;
    `}
    ${props=>props.type==='subTitle'&&`
    font-family : PoppinsSemiBold;
    font-size: 32px;
    line-height: 48px;
    color: #EEEEEE;
    `}
    ${props=>props.type==='subTitleS'&&`
    font-family : PoppinsSemiBold;
    font-size: 24px;
    line-height: 36px;
    color: #EEEEEE;
    `}
    ${props=>props.type==='contentS'&&`
    font-family : PoppinsLight;
    font-size: 12px;
    line-height: 22px;
    color: #A0A0A0;
    `}
    ${props=>props.type==='contentM'&&`
    font-family : PoppinsLight;
    font-size: 14px;
    line-height: 26px;
    color: #A0A0A0;
    `}
    ${props=>props.type==='contentB'&&`
    font-family : PoppinsLight;
    font-size: 16px;
    color: #A0A0A0;
    `}
    
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 312px;
    }
`;
export default TextDiv;