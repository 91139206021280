import React from 'react';
import styled from "styled-components";
import Section5Diagram from "../img/Section5Diagram.png";


const Section5 = styled.section`
  padding: 120px 0 180px 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding: 80px 0 100px 0;
  }
`;
const ContentBox = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #909090;
`;
const ContentBottomBox = styled.div`
  display: flex;
  margin-top: 40px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
  }
`;
const Content1 = styled.div`
  font-size: 48px;
  line-height: 72px;
  color: rgba(6, 199, 85, 1);
  width: 444px;
  margin-right: 56px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
    width : 86vw;
    margin-right: 0;
  }
`;
const Content2 = styled.div`
font-family: PoppinsRegular;
  font-size: 14px;
  line-height: 26px;
  color: #EEEEEE;
  width: 444px;
  margin: 16px 0 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
  }
`;
const Diagram = styled.img.attrs({
  src : Section5Diagram || ""
})`
  width: 940px;
  margin-top: 80px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
    margin-top: 40px;
  }
`;
function AboutSection5(props) {
    return (
        <Section5 id="BecomeASponsor">
          <ContentBox>
            Become a Sponsor
            <ContentBottomBox>
              <Content1>
                Become a Sponsor
              </Content1>
              <Content2>
                Halliday's mission is to donate part of the proceeds to select charity organizations. This is mandatory for every single sales on Halliday's and even from secondary trade fees.
                <br/><br/>
                Collaboration with K-Celebrities and renown digital artists will appeal to the mass market and help create awareness for charity.
              </Content2>
            </ContentBottomBox>
          </ContentBox>
          <Diagram/>
        </Section5>
    );
}

export default AboutSection5;