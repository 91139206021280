import React from 'react';
import styled from 'styled-components';

function TextUl({children, ...props}) {
    return (
        <Ul {...props}>
            {children}
        </Ul>
    );
}

const Ul = styled.ul`
    margin : 0 auto;
    width : 760px;
    li:: marker {
        color : #A0A0A0;
    }
    ${props=>props.type===1&&`
        list-style-type : decimal;
    `}
    ${props=>props.type===2&&`
        list-style-type : lower-alpha
        ;
    `}
        
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 312px;
    }
`;

export default TextUl;