import mousai_desktop from "../components/home/img/mousai_desktop.png";
import mousai_mobile from "../components/home/img/mousai_desktop.png";
import gaeko_desktop from "../components/home/img/gaeko_desktop.png";
import gaeko_mobile_active from "../components/home/img/gaeko_mobile.png";
import cheetah_desktop from "../components/home/img/cheetah_desktop.png";
import cheetah_mobile_active from "../components/home/img/cheetah_mobile.png";
import yura_desktop from "../components/home/img/yura_desktop.png";
import yura_mobile_active from "../components/home/img/yura_mobile.png";
import hajungwoo_desktop from "../components/home/img/hajungwoo_desktop.png";
import hajungwoo_mobile_active from "../components/home//img/hajungwoo_mobile.png";

export const showcaseData = [
  {
    id: 1,
    isUpcoming: false,
    artist: "Halliday's",
    title: "MOUSAI",
    desc: "10,000 MOUSAI are here to inspire, define and illuminate the future",
    desktopImg: mousai_desktop,
    mobileImg: mousai_mobile,
    link: "https://mousai.club/",
  },
  {
    id: 2,
    isUpcoming: false,
    artist: "Gaeko of Dynamic Duo",
    title: "PROJECT REBIRTH",
    desc: "",
    desktopImg: gaeko_desktop,
    mobileImg: gaeko_mobile_active,
    link: "/rebirth",
  },
  {
    id: 3,
    isUpcoming: true,
    artist: "Cheetah",
    title: "COMING SOON",
    desc: "",
    desktopImg: cheetah_desktop,
    mobileImg: cheetah_mobile_active,
  },
  {
    id: 4,
    isUpcoming: true,
    artist: "Yura of Girl’s Day",
    title: "COMING SOON",
    desc: "",
    desktopImg: yura_desktop,
    mobileImg: yura_mobile_active,
  },
  {
    id: 5,
    isUpcoming: true,
    artist: "Ha Jung Woo",
    title: "COMING SOON",
    desc: "",
    desktopImg: hajungwoo_desktop,
    mobileImg: hajungwoo_mobile_active,
  },
];
