import React from 'react';
import styled from "styled-components";
import Section2Diagram1 from "../img/Section2Diagram1.png";
import Section2Diagram2 from "../img/Section2Diagram2.png";
import Section2Diagram3 from "../img/Section2Diagram3.png";
import Section2Diagram4 from "../img/Section2Diagram4.png";


const Section2 = styled.section`
  font-family: PoppinsMedium;
  padding : 120px 0 0 0;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding : 80px 0 0 0;
  }
`;

/* -------------------------------------------------- */

const Box1 = styled.div`


  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;
const Box1TopBox = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #909090;
  padding : 0 0 40px 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-weight : normal;
  }
`;
const Box1CenterBox = styled.div`
  font-size: 48px;
  line-height: 72px;
  color: #06C755;
  font-family: PoppinsMedium;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 8vw;
    line-height: 42px;
    margin-bottom : 16px;
  }
`;
const Box1BottomBox = styled.div`
  display : flex;
  font-size: 14px;
  line-height: 26px;
  color: #EEEEEE;
  font-family: PoppinsRegular;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
  }
`;
const Box1BottomContent1 = styled.div`
  width : 444px;
  margin : 16px 56px 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
    margin:0;
  }
`;
const Box1BottomContent2 = styled.div`
  width : 444px;
  margin : 16px 0 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
  }
`;

const Diagram1 = styled.img.attrs({
  src : Section2Diagram1 || ""
})`
  width : 944px;
  padding : 80px 0 160px 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
    padding : 40px 0 80px 0;
  }
`;

/* -------------------------------------------------- */

const Box2 = styled.div`

`;
const Box2ContentBox = styled.div`
  display : flex;
  padding : 176px 0 0 0;
  font-family: PoppinsRegular;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
    padding : 80px 0 0 0;
  }
`;
const Box2Content1 = styled.div`
  font-size: 48px;
  line-height: 45px;
  color: #06C755;
  width : 444px;
  margin : 0 56px 0 0;
  font-family: PoppinsMedium;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 8vw;
    line-height: 42px;
    width : 86vw;
    margin: 0;
  }
`;
const Box2Content2 = styled.div`
  font-size: 14px;
  line-height: 26px;
  width : 444px;
  color: #EEEEEE;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
    margin: 16px 0 0 0;
  }
`;
const Diagram2 = styled.img.attrs({
  src : Section2Diagram2 || ""
})`
  width : 595px;
  margin : 80px 174.5px 160px 174.5px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
    margin: 40px 0 80px 0;
  }
`;

/* -------------------------------------------------- */

const Box3 = styled.div`
  padding: 160px 0;
`;
const Box3TopBox = styled.div`
  font-family: PoppinsMedium;
  font-size: 48px;
  line-height: 72px;
  color: #06C755;
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
  }
`;
const Box3BottomBox = styled.div`
  display : flex;
  font-size: 14px;
  line-height: 26px;
  color: #EEEEEE;
  padding : 16px 0 80px 0;
  font-family: PoppinsRegular;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
    padding : 16px 0 40px 0;
  }
`;
const Box3BottomContent1 = styled.div`
  width : 444px;
  padding : 0 56px 0 0;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
    padding:0;
  }
`;
const Box3BottomContent2 = styled.div`
  width : 444px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
    margin : 30px 0 0 0;
  }
`;
const Diagram3 = styled.img.attrs({
  src : Section2Diagram3 || ""
})`
  width : 940px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;

/* -------------------------------------------------- */

const Box4 = styled.div`
  padding : 176px 0 180px 0;
`;
const Box4ContentBox = styled.div`
  display : flex;
  font-family: PoppinsRegular;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
  }
`;
const Box4Content1 = styled.div`
  font-size: 48px;
  line-height: 72px;
  color: #06C755;
  width : 444px;
  padding-right : 100px;
  font-family: PoppinsMedium;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
    width : 86vw;
    padding:0;
  }
`;
const Box4Content2 = styled.div`
  font-size: 14px;
  line-height: 26px;
  width : 444px;
  color: #EEEEEE;
  margin : 16px 0 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
  }
`;
const Diagram4 = styled.img.attrs({
  src : Section2Diagram4 || ""
})`
  width : 940px;
  padding : 80px 0 0 0;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;

function AboutSection2(props) {
    return (
        <Section2>
          <Box1>
            <Box1TopBox>
              Our Platform
            </Box1TopBox>
            <Box1CenterBox>
              How Does Halliday's Work?
            </Box1CenterBox>
            <Box1BottomBox>
              <Box1BottomContent1>
                  Digital art creators, celebrities, brands, and influencers come to Halliday’s to create premium NFT collections that will be exclusively available on this platform. This rare collaborative digital art will then be launched on Halliday’s platform for fans across the world to purchase.
              </Box1BottomContent1>
              <Box1BottomContent2>
                  To date, we’ve partnered with individuals such as Korean legendary rapper and producer Gaeko of Dynamic Duo, actor Ha Jungwoo, and world-renowned digital artist Vector Meldrew, amongst many others. Our aim is to bring mass-market appreciation and awareness to K-celebrities. This increased exposure benefits fans, NFT collectors, digital artists, and K-celebrities themselves. With greater demand, art becomes rarer and sought after, and Halliday’s is the perfect platform for them to come together on joint collaboration projects.
              </Box1BottomContent2>
            </Box1BottomBox>
            <Diagram1/>
          </Box1>
          <Box2>
            <Box2ContentBox>
              <Box2Content1>
                A Sharing Economy
              </Box2Content1>
              <Box2Content2>
                  We believe in using wealth and celebrity status in a positive and productive manner. One focus of our project is to team up with global partners to support charitable fundraising efforts, with a portion of every transaction dedicated to donations. It’s important to us to create an awesome experience for fans, collectors, and creators, and we aim to enable everybody involved in this platform to benefit greatly.
              </Box2Content2>
            </Box2ContentBox>
            <Diagram2/>  
          </Box2>
          <Box3>
            <Box3TopBox>
              What Is An NFT?
            </Box3TopBox>
            <Box3BottomBox>
                <Box3BottomContent1>
                    An NFT or Non-Fungible Token is a unique digital item that you can truly own. Examples of NFTs include art, music, video, film, metaverse clothing, and much more. Digital art and NFTs are an exciting new development with endless possibilities, and just like physical assets, once you own them, you can do whatever you want with them.
                </Box3BottomContent1>
                <Box3BottomContent2>
                    Ownership of an NFT is incredibly safe and secure. Unlike physical assets, they’re armed with the defenses of blockchain technology – digital contracts, transaction history, and authentication stamps ensure the validity of all artwork, keeping people from being able to access and sell the NFTs you own. A good example would be to think of the Mona Lisa, anyone in the world can print a picture from the internet, but it doesn’t mean they have the original Mona Lisa. Right?
                </Box3BottomContent2>
            </Box3BottomBox>
            <Diagram3/>
          </Box3>
          <Box4>
            <Box4ContentBox>
              <Box4Content1>
                How Do I Get Halliday's NFT?
              </Box4Content1>
              <Box4Content2>
                  Our NFT drops will happen frequently, and we’ll be sure to notify all users of upcoming collection launches in plenty of time. Our aim is to release new drops once every three weeks. To increase rarity and hype, they’ll only be available for a limited time – you’ll have to be quick to buy an NFT. If the NFT collection sells out or the sale time expires, you will only ever be able to purchase the NFT from other open marketplaces.
              </Box4Content2>
            </Box4ContentBox>
            <Diagram4/>
          </Box4>
        </Section2>
    );
}

export default AboutSection2;