import React from 'react';
import styled from "styled-components";
import StyledButton from '../../common/Button';
import { useMediaQuery } from "react-responsive";

const Section6 = styled.section`
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 140px 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding: 30vw 0;
  }
`;
const Content1 = styled.div`
  font-size: 48px;
  line-height: 72px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
  }
`;
const Content2 = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 40px 0;
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 14px;
    line-height: 21px;
    width: 86vw;
    margin-bottom: 32px;
  }
`;

function AboutSection6(props) {
  const buttonStyle = {
    fontSize: "16px",
    borderRadius: "35px",
    color: "#FFFFFF",
    width: "178px",
    height: "56px",
    fontFamily: "PoppinsSemiBold",
    background: "rgba(6, 199, 85, 1)",
    margin : "0",
    page : "#BecomeASponsor"
  }
  const isPc = useMediaQuery ({
    query: "(min-width:768px)"
  });
  return (
    <Section6>
      <Content1>
        Become a Sponsor
      </Content1>
      <Content2>
        {isPc ? <p>Join us to help create awareness for the charity!</p> : <p>Join Halliday's to achieve <br/>social sustainability</p>}
      </Content2>
      <StyledButton style={buttonStyle}>Become a Sponsor</StyledButton>
    </Section6>
  );
}
export default AboutSection6;