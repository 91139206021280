import React from "react";
import Link from "../../js/link.js";
import styled from "styled-components";
import InstagramImg from "../common/img/instagram_basic.png";
import InstagramImgH from "../common/img/instagram_hover.png";
import TwitterImg from "../common/img/twitter_basic.png";
import TwitterImgH from "../common/img/twitter_hover.png";
import MediumImg from "../common/img/medium_basic.png";
import MediumImgH from "../common/img/medium_hover.png";

function Footer(props) {
  return (
    <StyledFooter>
      <MainDiv>
        <LeftBox>
          <LeftTopBox>
            <LeftTopContent href={"/terms#TermsOfUse"}>
              Terms of Use
            </LeftTopContent>
            <LeftTopContent href={"/terms#PrivacyPolicy"}>
              Privacy
            </LeftTopContent>
            <LeftTopContent href={"/terms#Disclaimers"}>
              Disclaimers
            </LeftTopContent>
          </LeftTopBox>
          <LeftBottomBox>
            © 2022 Halliday's LLC All Rights Reserved.
            <br />
            <br />
            Contact : info@hallidays.io
          </LeftBottomBox>
        </LeftBox>
        <RightBox>
          <InstagramIcon
            onClick={() =>
              window.open("https://www.instagram.com/official_hallidays/")
            }
          />
          <TwitterIcon
            onClick={() => window.open("https://twitter.com/hallidays_io/")}
          />
          <MediumIcon
            onClick={() => window.open("https://medium.com/@hallidays/")}
          />
        </RightBox>
      </MainDiv>
    </StyledFooter>
  );
}
const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background: rgba(26, 26, 26, 1);
  font-family: PoppinsRegular;
  text-align: left;
  padding: 36px 0 108px 0;
  width: 100%;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: auto;
    display: block;
    padding: 36px 24px;
  }
`;
const MainDiv = styled.div`
  width: 1440px;
  display: flex;
  justify-content: space-between;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
  }
`;
const LeftBox = styled.div``;
const LeftTopBox = styled.div`
  display: flex;
  font-size: 16px;

  /* 모바일 환경 */

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftTopContent = styled.a`
  color: #c4c4c4;
  margin: 12px;
  text-decoration-line: none;
  &:hover {
    color: #eeeeee;
  }
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 2px 0;
  }
`;
const LeftBottomBox = styled.div`
  font-size: 14px;
  line-height: 30px;
  color: #a0a0a0;
  margin: 12px;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 10px;
    line-height: 14px;
    margin: 2px auto;
  }
`;
const RightBox = styled.div`
  display: flex;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`;
const InstagramIcon = styled.div`
  background-image: url(${InstagramImg});
  background-size: 100% 100%;
  width: 52px;
  height: 52px;
  margin: 8px;
  &:hover {
    background-image: url(${InstagramImgH});
    cursor: pointer;
  }
`;
const TwitterIcon = styled.div`
  background-image: url(${TwitterImg});
  background-size: 100% 100%;
  width: 52px;
  height: 52px;

  margin: 8px;
  &:hover {
    background-image: url(${TwitterImgH});
    cursor: pointer;
  }
`;
const MediumIcon = styled.div`
  background-image: url(${MediumImg});
  background-size: 100% 100%;
  width: 52px;
  height: 52px;

  margin: 8px;
  &:hover {
    background-image: url(${MediumImgH});
    cursor: pointer;
  }
`;
export default Footer;
