import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

// 액션 타입 정의하기

const LOGIN_STATUS = 'status/LOGIN_STATUS';

// 액션 생성함수 만들기
export const changeLoginStatus = createAction(LOGIN_STATUS, login_status => login_status);

// 초기 상태
const initialState = {
  login_status: false
};

const status = handleActions(
  {
    [LOGIN_STATUS]: (state, action) => (state, { payload: login_status }) =>
    produce(state, draft => {
      draft.login_status = login_status;
    }),
  },
  initialState
);

export default status;
