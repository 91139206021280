import { combineReducers } from 'redux';
import nftBuy from './nftBuy';
import status from './status';
import certmsg from './certmsg';

const rootReducer = combineReducers({
  nftBuy,
  status,
  certmsg
});

export default rootReducer;
