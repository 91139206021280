import React from 'react';
import styled from 'styled-components';
function TextDiv({children, ...props}) {
    return (
        <Div {...props}>
            {children}
        </Div>
    );
}
const Div = styled.div`
    font-family : ${props=>props.fontFamily || 'PoppinsRegular'};
    font-size : ${props=>props.fontSize};
    color : ${props=>props.color};
    width : ${props=>props.width};
    height : ${props=>props.height};
    margin : ${props=>props.margin};
    margin-bottom : ${props=>props.marginB};
    padding : ${props=>props.padding};
    display : ${props=>props.display};
    justify-content : ${props=>props.justify};
    align-items : ${props=>props.alignItems};
    flex-direction : ${props=>props.flexDirection};
    background-color : ${props=>props.background};
    text-align : ${props=>props.textAlign};
    text-decoration : ${props=>props.textDecoration};
    overflow:hidden; 
    text-overflow:ellipsis;
    `;
    export default TextDiv;