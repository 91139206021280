import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

// 액션 타입 정의하기
const LODING = 'nftBuy/LODING';
const COMPLETE = 'nftBuy/COMPLETE';

// 액션 생성함수 만들기
export const loding = createAction(LODING);
export const complete = createAction(COMPLETE);

// 초기 상태
const initialState = {
  type: true, // 로딩창 컨트롤
};

const nftBuy = handleActions(
  {
    [LODING]: (state, action) => 
      produce(state, draft => {
        draft.type = false;
      }),
    [COMPLETE]: (state, action) => 
      produce(state, draft => {
        draft.type = true;
      }),
  },
  initialState
);

export default nftBuy;
