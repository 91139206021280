import React from 'react';
import { useSelector } from 'react-redux';
import EventComponent from '../components/event/EventComponent';
import useActions from '../redux/lib/useAction';
import {changeLoginStatus} from '../redux/module/status.js';

function EventContainer(props) {
    const { login_status } = useSelector(({ status }) => ({
        login_status: status.login_status,
      }));
    
      const [ChangeLoginStatus] = useActions(
        [changeLoginStatus],
        []
      );

      console.log("EventContainer login_status >>",login_status);
      console.log("EventContainer  >>");
    return (
        <EventComponent 
            login_status={login_status}
            changeLoginStatus={ChangeLoginStatus}
        />
    );
}

export default EventContainer;