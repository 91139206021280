import React from "react";
import styled from "styled-components";
import FlexDiv from "../../common/styled/FlexDiv";
import TextDiv from "../../common/styled/TextDiv";
import { useMediaQuery } from "react-responsive";
import Icon from "../../common/Icon";
import Swal from "sweetalert2";
import imgdescIcon from "../../common/img/imgdesc-icon.svg";
import DefaultProfile from "../img/DefaultProfile.svg";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { useState } from "react";

function SetProfile() {
  const history = useHistory();
  const location = useLocation();
  const idRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [profileImg, setProfileImg] = useState('');
  const [nickName, setNickname] = useState('');
  const [color, setColor] = useState('');

  const Tooltip = ({ children }) => {
    return (
      <Container>
        <Content className="tooltip">
          <TextDiv margin={"auto 0"}>Recommended 350px x 350px </TextDiv>
          <TextDiv margin={"auto 0"}>Max size: 100MB</TextDiv>
        </Content>
        {children}
      </Container>
    );
  };
  const onChange = (e) => {
    setColor('');
    const {value} = e.target;
    console.log(value);
    setNickname(value);
  }
  const Confirm = () => {
    axios.post(process.env.REACT_APP_API_URL+"/user/nickname", {
      id:idRef.current,
      nickname:nickName,
      profile_picture:profileImg
    }).then(res=>{
      if(res.status===200){
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success SignUp",
          showConfirmButton: false,
          timer: 1500,
        }).then(()=>history.push('/'))
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Duplicate nicknames.",
          showConfirmButton: false,
          timer: 1500,
        }).then(()=>setColor('red'))
      }
      console.log(res);
    }).catch(err=>{
      console.log(err);
    })
  };
  function InputHandler(){
    hiddenFileInput.current.click();
  }
  async function onFileChange(e){
    let _img = e.target.files[0];
    const formData = new FormData();
    formData.append('file', _img);
    await axios.post(process.env.REACT_APP_API_URL+"/upload/uploadProfileImage", formData)
    .then(res=>{
      console.log(res.data.profileImageUrl);
      setProfileImg(res.data.profileImageUrl);
    })
    .catch(err=>{
      console.log("err >>", err);
    });
  }
  
  // id 값 없이 접근하는 경우 메인화면으로 이동
  useEffect(() => {
    if (location.state === undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "wrong approach",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push("/");
      });
    } else {
      const { userid } = location.state;
      console.log("AuthenticationSection location >>", location);
      console.log("AuthenticationSection userid >>", userid);
      idRef.current = userid;
    }
  }, []);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });
  return (
    <ContentBox>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"20px"}
        alignItems={"center"}
        margin={"40px auto 30px auto"}
      >
        Set your profile
      </TextDiv>
      <FlexDiv
        justify={"left"}
        width={isPc ? "350px" : "95%"}
        margin={"0 auto"}
      >
        <TextDiv alignItems={"center"} fontSize={"18px"}>
          Upload your profile picture
        </TextDiv>
        <Tooltip>
          <Icon src={imgdescIcon} margin={"3.5px"}/>
        </Tooltip>
      </FlexDiv>
      <Icon
        src={profileImg?profileImg : DefaultProfile}
        width={"140px"}
        height={"140px"}
        margin={"40px auto 30px auto"}
        borderRadius={'100px'}
        onClick={()=>InputHandler()}
      />
      <input type="file" name="userProfileImageUrl" onChange={(e)=>onFileChange(e)} ref={hiddenFileInput} style={{display:'none'}}/>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        alignItems={"center"}
        fontSize={"18px"}
        margin={"0 auto"}
      >
        Username
      </TextDiv>
      <InputBox margin={"10px auto 40px auto"}>
        <Input placeholder={"Username"} name={'nickname'} color={color} onChange={(e)=> onChange(e)}/>
      </InputBox>
      <FlexDiv
        width={isPc ? "350px" : "95%"}
        justify={"space-between"}
        margin={"0 auto"}
      >
        <Button onClick={() => history.push("/")}>Later</Button>
        <Button color={"#FFFFFF"} background={"#06C755"} onClick={Confirm}>
          Confirm
        </Button>
      </FlexDiv>
    </ContentBox>
  );
}

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  &:hover > .tooltip,
  &:active > .tooltip {
    display: flex;
  }
`;

const Content = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  z-index: 200;
  width: 300px;
  height: 70px;
  top: -80px;
  left: -130px;
  background-color: #00131c;
  text-align: center;
  border-radius: 10px;
  opacity: 0.9;
`;
const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const InputBox = styled.div`
  display: flex;
  jutify-content: left;
  align-items: center;
  width: 350px;
  height: 50px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin: ${(props) => props.margin || "5px auto"};
  padding: 14px;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Input = styled.input`
  border: none;
  height: 30px;
  color: ${props=>props.color};
  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    font-family: PoppinsSemiBold;
    font-size: 14px;
  }
`;

const Button = styled.button`
  font-family: PoppinsSemiBold;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: -20000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
  }
`;
export default SetProfile;
