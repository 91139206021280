import React from 'react';
import styled from 'styled-components';
import SaleTimer from '../common/SaleTimer.jsx';
import puddle0 from '../../test_img/toy-puddle0.jpg';
import { useMediaQuery } from 'react-responsive';

function SaleSubComponent({loding_type, Loding, Complete, page_type}) {

    const isPc = useMediaQuery ({query: "(min-width:768px)"});
    return (
        <Main>
            <FlexDiv type={'main'} isPc={isPc}>
                <Img src={puddle0} isPc={isPc}/>
                <ColumnDiv type={'content1'} isPc={isPc}>
                    <NFTname>NFTname</NFTname>
                    <Founder>founder</Founder>
                    <TextDiv type={'project'}>PROJECT</TextDiv>
                    <TextDiv type={'pjt content'}>aldfjlkasdfjakldjfkalksjddfkljalksjdkfjkalasdfasdfsdfasdfdckjkkskkakasdjflkasdlkfjklajsdlkfjlajsdklfjlasjdflkjalsdjfkljaklsjdfkljasdfkajskdkjfaaskdkkkkkkkkkkkkfajldsjfjalsdjklfjads</TextDiv>
                    <FlexDiv type={'FlexDiv price'}>
                        <ColumnDiv>
                            <TextDiv type={'price'}>1.2ETH</TextDiv>
                            <SaleTimer deadline={'2022-2-5 10:00:00'}/>
                        </ColumnDiv>
                        <BidButton/>
                    </FlexDiv>
                    <ColumnDiv>

                    </ColumnDiv>
                </ColumnDiv>
            </FlexDiv>
            <ColumnDiv type={'bottom content'}>
                <TextDiv type={'Description'}>Description</TextDiv>
                <TextDiv type={'Desc'}>
                    Founder’s NFT consists of two artists, members of Halliday’s first project REBIRTH. You can deal this NFT with others, not just keep it to commemorate for
                    being one of us. It can deal still after pre-participate on project.
                    For moving your NFTs to Ethereum network, see here.
                </TextDiv>
            </ColumnDiv>
        </Main>
    );
}

const Main = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    background: #1C1C1C;
    font-family : RL;
    height : 100vh;
    color : rgba(255, 255, 255, 1);
`;
const FlexDiv = styled.div`
    display : flex;
    ${props=>props.type==='main'&&`
        justify-content : space-between;
        margin : 0 345px;
    `}
    ${props=>props.type==='FlexDiv price'&&`
        justify-content : space-between;
        align-items : center;
    `}
`;
const ColumnDiv = styled.div`
    display : flex;
    flex-direction : column;
    ${props=>props.type==='bottom content'&&`
        width : 992px;
        margin : 0 auto;
        margin-top : 80px;
    `}
    ${props=>props.type==='content1'&&`
        width : 530px;
    `}

`;
const NFTname = styled.div`
    font-family : RBLI;
    font-size : 50px;
    line-height : 59px;
`;
const Founder = styled.div`
    font-size : 18px;
    line-height : 21px;
`;
const TextDiv = styled.div`
    ${props=>props.type==='price'&&`
        font-family : RBL;
        font-size : 25px;
        line-height : 29px;
    `}
    ${props=>props.type==='Description'&&`
        font-family : RB;
        margin : 20px 0;
        color : rgba(161, 161, 161, 1);
    `}
    ${props=>props.type==='Desc'&&`
        color : rgba(161, 161, 161, 1);
    `}
    ${props=>props.type==='project'&&`
        margin : 24px 0;
        color : rgba(161, 161, 161, 1);
    `}
    ${props=>props.type==='pjt content'&&`
        color : rgba(161, 161, 161, 1);
        margin-bottom : 50px;
        overflow-wrap: break-word;
    `}
`;
const Button = styled.button`
    ${props=>props.type==='bid'&&`
        font-family : RBD;
        font-size : 16px;
        background : rgba(0, 0, 0, 0);
        color : rgba(6, 197, 85, 1);
        width : 174px;
        height : 49px;
        border: 1px solid #06C555;
        border-radius: 11px;
        
        margin-top : ${props.page_type<=10&&`69px`};
    `}
`;

const BidButton = ({page_type}) => {
    return (
        <Button type={'bid'} page_type={page_type} onClick={()=>alert('무야호')}>Bid</Button>
    )
}
const Img = styled.img`
    ${props=>props.isPc?
        `
            width : 582px;
        `
    :
        `
        
        `
    }
`;
export default SaleSubComponent;