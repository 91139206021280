import React from "react";
import { Route, Switch, useHistory } from "react-router";
import { useSelector } from "react-redux";
import Footer from "./common/Footer.jsx";
import TopNav from "./common/TopNav.jsx";
import HomePage from "./home/HomePage.jsx";
import TermsPage from "./terms/TermsPage.jsx";
import FAQPage from "./FAQ/FAQPage.jsx";
import DropsMainPage from "./drops/DropsMainPage.jsx";
import DropsDetailPage from "./drops/DropsDetailPage.jsx";
import AboutPage from "./about/AboutPage.jsx";
import LoginPage from "./login/LoginPage.jsx";
import ProfilePage from "./profile/ProfilePage.jsx";
import ProfileEditPage from "./profile/ProfileEditPage.jsx";
import RandingPage from "./randing/RandingPage.jsx";
import Loding from "./common/Loding.jsx";
import ScrollToTop from "./common/Scrolltotop.jsx";
import EventContainer from "../containers/EventContainer.js";
import AuctionContainer from "../containers/AuctionContainer";
import SaleAuctionContainer from "../containers/SaleAuctionContainer.js";
import Mousai from "./mousai/index.jsx";

function RouterComponent(props) {
  const status = useSelector((state) => state.nftBuy.type);

  return (
    <>
      {!status && <Loding />}
      <Route component={TopNav} />
      <Switch>
        <ScrollToTop>
          <Route path={"/profileEdit"} component={ProfileEditPage} />
          <Route path={"/my-profile"} component={ProfilePage} />
          <Route path={"/profile"} component={ProfilePage} />
          <Route path={"/rebirth"} component={RandingPage} />
          <Route path={"/terms"} component={TermsPage} />
          <Route path={"/faq"} exact={true} component={FAQPage} />
          <Route path={"/drops"} exact component={DropsMainPage} />
          <Route path={`/drops/:id`} component={DropsDetailPage} />
          <Route path={"/about"} exact={true} component={AboutPage} />
          <Route
            path={"/login"}
            exact={true}
            render={() => <LoginPage type={"login"} />}
          />
          <Route
            path={"/signUp"}
            exact={true}
            render={() => <LoginPage type={"signUp"} />}
          />
          <Route
            path={"/authentication"}
            exact={true}
            render={() => <LoginPage type={"authentication"} />}
          />
          <Route
            path={"/setProfile"}
            exact={true}
            render={() => <LoginPage type={"setProfile"} />}
          />
          <Route path={"/event"} exact={true} component={EventContainer} />
          <Route
            path={"/auction"}
            exact={true}
            component={SaleAuctionContainer}
          />
          <Route
            path={"/drops/auction/aaaaaaaaaaaaaaaaaaaaaaaaa"}
            exact={true}
            component={AuctionContainer}
          />
          <Route path={"/"} exact={true} component={HomePage} />
          {/* <Route path={"/mousai"} exact={true} component={Mousai} /> */}
        </ScrollToTop>
      </Switch>
      <Route component={Footer} />
    </>
  );
}

export default RouterComponent;
