/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useCookies, Cookies } from "react-cookie";
import styled from 'styled-components';
import Web3 from "web3";
import axios from 'axios';
import Icon from './Icon';
import Metamask from "./img/MetamaskButton.png";
import Swal from 'sweetalert2';
import { useState } from 'react';
import FlexDiv from './styled/FlexDiv';
import TextDiv from './styled/TextDiv';
import CopyButton from './CopyButton';
import { useSelector } from 'react-redux';

function ConnectButton(props) {
  const [cookies] = useCookies();
  const { certmsg } = useSelector(({ certmsg }) => ({
    certmsg: certmsg.msg,
  }));
  const ConnectWallet = useCallback(async () => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      const _account = await web3.eth.getAccounts(); 
      //console.log("accountaccountaccountaccountaccount 2 >>>", _account);
      // 메타마스크 서명
      const sign_cert_data = await web3.eth.personal.sign(certmsg, _account[0], "");
      const authorization = cookies.authorization;
      console.log(sign_cert_data);
      console.log(authorization);
      
      axios.post(process.env.REACT_APP_API_URL+'/user/sign/metamask', {
          wallet_address : _account[0],
          cert_data : sign_cert_data,
          msg : certmsg
      },{
          headers: {
            authorization: authorization,
          },
      })
      .then((res)=>{
        //console.log(res);
        if(res.status===200){
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Wallet Connected!",
            showConfirmButton: false,
            timer: 1500,
          }).then(()=>window.location.reload())
        }
      })
      .catch((err)=>{
          console.log("ConnectButton >> ConnectWallet >> /user/sign/metamask request error >>", err);
      })
    } catch (error) {
      console.log("ConnectButton >> ConnectWallet >> error", error);
    }
    
  }, []);

  return (
    <Div onClick={ConnectWallet}>
      <Icon src={Metamask}/>
    </Div>
  );
}

const Div = styled.div`
    margin : 0 auto 50px;
    
    &:hover {
      cursor: pointer;
    }
`;
export default ConnectButton;