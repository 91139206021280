import React from "react";
import { useState } from "react";
import styled from "styled-components";
import FlexDiv from "../../common/styled/FlexDiv";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextDiv from "../../common/styled/TextDiv";
import Google from "../../common/styled/Google";
import Icon from "../../common/Icon";
import or from "../../common/img/or.svg";
import { useRef } from "react";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { Cookies } from "react-cookie";

function SignUpSection(props) {
  const history = useHistory();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const cookies = new Cookies();

  function onChange(e) {
    const { value } = e.target;
    setEmail(value);
  }
  function onChangePass(e) {
    const { value } = e.target;
    setPassword(value);
  }
  const Login = () => {
    //console.log(Email)
    //console.log(Password)
    axios
      .post(process.env.REACT_APP_API_URL + "/user/login/email", {
        mail_address: Email,
        password: Password,
      })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          // 로그인 성공
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Logged in!",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            console.log(res.data);
            const { cert_data } = res.data;
            cookies.set("authorization", cert_data);
            cookies.set("state", true);

            window.location.href = "/";
          });
        } else if (res.status === 204) {
          // 아이디 혹은 비밀번호 다름
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Check your Email or Password",
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (res.status === 203) {
          //window.location.href="/authentication";
          console.log("res.data.uid >>", res.data.uid);
          console.log("res.data.mail_address >>", res.data.mail_address);
          history.push({
            pathname: "/authentication",
            state: {
              userid: res.data.uid,
              email: res.data.mail_address,
            },
          });
        }
      })
      .catch(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Bad approach",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const isPc = useMediaQuery({ query: "(min-width:768px)" });
  return (
    <Section>
      <ContentBox>
        <TextDiv
          fontFamily={"PoppinsRegular"}
          fontSize={"20px"}
          width={isPc ? "350px" : "80vw"}
          margin={isPc ? "35px auto" : "20px auto"}
        >
          Login for Halliday’s
        </TextDiv>
        <Google />
        <FlexDiv alignItems={"center"} margin={"5px 0"}>
          <Line />
          <Icon src={or} margin={"10px"} />
          <Line />
        </FlexDiv>
        <InputBox>
          <Input
            type={"text"}
            name={"mail_address"}
            placeholder={"Email Address"}
            onChange={(e) => onChange(e)}
          />
        </InputBox>
        <InputBox>
          <Input
            type={"password"}
            name={"password"}
            placeholder={"PASSWORD"}
            onChange={(e) => onChangePass(e)}
          />
        </InputBox>
      </ContentBox>
      <TextDiv
        fontSize={"10px"}
        width={isPc ? "350px" : "95%"}
        margin={"0 auto"}
      >
        Please use dedicated browser app instead of Metamask app on mobile
        phones for signup and login.
      </TextDiv>

      <FlexDiv
        justify={"space-between"}
        width={isPc ? "350px" : "95%"}
        margin={"0 auto"}
      >
        <Button onClick={() => history.goBack()}>Back</Button>
        <Button color={"#FFFFFF"} background={"#06C755"} onClick={Login}>
          Login
        </Button>
      </FlexDiv>
      <FlexDiv
        justify={"space-between"}
        width={isPc ? "350px" : "95%"}
        margin={"0 auto"}
      >
        <TextDiv
          fontFamily={"PoppinsBold"}
          textDecoration={"underline"}
          fontSize={"16px"}
        >
          Forgot Password
        </TextDiv>

        <TextDiv
          fontFamily={"PoppinsBold"}
          textDecoration={"underline"}
          onClick={() => history.push("/signUp")}
          fontSize={"16px"}
        >
          Create Account
        </TextDiv>
      </FlexDiv>
    </Section>
  );
}

const Section = styled.section`
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
  }
`;

const Line = styled.div`
  border: 0.5px solid #ffffff;
  width: 150px;
  height: 0;
`;
const A = styled(Link)`
  color: #ffffff;
  font-family: PoppinsBold;
`;
const InputBox = styled.div`
  display: flex;
  jutify-content: left;
  align-items: center;
  width: 350px;
  height: 50px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin: ${(props) => props.margin || "5px auto"};
  padding: 14px;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
const Input = styled.input`
  border: none;
  height: 30px;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    font-family: PoppinsSemiBold;
    font-size: 14px;
  }
`;
const Button = styled.button`
  font-family: PoppinsSemiBold;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  margin: 40px 0;
  &:hover {
    box-shadow: -20000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
  }
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export default SignUpSection;
