import React from "react";
import {GoogleLogin} from "react-google-login";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import GoogleIcon from '../img/Google-icon.svg';
import FlexDiv from "./FlexDiv";
import Icon from "../Icon";
import axios from "axios";
import Swal from "sweetalert2";
import {Cookies} from 'react-cookie';

function Google() {
  const history = useHistory();
  const cookies = new Cookies();

  const responseGoogle = async (response) => {
    console.log("responseGoogle >>", response);
    const {email, imageUrl} = response.profileObj;
    await axios.post(process.env.REACT_APP_API_URL+"/user/login/google", {
        email : email,
        img : imageUrl
    }).then(res=>{
      const {cert_data} = res.data;
        if (res.status===200){
          console.log(cert_data);
          cookies.set('authorization', cert_data);
          cookies.set('state', true);
          window.location.href="/";
        } else {
          Swal.fire({
            title: 'registered email',
            text: "Will you go to the login page?",
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/login');
            }
          })
          cookies.set('state', false);
        }
    });
  };

  const eresponseGoogle = (response) => {
    console.log("eresponseGoogle error >>", response);
  };

  
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_API_KEY}
      render={(renderProps)=>
        <GoogleLoginButton isPc={isPc} onClick={renderProps.onClick}>
            <FlexDiv alignItems={'center'} justify={'left'}>
                <Icon src={GoogleIcon}/>
                <FlexDiv width={'150px'}>
                    Login with Google
                </FlexDiv>
            </FlexDiv>
        </GoogleLoginButton>
      }
      onSuccess={responseGoogle}
      onFailure={eresponseGoogle}
    />
  );
}
const GoogleLoginButton = styled.button`
    font-family : PoppinsBold;
    width : 350px;
    height : 50px;
    margin : 10px auto;
    border-radius: 4px;
    border : none;
    cursor : pointer;
    &:hover {
      box-shadow:-20000px 0 0 0 rgba(0,0,0,0.4) inset;
    }
    ${props=> !props.isPc&&`
      width : 85vw;
      height : 50px;
    `}
`;

export default Google;
