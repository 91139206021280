import React from 'react';
import styled from 'styled-components';

function Icon({children, ...props}) {
    return (
        <Img {...props}>
            {children}
        </Img>
    );
}
const Img = styled.img`
    height : ${props=>props.height};
    width : ${props=>props.width};
    margin : ${props=>props.margin};
    margin-bottom : ${props=>props.marginB};
    border-radius : ${props=>props.borderRadius};
    cursor : ${props=>props.cursor};
`;

export default Icon;