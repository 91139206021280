import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import {NFTCA, NFTABI} from "../artifacts/NFT_ABI.json";
import {AUCTIONCA, AUCTIONABI} from "../artifacts/AUCTION_ABI.json";

export const web3 = window.ethereum ? new Web3(Web3.givenProvider) : null;
export const NftContract = web3 ? new web3.eth.Contract(NFTABI, NFTCA) : null;
export const AuctionContract = web3 ? new web3.eth.Contract(AUCTIONABI, AUCTIONCA) : null;

// 설정할 NETWORK ID
export const NETWORKID = 1;

// /0xdeA5E4A9cFf8E303c971EE16529B0442F6A47C9c
export const injected = new InjectedConnector({ 
    supportedChainIds: [
      1,
      2,
      137,
      80001
    ] 
  });

