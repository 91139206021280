import React from 'react';
import styled from 'styled-components';
import FlexDiv from '../../common/styled/FlexDiv';
import TextDiv from '../styled/TextDiv';
import { useMediaQuery } from "react-responsive";

function Disclaimers(props) {
    const isPc = useMediaQuery({query: "(min-width:768px)"});
    return (
        <FlexDiv flexDirection={'column'} padding={'160px 0'} id="Disclaimers">
            <TextDiv type={'title'}>Disclaimers</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY HALLIDAY'S, THE SITE, CONTENT CONTAINED THEREIN, AND NFT LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. HALLIDAY'S (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SITE. HALLIDAY'S DOES NOT REPRESENT OR WARRANT THAT CONTENT ON THE SITE IS ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>WHILE HALLIDAY'S ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SITE AND CONTENT SAFE, HALLIDAY'S CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SITE, CONTENT, ANY NFT LISTED ON OUR SITE OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF NFT INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED WALLET FILES; (4) UNAUTHORIZED ACCESS TO APPLICATIONS; (5) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE OR NFT.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>NFTs ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT HALLIDAY'S OR ANY HALLIDAY'S PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY NFT.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>HALLIDAY'S is not responsible for sustained losses due to vulnerability or any kind of failure, abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other features of NFT. HALLIDAY'S is not responsible for sustained losses due to late reports by developers or representatives (or no report at all) of any issues with the blockchain supporting NFT including forks, technical node issues or any other issues having fund losses as a result.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>Nothing in this Agreement shall exclude or limit liability of either party for fraud, death or bodily injury caused by negligence, violation of laws, or any other activity that cannot be limited or excluded by legitimate means.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>TO THE FULLEST EXTENT PROVIDED BY LAW, HALLIDAY'S HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE AND CONTENT CONTAINED THEREIN.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL HALLIDAY'S BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THIS AGREEMENT, THE SITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF HALLIDAY'S HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITES, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF HALLIDAY'S ARISING OUT OF OR IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE SITE, CONTENT, NFT, OR ANY PRODUCTS OR SERVICES PURCHASED ON THE SITE EXCEED THE GREATER OF (A) $100 OR</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}>(B) THE AMOUNT YOU HAVE PAID TO HALLIDAY'S FOR THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH LIABILITY AROSE.</TextDiv>
            <TextDiv type={'contentS'} margin={'24px auto'}> THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF HALLIDAY'S FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A MEMBER OF HALLIDAY'S’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY A MEMBER OF HALLIDAY'S’S FRAUD OR FRAUDULENT MISREPRESENTATION.</TextDiv>
        </FlexDiv>
    );
}

export default Disclaimers;