import React from "react";
import styled from "styled-components";
function FlexDiv({ children, ...props }) {
  return <Div {...props}>{children}</Div>;
}
const Div = styled.div`
  display: ${(props) => props.display || "flex"};
  font-family: ${(props) => props.fontFamily};
  justify-content: ${(props) => props.justify || "center"};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  min-width: ${(props) => props.Mwidth};
  max-width: ${(props) => props.Mxwidth};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginT};
  margin-bottom: ${(props) => props.marginB};
  margin-left: ${(props) => props.marginL};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderB};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  scroll-snap-type: ${(props) => props.scrollSnap};
  padding-top: ${(props) => props.paddingT};
  ${(props) =>
    props.position === "absolute" &&
    `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  `}
`;
export default FlexDiv;
