import React from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import FlexDiv from "../../common/styled/FlexDiv";
import TextDiv from "../../common/styled/TextDiv";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { useState } from "react";

function AuthenticationSection() {
  const history = useHistory();
  const location = useLocation();
  const [temp, setTemp] = useState('');
  const [email, setEmail] = useState('');
  const idRef = useRef(null);
  const SendEmail = () => {
      console.log("idRef >>", idRef.current);
      axios.post(process.env.REACT_APP_API_URL+'/user/mail/send', { id : idRef.current }).then(res=>{
          if(res.status===200){
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Successful in sending mail.",
                showConfirmButton: false,
                timer: 1500,
            });
          }
      })
  }
  
  const Verify = () => {
    console.log("idRef >>", idRef.current);
    console.log("temp >>", temp);
    axios.post(process.env.REACT_APP_API_URL+'/user/authentication', { id : idRef.current, temp : temp }).then(res=>{
      if(res.status===200){
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Successful authentication",
            showConfirmButton: false,
            timer: 1500,
        }).then(() => {
          history.push({
              pathname : "/setProfile",
              state : {userid : idRef.current}
          });
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "wrong authentication",
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  function onChange(e){
    const {value} = e.target;
    setTemp(value);
  }

  // id 값 없이 접근하는 경우 메인화면으로 이동
  useEffect(() => {
    if (location.state === undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "wrong approach",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.push("/");
      });
    } else {
      const { userid, email } = location.state;
      //console.log("AuthenticationSection userid >>", userid);
      //console.log("AuthenticationSection email >>", email);
      idRef.current = userid;
      setEmail(email);
    }
  }, []);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });
  return (
    <ContentBox>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"20px"}
        margin={isPc ? "10px auto" : "5px auto"}
      >
        Create an account
      </TextDiv>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"16px"}
        margin={isPc ? "25px auto" : "10px auto"}
      >
        Please verify your email
      </TextDiv>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"16px"}
        margin={"0 auto"}
      >
        We sent the verification code to
      </TextDiv>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"16px"}
        margin={"0 auto"}
        fontFamily={"PoppinsBold"}
      >
        {email}
      </TextDiv>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"16px"}
        margin={isPc ? "25px auto" : "10px auto"}
      >
        Please enter the 6-digit code sent to your email to complete your
        signup. If you can’t find it check your spam folder.
      </TextDiv>
      <TextDiv
        width={isPc ? "350px" : "95%"}
        fontSize={"16px"}
        margin={isPc ? "0 auto 35px auto" : "0 auto 20px auto"}
      >
        The code is valid for 30 minutes.
      </TextDiv>
      <InputBox>
        <Input placeholder={"Verification Code"} name={'temp'} onChange={(e)=>onChange(e)}/>
      </InputBox>
      <FlexDiv
        justify={"left"}
        width={isPc ? "350px" : "95%"}
        margin={isPc ? "35px auto" : "20px auto"}
      >
        <TextDiv>Can’t find the email?</TextDiv>
        <TextDiv
          fontFamily={"PoppinsBold"}
          textDecoration={"underline"}
          onClick={SendEmail}
        >
          Resend email
        </TextDiv>
      </FlexDiv>

      <FlexDiv
        width={isPc ? "350px" : "95%"}
        justify={"space-between"}
        margin={"0 auto"}
      >
        <Button onClick={() => history.goBack()}>Back</Button>
        <Button color={"#FFFFFF"} background={"#06C755"} onClick={Verify}>
          Verify
        </Button>
      </FlexDiv>
    </ContentBox>
  );
}

const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const InputBox = styled.div`
  display: flex;
  jutify-content: left;
  align-items: center;
  width: 350px;
  height: 50px;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  margin: ${(props) => props.margin || "5px auto"};
  padding: 14px;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Input = styled.input`
  border: none;
  height: 30px;
  color: ${(props) => props.color};

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    font-family: PoppinsSemiBold;
    font-size: 14px;
  }
`;

const Button = styled.button`
  font-family: PoppinsSemiBold;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: -20000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
  }
`;
export default AuthenticationSection;
