import React from 'react';
import styled from 'styled-components';
import IconCopy from "./img/IconCopy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from 'sweetalert2';

function CopyButton(props) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
    });
    return (
        <CopyToClipboard
          text={props.wallet}
          onCopy={() =>
            Toast.fire({ icon: "success", title: "Copy success" })
          }
        >
          <Div img={IconCopy}></Div>
        </CopyToClipboard>
    );
}

const Div = styled.div`
  border: none;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 16px;
  width: 16px;
  margin: auto 0;
  &:hover {
    cursor: pointer;
  }
`;
export default CopyButton;