import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import mousai_logo from "../../../home/img/mousai_logo.png";
import bottom_arrow from "../../../home/img/bottom_arrow.png";
import main_video from "../../img/main_video.mp4";
import main_video_M from "../../img/main_video_M.mp4";
import { css } from "styled-components";

function Con1Section(props) {
  const { moveToBottom, hideArrow } = props;
  const history = useHistory();
  const videoRef = useRef(null);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });

  useEffect(() => {
    if (!videoRef.current.autoplay) {
      videoRef.current.play();
    }
  }, []);

  const openMousai = () => {
    window.open("https://mousai.club/", "_blank");
  };

  return (
    <MainDiv>
      <VideoDiv
        ref={videoRef}
        autoplay={true}
        loop={true}
        playsInline={true}
        muted
      >
        <source src={isPc ? main_video : main_video_M} type="video/mp4" />
      </VideoDiv>
      <ContentDiv>
        <TextDiv type={1}></TextDiv>
        <TextDiv type={2}>
          10,000 MOUSAI are here to inspire, define and illuminate the future{" "}
        </TextDiv>
        <Button onClick={openMousai}>See more</Button>
        <BottomArrow onClick={moveToBottom} hideArrow={hideArrow}></BottomArrow>
      </ContentDiv>
    </MainDiv>
  );
  // ) : (
  //   <>
  //     <ImgDiv img={ML008} type={"Con1Gif"}>
  //       <TextDiv type={1}>
  //         The Premier Marketplace for Digital Collectibles
  //       </TextDiv>
  //       <TextDiv type={2}>
  //         Integrating the latest digital technology and social concept with NFT
  //       </TextDiv>
  //       <Button onClick={() => history.push("/about")}>About Us</Button>
  //     </ImgDiv>
  //     <FlexDiv type={"four"}>
  //       <FlexDiv type={"five"}>
  //         <Video
  //           controls
  //           loop
  //           muted={"muted"}
  //           autoPlay={"autoplay"}
  //           playsInline
  //         >
  //           <source src={MainNFTBanner} type={"video/mp4"} />
  //         </Video>
  //         <TextDiv type={8}>Studio Muse</TextDiv>
  //       </FlexDiv>
  //       <Button2
  //         onClick={() =>
  //           window.open(
  //             "https://opensea.io/assets/0x5d0deef81dc5b7ede1706a079e7be35e19cf9dce/301"
  //           )
  //         }
  //       >
  //         See on Opensea
  //       </Button2>
  //       <Button3 onClick={() => history.push("/rebirth#studiomuse")}>
  //         Read More
  //       </Button3>
  //     </FlexDiv>
  //   </>
  // );
}

const VideoDiv = styled.video`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 100vh;
  }
`;
const Button = styled.button`
  width: 170px;
  height: 50px;
  margin: 0 auto;
  border-radius: 11px;
  border: 1px solid green;
  color: green;
  font-size: 16px;
  font-family: RBD;
  background-color: transparent;
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    border: none;
    color: white;
    background-color: green;
  }

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 7vh auto 5vh;
  }
`;

const TextDiv = styled.div`
  color: #ffffff;
  ${(props) =>
    props.type === 1 &&
    `
    font-family : RBL;
    font-size : 50px;
    line-height : 60px;
    margin : 0 auto ;
    width : 680px;
    height: 170px;
    background: url(${mousai_logo});
    background-repeat: no-repeat;
    background-size: contain;

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      font-size : 24px;
      margin: 0 auto;
      line-height : 30px;
      width: 272px;
      height: 68px;
    }
  `}
  ${(props) =>
    props.type === 2 &&
    `
    font-family : RL;
    font-size : 18px;
    margin : 40px auto;

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      width : 76vw;
      margin : 25px auto 29px;
      font-size : 12px;
      line-height: 14px;
    }
  `}
  ${(props) =>
    props.type === 3 &&
    `
    margin-top : 120px;
    font-family : RLI;
    font-size : 50px;
    line-height: 70px;
    text-align: center;

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      font-size: 22px;
      line-height: 24px;
      color: #FFFFFF;
    }
  `}
  ${(props) =>
    props.type === 4 &&
    `
    font-family : RBDI;
    font-size : 150px;
    line-height : 148px;
    text-align: center;
    
    @media screen and (max-width : 1234px) {
      font-size: 100px;
      line-height: 100px;
    }
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      width : 60vw;
      margin : 0 auto;
      font-size: 22px;
      line-height: 24px;
      font-size: 50px;
      line-height: 50px;
    }
    `}
    ${(props) =>
    props.type === 5 &&
    `
      font-family : RL;
      font-size : 18px;
      line-height : 30px;
      text-align: center;
      color : #FFFFFF;
      @media screen and (max-width : 768px) {
        font-size: 16px;
        line-height: 30px;
      }
    `}
    ${(props) =>
    props.type === 6 &&
    `
    font-family : RBDI;
    font-size : 150px;
    line-height : 148px;
    text-align: center;
    margin-top : 300px;
    @media screen and (max-width : 1000px) {
      font-size: 100px;
    }
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      width : 90vw;
      margin : 200px auto 100px;
      font-size: 50px;
      line-height: 50px;
    }
    `}
    ${(props) =>
    props.type === 7 &&
    `
      font-family : RL;
      font-size : 18px;
      line-height : 30px;
      text-align: center;
      color : #FFFFFF;
      @media screen and (max-width : 768px) {
        margin-top : 0px;
        font-size: 16px;
        line-height: 30px;
      }
    `}
    ${(props) =>
    props.type === 8 &&
    `
    font-family : RBDI;
    font-size : 150px;
    line-height : 148px;
    text-align: left;
    
    @media screen and (max-width : 1234px) {
      font-size: 100px;
    }
    
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
      position: absolute;
      text-align: center;
      bottom : 20px;
      width : 100vw;
      margin : 0 auto;
      font-size: 50px;
      line-height: 50px;
    }
    `}
    ${(props) =>
    props.type === 9 &&
    `
      font-family : RL;
      font-size : 18px;
      line-height : 30px;
      text-align: left;
      color : #FFFFFF;
      width : 35vw;
      min-width : 500px;
      @media screen and (max-width : 768px) {
        font-size: 16px;
        line-height: 30px;
      }
    `}
`;
const FlexDiv = styled.div`
    display : flex;
    flex-direction : column;
    ${(props) =>
      props.type === "one" &&
      `
    
    `}
    ${(props) =>
      props.type === "two" &&
      `
      margin : 20vh 0 10vh;
      /* 모바일 환경 */
      @media screen and (max-width : 768px) {
        margin : 7px 0 10vh;
      }
    `}
    ${(props) =>
      props.type === "two2" &&
      `
      margin : 30vh 0 5vh;
      /* 모바일 환경 */
      @media screen and (max-width : 768px) {
        margin : 20vh 0 0;
      }
    `}
    ${(props) =>
      props.type === "three" &&
      `
      margin : 20vh 0 10vh;
      padding-left : 15vw;
      /* 모바일 환경 */
      @media screen and (max-width : 1500px) {
        padding-left : 10vw;
      }
      @media screen and (max-width : 768px) {
        margin : 7px 0 10vh;
      }
    `}
    ${(props) =>
      props.type === "four" &&
      `
      width : 100vw;
      background-color : #1A1A1A;
    `}
    \`}
    ${(props) =>
      props.type === "five" &&
      `
      position : relative;
      z-index : 0;
    `}
`;

const arrowAnimation = keyframes`
0% {
  bottom: 50px;
} 50% {
  bottom: 60px;
} 100% {
  bottom: 50px;
}
`;

const arrowHideAnimation = keyframes`
from {
  bottom: 50px;
  opacity: 0.5;
} to {
  bottom: 50px;
  opacity: 0;
  display: none;
}
`;

const arrowMobileHideAnimation = keyframes`
from {
  bottom: 150px;
  opacity: 0.5;
} to {
  bottom: 150px;
  opacity: 0;
  display: none;
}
`;

const arrowMobileAnimation = keyframes`
0% {
  bottom: 150px;
} 50% {
  bottom: 160px;
} 100% {
  bottom: 150px;
}
`;

const BottomArrow = styled.div`
  display: block;
  width: 100%;
  height: 37px;
  background: url(${bottom_arrow});
  background-size: 80px 30px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  animation: ${(props) =>
    !props.hideArrow
      ? css`
          ${arrowAnimation} 1s infinite
        `
      : css`
          ${arrowHideAnimation} 1s
        `};

  cursor: pointer;
  opacity: 0.5;
  @media screen and (max-width: 768px) {
    height: 30px;
    animation: ${(props) =>
      !props.hideArrow
        ? css`
            ${arrowMobileAnimation} 1s infinite
          `
        : css`
            ${arrowMobileHideAnimation} 1s
          `};
    background-size: 50px 25px;
  }
`;

const MainDiv = styled.div`
  background-color: #1c1c1c;
  position: relative;
`;

const ContentDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export default Con1Section;
