import React from 'react';

function DropsDetailPage(props) {
    return (
        <div>
            
        </div>
    );
}

export default DropsDetailPage;