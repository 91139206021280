import React, { useEffect, useState } from "react";
import styled from "styled-components";

function RandingTimer({ children, ...props }) {
  const [hours, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    try {
      const { deadline } = props;
      
      let now = new Date();
      let arr = deadline.split(/[- :]/);
      let DeadLine = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);

      const _seconds = parseInt((DeadLine.getTime() - now.getTime()) / 1000);
      const _hours = parseInt(_seconds / 3600);
      const _minutes = parseInt((_seconds - _hours * 3600) / 60);

      setHour(_hours);
      setMinutes(_minutes);
      setSeconds(_seconds - (_hours * 3600 + _minutes * 60));
    } catch (error) {
      console.log(error);
    }
  }, [props]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          if (parseInt(hours === 0)) {
            clearInterval(countdown);
          } else {
            setHour(parseInt(hours) - 1);
          }
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [hours, minutes, seconds]);

  return (
    <Div {...props}>
      <FlexDiv type={2}>
        {hours < 10 ? `0${hours}` : hours}h :{" "}
        {minutes < 10 ? `0${minutes}` : minutes}m :{" "}
        {seconds < 10 ? `0${seconds}` : seconds}s
      </FlexDiv>
    </Div>
  );
}

const Div = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin : 0;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
  }
`;
const FlexDiv = styled.div`
  dlsplay: flex;
  width : 150px;
  font-family : RL;
  font-size : 18px;
`;

export default RandingTimer;
