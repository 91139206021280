import { createAction, handleActions } from 'redux-actions';

// 초기 상태
const initialState = {
  msg: `
  Signing this message will not cost you any Ether! It’s completely FREE.
  
  Welcome to Halliday’s. We have exclusive luxury NFTs for you. Keep your eyes on our newest drop.
  To continue, sign this message to connect your wallet to Halliday’s.`
};

const certmsg = handleActions(
  {
      
  },
  initialState
);

export default certmsg;
