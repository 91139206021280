import React from 'react';
import styled from 'styled-components';
import FlexDiv from '../../common/styled/FlexDiv.jsx';
import TextDiv from '../styled/TextDiv.jsx';
import Ul from '../styled/TextUl.jsx';
import Li from '../styled/TextLi.jsx';
import { useMediaQuery } from "react-responsive";

function TermsOfUse(props) {
    const isPc = useMediaQuery({query: "(min-width:768px)"});
    return (
        <FlexDiv flexDirection={'column'} id="TermsOfUse" padding={'160px 0 0 0'}>
            <TextDiv type={'title'}>Terms of Use</TextDiv>
            <Div>
                <TextDiv type={'contentS'}>Halliday's Terms of Use Last revised: Jan 20, 2022 </TextDiv>
                <TextDiv type={'contentS'} margin={'24px auto 0 auto'}>Welcome to Halliday's! Halliday's is a platform that allows you to buy, sell, and display NFTs. These Terms of Use are entered into by and between you and Halliday's (“Halliday's,” “Company,” “we,” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference, govern your access to and use of Halliday's, including any content, functionality, and services offered on or through http://hallidays.io (the “Site”).</TextDiv>
            </Div>

            <Div>
                <TextDiv type={'subTitle'}>1) Accepting these Terms</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>These Terms of Use set out your rights and responsibilities when you use Halliday's to buy, sell, or display non-fungible tokens (“NFTs” or “NFT”) or create a collection of NFTs (collectively, the “Services”), so please read them carefully. Halliday's is an administrative platform that facilitates transactions between a buyer and a seller but is not a party to any agreement between the buyer and seller of NFTs or between any users. By clicking on the “I Accept” button, completing the account registration process, or using the Site, you accept and agree to be bound and abide by these Terms of Use and all of the terms incorporated herein by reference. By agreeing to these terms, you hereby certify that you are at least 18 years of age. If you do not agree to these Terms of Use, you must not access or use the Site.</TextDiv>
                <TextDiv type={'contentS'} margin={'24px auto 0 auto'}>Halliday's reserves the right to change or modify these Terms of Use at any time and in our sole discretion. You agree and understand that by logging into your account, accessing or using the Site following any change to these Terms of Use, you agree to the revised Terms of Use and all of the terms incorporated therein by reference. We encourage you to review the Terms of Use from time to time to ensure that you understand the terms and conditions that apply when you access or use the Site.</TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>2) Communication</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>You agree that we may send you promotional communications by email, including, but not limited to, newsletters, special offers, surveys, and other news and information we think will be of interest to you.</TextDiv>
                <TextDiv type={'contentS'} margin={'24px auto'}>You agree that we may send you communications by email or text message that pertain to the status of a purchase or sale of a NFT on Halliday's, and other communications that pertain to your interaction with the website, including but not limited to notifications about the status of a NFT you are interested in purchasing.</TextDiv>
                <TextDiv type={'contentS'}>You may opt out of promotional communications at any time by following the instructions provided therein.</TextDiv>
            </Div>
            {isPc&&<div>
                <Div>
                    <TextDiv type={'subTitle'}>3) Content</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>The Site allows (1) artists or creators of NFTs (“NFT Creators”) to post their NFTs (“NFT Creations”), and bios (collectively, with Profile Information, “User Content”). All User Content must comply with these Terms of Use.</TextDiv>
                    <TextDiv type={'contentS'} margin={'24px auto'}>Any Profile Information you post to the Site will be considered non-confidential and non-proprietary. By providing any User Content on the Website, y ou grant us and our affiliates and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, retransmit, publish, broadcast, and otherwise disclose to third parties any such material for any purpose. You represent and warrant that (1) you own and control all rights in and to your User Content and have the right to grant such licenses to us and our affiliates and our respective licensees, successors, and assigns; and (2) all of your User Content do and will comply with these Terms of Use.</TextDiv>
                    <TextDiv type={'contentS'}>You understand and agree that you are responsible for any User Content you submit or contribute, and you, not Halliday's, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content, accuracy, or appropriateness of any User Content posted by you or any other user on the Site.</TextDiv>
                    <TextDiv type={'contentS'} margin={'24px auto'}>If you are a NFT Creator, you hereby grant Halliday's the right to use your name and image for marketing or promotional purposes. If you are a NFT Creator, you agree that we may use or modify images from the NFTs that you create for marketing or promotional purposes. You also agree that we can use your biography and other public information about you to promote the NFTs that you create.</TextDiv>
                    <TextDiv type={'contentS'}>As a NFT Creator, you understand and agree that:</TextDiv>
                    <TextDiv type={'contentS'}>You are responsible for the accuracy and content of your NFT Creations;</TextDiv>
                    <TextDiv type={'contentS'}>Any NFT Creation that violates any of Halliday's’s policies may be obfuscated or deleted by Halliday's, in its sole discretion;</TextDiv>
                    <TextDiv type={'contentS'}>You will respect the intellectual property of others;</TextDiv>
                    <TextDiv type={'contentS'}>and You will not coordinate pricing with other NFT Creators.</TextDiv>
                    <TextDiv type={'contentS'} margin={'24px auto 0 auto'}>We reserve the right, in our sole discretion, to prohibit you from uploading your NFT Creations. Although we are not required to monitor any User Content, we may, in our sole discretion, remove User Content at any time and for any reason without notice. Halliday's may monitor User Content to detect and prevent fraudulent activity or violation of these Terms of Use.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>4) Ownership</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>Unless otherwise indicated in writing by us, the Site, all content, and all other materials contained therein, including, without limitation, the Halliday's logo, and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “Halliday's Content”) are the proprietary property of Halliday's or our affiliates, licensors, or users, as applicable. The Halliday's logo and any Halliday's product or service names, logos, or slogans that may appear on the Site or elsewhere are trademarks of Halliday's or our affiliates, and may not be copied, imitated or used, in whole or in part, without our prior written permission.</TextDiv>
                    <TextDiv type={'contentS'} margin={'24px auto'}>You may not use any Halliday's Content to link to the Site or Content without our express written permission. You may not use framing techniques to enclose any Halliday's Content without our express written consent. In addition, the look and feel of the Site and Content, including without limitation, all page headers, custom graphics, button icons, and scripts constitute the service mark, trademark, or trade dress of Halliday's and may not be copied, imitated, or used, in whole or in part, without our prior written permission.</TextDiv>
                    <TextDiv type={'contentS'}>Notwithstanding anything to the contrary herein, you understand and agree that you shall have no ownership or other property interest in your account, and you further agree that all rights in and to your account are and shall forever be owned by and insure to the benefit of Halliday's.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>5) Intellectual Property</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 24px auto'}>Outside the Halliday's Content, all other trademarks, product names, and logos on the Site are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Without limiting the foregoing, if you believe that third-party material hosted by Halliday's infringes your copyright or trademark rights, please file a notice of infringement by contacting Halliday's’s Copyright Agent at info@hallidays.io.</TextDiv>
                    <TextDiv type={'contentS'}>In such event, please provide Halliday's’s Copyright Agent with the following information: (1) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (2) a description of the copyrighted work that you claim has been infringed; (3) a description of the location on Site of the material that you claim is infringing; (4) your address, telephone number and e-mail address; (5) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and (6) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>6) Your Use of Halliday's</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Site and Content. Our grant of such license is subject to the following conditions:Unlawful Activity: you agree not to engage, or assist, in any activity that violates any law, statute, ordinance, regulation, or sanctions program, , or that involves proceeds of any unlawful activity.</TextDiv>
                    <Ul>
                        <Li type={'contentS'}>Abusive Activity : you agree not to engage in any activity that poses a threat to Halliday's, for example by distributing a virus or other harmful code, or through unauthorized access to the Site or other users’ accounts.</Li>
                        <Li type={'contentS'}>Inappropriate Behavior : you agree not to interfere with other users’ access to or use of the Services.</Li>
                        <Li type={'contentS'}>Communication : You agree not to use data collected from the Site to contact individuals, companies, or other persons or entities outside the Site for any purpose, including but not limited to marketing activity.</Li>
                        <Li type={'contentS'}>Fraud : you agree not to engage in any activity which operates to defraud Halliday's, others users, or any other person; or to provide any false, inaccurate, or misleading information to Halliday's.</Li>
                        <Li type={'contentS'}>Gambling : you agree not to utilize the Services to engage in any lottery, bidding fee auctions, contests, sweepstakes, or other games of chance.</Li>
                        <Li type={'contentS'}>Intellectual Property Infringement : you agree not to (1) engage in transactions involving items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the law; (2) use Halliday's Content without express written consent from Halliday's; or (3) engage in any action that implies an untrue endorsement or affiliation with Halliday's.</Li>
                        <Li type={'contentS'}>Hyperlinks : you are hereby granted a limited, non-exclusive, non-transferable, revocable license to create a text hyperlink to the Site for noncommercial purposes, provided that such link does not portray Halliday's, our affiliates, or the Services in a false, misleading, derogatory, or otherwise defamatory manner, and provided further that the linking site does not contain any adult or illegal materials, or other materials that is offensive, harassing, or otherwise objectionable.</Li>
                        <Li type={'contentS'}>Additional Prohibitions : you agree not to (1) distribute, publish, broadcast, reproduce, copy, retransmit, or publicly display any Content; (2) modify or create derivative works from the Site or Content, or any portion thereof; (3) use any data mining, robots, or similar data gathering or extraction methods on the Site or Content; (4) download any portion of the Site or Content, other than for purposes of page caching, except as expressly permitted by us.</Li>
                    </Ul>
                    <TextDiv type={'contentS'}> If you are unsure whether a contemplated use would violate these Terms of Use, please contact us at the address listed below under Contact Information.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>7) Privacy</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>You acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with Halliday's’s Privacy Policy, which is incorporated into this User Agreement by reference.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>8) Modifications</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>You agree and understand that we may modify part or all of Halliday's or the Services without notice.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>9) Risks</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 24px auto'}>Please note the following risks in accessing or using Halliday's: The price and liquidity of blockchain assets, including NFTs, are extremely volatile and may be subject to large fluctuations; Fluctuations in the price of other digital assets could materially and adversely affect NFTs, which may also be subject to significant price volatility; Legislative and regulatory changes or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of NFTs; NFTs are not legal tender and are not backed by the government; Transactions in NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable; Some transactions in NFTs shall be deemed to be made when recorded on a public ledger, which is not necessarily the date or time that you initiated the transaction; The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, which may result in the potential for permanent and total loss of value of a particular NFT should the market for that NFT disappear; The nature of NFTs may lead to an increased risk of fraud or cyber attack, and may mean that technological difficulties experienced by Halliday’s may prevent the access to or use of your Digital Assets; Changes to Third Party Sites (discussed in Section 12 below) may create a risk that your access to and use of the Site will suffer.</TextDiv>
                    <TextDiv type={'contentS'}>You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that Halliday's does not give advice or recommendations regarding NFTs, including the suitability and appropriateness of, and investment strategies for, NFTs. You agree and understand that you access and use Halliday's at your own risk; however, this brief statement does not disclose all of the risks associated with NFTs and other digital assets. You agree and understand that Halliday's will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using NFTs, however caused.</TextDiv>
                </Div>

                <Div>
                    <TextDiv type={'subTitle'}>10) Third Parties</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>Halliday's may rely on third-party platforms, including but not limited to MetaMask to perform NFT transactions. Our Site may contain links to third-party websites or applications (collectively, “Third Party Sites”). Halliday's does not own or control Third Party Sites. You understand and agree that your use of any Third Party Site is subject to any terms of use and/or privacy policy provided by such Third Party Site. Halliday's is not a party to any such agreement. You should review any terms of use and/or privacy policy provided by such Third Party Site and should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.</TextDiv>
                    <TextDiv type={'contentS'} margin={'24px auto'}>Halliday's provides these Third Party Sites only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites, or their products or services. You use all links in Third Party Sites at your own risk. When you leave our Site, our Agreement and policies no longer govern. We may, but are not obligated to, warn you that you are leaving our Site.</TextDiv>
                    <TextDiv type={'contentS'}>Some transactions on our site may be facilitated and run by Metamask. Any such transactions are governed by the Metamask Terms of Service and Privacy Policy.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>11) Termination</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We reserve the right, without notice and in our sole discretion, to terminate your license to access or use the Site or Content, at any time and for any reason. You understand and agree that we shall have no liability or obligation to you in such an event.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>12) Severability</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 24px auto'}>If any term, clause, or provision of these Terms of Use is held invalid or unenforceable, then that term, clause, or provision shall be severable from these Terms of Use and will not affect the validity or enforceability of any remaining part of that term, cause, or provision, or any other terms, clause, or provision of these Terms of Use.</TextDiv>
                    <TextDiv type={'contentS'}>These Terms of Use comprise the entire agreement between you and Halliday's relating to your access to and use of the Site and Content, and supersede any and all prior discussions agreements, and understandings of any kind (including without limitation prior versions of this User Agreement). Except as otherwise provided herein, these Terms of Use are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>13) Survival</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>You agree and understand that all provisions of these Terms of Use shall survive the termination or expiration of these Terms of Use.</TextDiv>
                </Div>
                <Div>
                    <TextDiv type={'subTitle'}>14) Contact Information</TextDiv>
                    <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>If you have any questions, would like to provide feedback, or would like more information about Halliday's, please feel free to email us at info@hallidays.io. If you would like to lodge a complaint, please contact us at info@hallidays.io.</TextDiv>
                </Div>
            </div>
            }
        </FlexDiv>
    );
}
const Div = styled.div`
    margin : 24px 0;
`;
export default TermsOfUse;