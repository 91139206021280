import React from "react";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot){
    console.log(snapshot)
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { location = {} } = prevProps;
    //console.log("ScrollToTop this.props.location.pathname", this.props.location.pathname);
    //console.log("ScrollToTop location.pathname", location.pathname);
    // 컴포넌트 업데이트 시 페이지 이동인지 확인
    if (this.props.location.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
    return null;
  }
  
  render() {
    return this.props.children;
  }
}
  
export default ScrollToTop;