import React from 'react';
import styled from "styled-components";

const StyledButton = styled.button`
  border: none;
  transition:all 0.9s, color 0.3;
  border-radius: ${(props) => props.borderRadius};
  font-family: ${(props) => props.fontFamily || "PoppinsMedium"};
  font-size: ${(props) => props.fontSize || "14px"};
  color: ${(props) => props.color || "white"};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.background};
  margin : ${(props) => props.margin || "0"};
  cursor : pointer;
  &:hover {
    box-shadow:-20000px 0 0 0 rgba(0,0,0,0.4) inset;
  }
  ${props=> (props.type==='Login'||props.type==='Signup')&&`
    width : 90px;
    height : 40px;
    margin : 10px;
    border-radius: 4px;
    background-color: ${props.type==='Login'?'#FFFFFF':'#06C755'};
    color : ${props.type==='Login'?'#000000':'#FFFFFF'};
    /* 모바일 환경 */
    @media screen and (max-width: 768px) {
      width : 250px;
      margin : 20px auto;
    }
  `}
  ${props=> props.type==='metamask' &&`
    display : flex;
    align-items : center;
    
  `}
`;

function Button({ children, ...props }) {
  return <StyledButton {...props}>
    {children}    
  </StyledButton>;
}

export default Button;