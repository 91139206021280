import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import DefaultProfileImg from "./img/DefaultProfile.svg";
import DefaultBannerImg from "./img/default-banner.png";
import IconCopy from "./img/IconCopy.png";
import Swal from "sweetalert2";
import CopyButton from "../common/CopyButton";
import InactiveFacebook from "../common/img/InactiveFacebook.svg";
import InactiveInstagram from "../common/img/InactiveInstagram.svg";
import InactiveTwitter from "../common/img/InactiveTwitter.svg";
import InactiveDiscord from "../common/img/InactiveDiscord.svg";
import ActiveDiscord from "../common/img/ActiveDiscord.svg";
import ActiveFacebook from "../common/img/ActiveFacebook.svg";
import ActiveInstagram from "../common/img/ActiveInstagram.svg";
import ActiveTwitter from "../common/img/ActiveTwitter.svg";
import { useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

function ProfilePage({history}) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [Toggle1, setToggle1] = useState(false);
  const [snsId, setSnsId] = useState("");
  const [Cert, setCert] = useState(false);
  // 보유한 Token List
  const [tokenList, setTokenList] = useState([]);
  // 모바일 보여줄 카드 갯수
  const [viewCount, setViewCount] = useState(1);
  // 해당 회원 프로필 정보
  const [profile, setProfile] = useState({
    join_datetime : "join_datetime",
    mail_address : "mail_address",
    wallet_address : "wallet_address",
    nickname : "nickname",
    profile_picture : "profile_picture"
  });


  const EditProfile = () => {
    // my profile 인지 check 필요

    history.push({
      pathname : '/profileEdit',
      state : {Profile : profile}
    });
  }

  const getProfile = async () => {
    const authorization = cookies.authorization;
    axios.post(process.env.REACT_APP_API_URL + "/user/getProfile", {},
        {
          headers: {
            authorization: authorization,
          },
        }).then((res)=>{
          const {result, cert} = res.data.result;
          const {imgs} = res.data;
          console.log("getProfile result >>", result);
          console.log("getProfile cert >>", cert);
          console.log("getProfile imgs >>", imgs);
          setTokenList(imgs);
          setProfile(result);
          setCert(cert);
        }).catch(()=>{
          console.log('getProfile err');
        })
  }

  useEffect(()=>{
    getProfile();
  },[])
  const isMo = useMediaQuery({ query: "(max-width:768px)" });
  const isPc0 = useMediaQuery({ query: "(max-width:900px)" });
  const isPc1 = useMediaQuery({ query: "(max-width:1440px)" });
  const isPc2 = useMediaQuery({ query: "(max-width:1920px)" });
  const isPc3 = useMediaQuery({ query: "(max-width:2420px)" });
  const isPc4 = useMediaQuery({ query: "(max-width:2920px)" });
  const isPc5 = useMediaQuery({ query: "(max-width:3420px)" });
  return (
    <Main>
      <Section1>
        <RelativeDiv>
          <BannerImg></BannerImg>
        </RelativeDiv>
      </Section1>
      <FlexDiv styletype={"Main"} isMo={isMo}>
        <ProfileDiv isPc={isPc0} isMo={isMo}>
          <ProfileImg
            img={
              profile.profile_picture
                ? profile.profile_picture
                : DefaultProfileImg
            }
          />
          <TextBox styletype={1} isPc={isPc0} isMo={isMo}>
            {profile.nickname}
          </TextBox>
          <FlexDiv type={"wallet_address"} justify={"space-between"} width={"200px"}>
            <TextBox styletype={2} isPc={isPc0}>
              {profile.wallet_address}
            </TextBox>
            <CopyButton wallet={profile.wallet_address}></CopyButton>
          </FlexDiv>
          <TextBox styletype={6}>
            {profile.mail_address ? profile.mail_address : "-"}
          </TextBox>
          <Button styletype={"Edit"} onClick={EditProfile}>Edit Profile</Button>
          <TextBox styletype={5} isPc={isPc0} userIntro={true}>
            {profile.userIntroduction}
          </TextBox>
          <TextBox styletype={6}>
            Member since : {profile.join_datetime ? profile.join_datetime : "-"}
          </TextBox>
          {/* <FlexDiv justify={"space-between"} width={"130px"}>
            <TextBox styletype={6}>
              {profile.userAcTwitter ? (
                <Icon
                  img={ActiveTwitter}
                  hover={true}
                  onMouseEnter={() => setSnsId("@" + profile.userAcTwitter)}
                  onMouseLeave={() => setSnsId("")}
                  onClick={() =>
                    (window.location.href =
                      "https://twitter.com/" + profile.userAcTwitter)
                  }
                />
              ) : (
                <Icon img={InactiveTwitter} />
              )}
            </TextBox>
            <TextBox styletype={6}>
              {profile.userAcDiscord ? (
                <Icon
                  img={ActiveDiscord}
                  onMouseEnter={() => setSnsId("@" + profile.userAcDiscord)}
                  onMouseLeave={() => setSnsId("")}
                />
              ) : (
                <Icon img={InactiveDiscord} />
              )}
            </TextBox>
            <TextBox styletype={6}>
              {profile.userAcFacebook ? (
                <Icon
                  img={ActiveFacebook}
                  hover={true}
                  onMouseEnter={() => setSnsId("@" + profile.userAcFacebook)}
                  onMouseLeave={() => setSnsId("")}
                  onClick={() =>
                    (window.location.href =
                      "https://facebook.com/" + profile.userAcFacebook)
                  }
                />
              ) : (
                <Icon img={InactiveFacebook} />
              )}
            </TextBox>
            <TextBox styletype={6}>
              {profile.userAcInstagram ? (
                <Icon
                  img={ActiveInstagram}
                  hover={true}
                  onMouseEnter={() => setSnsId("@" + profile.userAcInstagram)}
                  onMouseLeave={() => setSnsId("")}
                  onClick={() =>
                    (window.location.href =
                      "https://instagram.com/" + profile.userAcInstagram)
                  }
                />
              ) : (
                <Icon img={InactiveInstagram} />
              )}
            </TextBox>
          </FlexDiv> */}
          {snsId}
        </ProfileDiv>
        <AssetDiv isMo={isMo}>
          <FlexDiv
            borderbottom={"1px solid #E6E8EC"}
            margin={isMo ? "0" : "0 10px"}
          >
            <TextBox styletype={7} isMo={isMo} color={'#ffffff'} onClick={()=>setToggle1(false)}>
              Purchase History
            </TextBox>
            {/* <TextBox styletype={7} onClick={()=>setToggle1(true)}>EVENT</TextBox> */}
          </FlexDiv>
          {!Toggle1?
          
          <FlexDiv
            justify={"left"}
            width={
              isMo
                ? "260px"
                : isPc1
                ? "520px"
                : isPc2
                ? "780px"
                : isPc3
                ? "1040px"
                : isPc4
                ? "1300px"
                : isPc5
                ? "1560px"
                : "2080px"
            }
          >
            {tokenList.length === 0 && (
              <NoItemTextBox>No items to display</NoItemTextBox>
            )}
            {tokenList.map((data, index) => (
              <AssetCard
                key={index}
                isMo={isMo}
                viewState={viewCount * 3 > index}
              >
                <ItemImg img={data.img_url} />
                {!isMo && <TextDiv type={1}>{data.name}</TextDiv>}
                <TextDiv type={2}>{data.create_dt}</TextDiv>
              </AssetCard>
            ))}
            {isMo && (
              tokenList.length>3&&
              <Button
                styletype={"More"}
                onClick={() =>
                  viewCount * 3 > tokenList.length
                    ? setViewCount(1)
                    : setViewCount(viewCount + 1)
                }
              >
                {viewCount * 3 > tokenList.length ? "Close" : "Load More"}
              </Button>
            )}
          </FlexDiv>
          :
          <FlexDiv
            justify={"left"}
            width={
              isMo
                ? "260px"
                : isPc1
                ? "520px"
                : isPc2
                ? "780px"
                : isPc3
                ? "1040px"
                : isPc4
                ? "1300px"
                : isPc5
                ? "1560px"
                : "2080px"
            }
          >
            이벤트 참여 완료
          </FlexDiv>
          }
        </AssetDiv>
      </FlexDiv>
    </Main>
  );
}
const Main = styled.main`
  font-family: PoppinsMedium;
  margin: 0 auto;
  color : #FFFFFF;
  background-color : #1C1C1C;
`;
const Section1 = styled.section``;
const RelativeDiv = styled.div``;
const BannerImg = styled.div`
  background-image: url(${DefaultBannerImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 180px;
`;
const ProfileImg = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 70px;
  border: 5px solid #ffffff;
  width: 125px;
  height: 125px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    margin: 0 auto;
  }
}
`;
const TextBox = styled.div`
  font-size: ${(props) =>
    props.styletype === 1
      ? "48px"
      : props.styletype === 3
      ? "18px"
      : props.styletype === 8
      ? "10px"
      : props.styletype === 9 || props.styletype === 11
      ? "7px"
      : "13px"};
  font-family: ${(props) =>
    props.styletype === 2 ||
    props.styletype === 4 ||
    props.styletype === 5 ||
    props.styletype === 9 ||
    props.styletype === 11
      ? "PoppinsLight"
      : "PoppinsMedium"};
  color: ${(props) =>
    props.styletype === 1 || props.styletype === 3 || props.styletype === 8
      ? "#FFFFFF"
      : props.styletype === 2 || props.styletype === 4
      ? "#FFFFFF"
      : props.styletype === 5 || props.styletype === 9
      ? "#FFFFFF"
      : props.styletype === 6
      ? "#FFFFFF"
      : props.styletype === 7
      ? "#FFFFFF"
      : ""};
  width: ${(props) =>
    props.styletype === 2
      ? "180px"
      : props.styletype === 7
      ? "350px"
      : props.styletype === 10
      ? "200px"
      : props.styletype === 5
      ? "260px"
      : ""};
  height: ${(props) => props.userIntro && "300px"};
  margin: ${(props) =>
    props.styletype === 1
      ? "80px 0 20px 0"
      : (props) =>
          props.styletype === 3 ||
          props.styletype === 4 ||
          props.styletype === 8 ||
          props.styletype === 9 ||
          props.styletype === 10
            ? "0"
            : props.styletype === 7
            ? "10px"
            : props.styletype === 11
            ? "0 0 10px 0"
            : props.styletype === 5
            ? "50px 0"
            : "20px 0 20px 0"};
  text-align: ${(props) => (props.styletype === 7 ? "center" : "")};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${(props) => props.styletype === 10 && "nowrap"};
  &:hover {
    cursor: ${(props) => (props.styletype === 7 ? "pointer" : "")};
    color: ${(props) => (props.styletype === 7 ? "#EEEEEE" : "")};
    background-color: ${(props) => (props.styletype === 7 ? "#212121" : "")};
  }
  /* PC 반응형 */
  ${(props) =>
    (props.styletype === 2 || props.styletype === 5) &&
    props.isPc &&
    `
    text-align : center;
    margin: 20px auto;
    `}
  /* PC 반응형 */
  ${(props) =>
    props.styletype === 2 &&
    props.isPc &&
    `
    text-align : center;
    margin: 20px auto;
    `}
  /* Mobile 반응형 */
  ${(props) =>
    props.styletype === 7 &&
    props.isMo &&
    `
      width : 260px;
      margin : 10px 0;
      `}
  ${(props) =>
    props.styletype === 1 &&
    props.isMo &&
    `
      width : 260px;
      margin : 0 auto;
    `}
  ${(props) =>
    props.styletype === 1 &&
    props.isMo &&
    `
      width : 260px;
      margin : 0 auto;
    `}
`;
const Button = styled.button`
  width: 190px;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family: Aeonik-Medium;
  line-height: 22px;
  border: none;
  border-radius: 22px;
  margin: 18px 0;
  &:hover {
    cursor: pointer;
    box-shadow: -20000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
  }

  ${(props) =>
    props.styletype === "Follow" &&
    `
    background-color : #7144FF;
  `}
  ${(props) =>
    (props.styletype === "More" || props.styletype === "Edit") &&
    `
    background-color : #000000;
    margin: 18px auto;
  `}
  ${(props) =>
    props.styletype === "" &&
    `
    background-color : #000000;
  `}
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "center"};
  width: ${(props) => props.width};
  flex-wrap: wrap;
  border-bottom: ${(props) => props.borderbottom};
  border-top: ${(props) => props.bordertop};
  margin: ${(props) => props.margin};

  ${(props) =>
    props.styletype === "Main" &&
    `
      min-height : 1080px;
    `}

    ${props=>props.type==='wallet_address'&&`
      /* 모바일 환경 */
      @media screen and (max-width : 768px) {
        margin: 0 auto;
      }
    `}
`;
const ProfileDiv = styled.div`
  margin-top: -63px;
  width: 300px;
  margin-right: 80px;

  /* PC 반응형 */
  ${(props) =>
    props.isPc
      ? `
    width : 400px;
    text-align : center;
    margin: -63px auto 0 auto;
    `
      : ""}
  /* Mobile 반응형 */
  ${(props) =>
    props.isMo
      ? `
    width : 100vw;
    `
      : ""}
`;
const AssetDiv = styled.div`
  margin-top: ${(props) => (props.isMo ? "50px" : "150px")};
  margin-bottom: ${(props) => (props.isMo ? "0" : "100px")};
`;
const AssetCard = styled.div`
  display : flex;
  justify-content: space-between;
  margin: 10px;
  width: 100%;
  display: ${(props) => props.isMo && !props.viewState && "none"};
`;
const ItemImg = styled.div`
  background-image: url(${(props) => props.img || DefaultProfileImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 100px;
`;
const CardImg = styled.div`
  background-image: url(${(props) => props.img || DefaultProfileImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const NoItemTextBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 30px;
`;

const TextDiv = styled.div`
  ${props=>props.type===1&&`
    line-height: 100px;
    font-size: 15px;
  `}
  ${props=>props.type===2&&`
    line-height: 100px;
    font-size: 13px;
  `}
`;
export default ProfilePage;
