import React, { useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import L002 from '../home/img/L002.jpg';
import ML009 from '../home/img/ML009.jpg';
import L003 from '../home/img/L003.jpg';
import L004 from '../home/img/L004-A.png';
import L005 from '../home/img/L005-A.png';
import L006 from '../home/img/L006.jpg';
import L007 from '../home/img/L007.jpg';
import XIcon from '../home/img/x-icon.png';
import SUB1 from '../home/img/sub_nft_02.gif';
import SUB2 from '../home/img/sub_nft_03.gif';
import SUB3 from '../home/img/sub_nft_01_1.gif';
import MAIN from '../home/img/MAINFT.mp4';
import RandingTimer from '../common/RandingTimer';
import YouTube from 'react-youtube';
import { useState } from 'react';
import { readMore } from './read_more';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import {web3, NftContract, injected, NETWORKID} from '../../js/Web3.js';
import useActions from '../../redux/lib/useAction';
import {loding, complete} from '../../redux/module/nftBuy.js';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';


function RandingPage() {
    const [toggle, setToggle] = useState(false);
    const [sub1, setSub1] = useState(true);
    const [sub2, setSub2] = useState(true);
    const [sub3, setSub3] = useState(true);
    const [cookies] = useCookies();
    const [readMoreState, setReadMoreState] = useState(0);
    const { activate } = useWeb3React();
    const history = useHistory();
    const location = useLocation();

    const hash = location.hash;
    if (hash !== '') {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView();
        }, 0);
    }

    const [Loding, Complete] = useActions(
      [loding, complete],
      []
    );

    const opts = {
        height: '600',
        width: '1020',
    };
    const optsM = {
        height: '250',
        width: '320',
    };
    const optsF = {
        height: '200',
        width: '224',
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const ReadMore = (index) => {
        setToggle(true);
        setReadMoreState(index);

        document.body.style.overflow = "hidden"; // 모달창 열릴때 스크롤 못하도록
    }
    const ReadMoreClose = () => {
        setToggle(false);

        document.body.style.overflow = ""; // 모달창 열릴때 스크롤 못하도록 해제
    }
    
    const InfiniteSoundsMint = async (type) => {
        console.log('구매타입 >>', type);
        const accounts = await web3.eth.getAccounts();
        await NftContract.methods.InfiniteSoundsMint().send({from : accounts[0], value : 220000000000000000})
        .on('transactionHash', function(transactionHash){
            console.log('InfiniteSoundsMint transactionHash >>', transactionHash);
            Loding();
        })
        .then(async (result)=>{
            const {tokenId} = result.events.Transfer.returnValues;
            console.log("InfiniteSoundsMint result >>", tokenId);
            await axios.post(process.env.REACT_APP_API_URL+'/meta/InfiniteSoundsMint', {
                token_id : tokenId, 
                wallet : accounts[0]
            }).then(()=>{
                console.log('PureFrequencyMint 완료');
                Swal.fire({
                    icon : 'success',
                    text : 'Successfully minted!'
                }).then(()=>{
                    Complete();
                })
            })
        })
    }

    const PureFrequencyMint = async (type) => {
        console.log('구매타입 >>', type);
        const accounts = await web3.eth.getAccounts();
        
        await NftContract.methods.PureFrequencyMint().send({from : accounts[0], value : 220000000000000000})
        .on('transactionHash', function(transactionHash){
            console.log('PureFrequencyMint transactionHash >>', transactionHash);
            Loding();
        })
        .then(async (result)=>{
            const {tokenId} = result.events.Transfer.returnValues;
            console.log("PureFrequencyMint result >>", tokenId);
            
            await axios.post(process.env.REACT_APP_API_URL+'/meta/PureFrequencyMint', {
                token_id : tokenId, 
                wallet : accounts[0]
            }).then(()=>{
                console.log('PureFrequencyMint 완료');
                Swal.fire({
                    icon : 'success',
                    text : 'Successfully minted!'
                }).then(()=>{
                    Complete();
                })
            })
        })
    }
    const HigherReverbMint = async (type) => {
        console.log('구매타입 >>', type);
        const accounts = await web3.eth.getAccounts();
        await NftContract.methods.HigherReverbMint().send({from : accounts[0], value : 220000000000000000})
        .on('transactionHash', function(transactionHash){
            console.log('HigherReverbMint transactionHash >>', transactionHash);
            Loding();
        })
        .then(async (result)=>{
            const {tokenId} = result.events.Transfer.returnValues;
            console.log("HigherReverbMint result >>", tokenId);
            
            await axios.post(process.env.REACT_APP_API_URL+'/meta/HigherReverbMint', {
                token_id : tokenId, 
                wallet : accounts[0]
            }).then(()=>{
                console.log('PureFrequencyMint 완료');
                Swal.fire({
                    icon : 'success',
                    text : 'Successfully minted!'
                }).then(()=>{
                    Complete();
                })
            })
        })
    }

    const BuyNFT = async (type) => {
        console.log('구매타입 >>', type);

        if (!window.ethereum) {
            // 설치x 설치 페이지로 이동
            Swal.fire({
              icon: "info",
              title: "You need to install Metamask",
              text: `
                Would you like to go to the installation page? (We do not support Metamask mobile app.)
              `,
              showDenyButton: true,
              confirmButtonText: "Yes",
              denyButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                  window.open("https://metamask.io/download")
                } else if (result.isDenied) {
                  //window.location.reload();
                }
            });
        } else {
            const networkId = await web3.eth.net.getId();
            const authorization = cookies.authorization;

            axios.post(process.env.REACT_APP_API_URL+'/check/wallet_cert', {}, {
                headers: {
                    authorization: authorization,
                },
            }).then(async (res)=>{
                console.log(res);
                const {data, status} = res;
                console.log("wallet_cert data > ", data);
                console.log("wallet_cert status > ", status);
                if(status===200){
                    try {
                        let state = false;
                        await activate(injected).then( async ()=>{
                            console.log("networkId >>", networkId);
                            const chainId = web3.utils.numberToHex(NETWORKID);
                            // 네트워크 체크
                            if(networkId!==NETWORKID){
                              // 해당 net 으로 변경
                              await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: chainId }],
                              }).then(()=>{
                                state = true;
                              });
                            } else {
                                state = true;
                            }
                        });
    
                        if(state){
                            switch (type) {
                                case 1:
                                    InfiniteSoundsMint(type);
                                    break;
                                case 2:
                                    PureFrequencyMint(type);
                                    break;
                                case 3:
                                    HigherReverbMint(type);
                                    break;
                                default:
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("wallet connect error > ", error);
                    }
                } else if(status===205) {
                    // 로그인이 필요합니다.
                    Swal.fire({
                        icon : 'info',
                        title : 'You need to log in.',
                        text : 'You should log in first and connect your wallet to continue.'
                    })
                }else if(status===204) {
                    // 지갑연결이 필요합니다.
                    Swal.fire({
                        icon : 'question',
                        title : 'You need to connect your wallet.',
                        text : 'After logged in, you can connect your wallet at upper right corner.'
                    })
                }
            }).catch((err)=>{   
                console.log("post wallet_cert err > ", err);
                Swal.fire({
                    icon : 'error',
                    text : 'error'
                })
            })
        }

        
    }
    const Alert = () => {
        Swal.fire({
            icon : 'warning',
            title : 'You should use Metamask on DESKTOP',
            text : "Sorry for your inconvenience. We don't support mobile Metamask for minting. Please come here with Chrome desktop browser with Metamask."
        })
    }

    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_URL+"/check/subnft").then((res)=>{
            console.log("axios /check/subnft >>", res);
            const {cnum1, cnum2, cnum3} = res.data;
            if(cnum1===1){
                console.log("axios /check/subnft cnum1 >>", cnum1);
                setSub1(false);
            }
            if(cnum2===1){
                console.log("axios /check/subnft cnum2 >>", cnum2);
                setSub2(false);
            }
            if(cnum3===1){
                console.log("axios /check/subnft cnum3 >>", cnum3);        
                setSub3(false);
            }
        })
    }, [])
    
    const isPc = useMediaQuery({ query: "(min-width:768px)" });
    const isFold = useMediaQuery({ query: "(min-width:375px)" });
    return (
        <MainDiv>
            {toggle&&
            <ToggleBox toggle={toggle}>
                <FlexDiv type={'close'}>
                    <Img src={XIcon} type={'close'} onClick={ReadMoreClose}/>
                    <TextDiv type={'close'}>
                        {readMore[readMoreState]}
                    </TextDiv>
                </FlexDiv>
            </ToggleBox>}
            <Section type={1}>
                <FlexDiv type={0}>
                    <Top>
                        <Img src={isPc?L002:ML009} type={'top'}/>
                        <TextDiv type={'top'}>REBIRTH</TextDiv>
                    </Top>
                    <Bottom>
                        <TextDiv type={'top2'}>
                            {isPc?"네모난 창과 네모난 전화 밝기만 한데 나의 네모난 작은 방은 어두워":"네모난 창과\n네모난 전화\n밝기만 한데\n나의 네모난\n작은 방은\n어두워"}
                        </TextDiv>
                        <TextDiv type={'youtube'}>
                            <YouTube videoId="vZfdBiNXHxY" opts={isPc?opts:isFold?optsM:optsF} onReady={onReady}></YouTube>
                        </TextDiv>
                        <TextDiv type={'PROJECT REBIRTH'}>PROJECT REBIRTH</TextDiv>
                        <TextDiv type={'center'}>
                            Inspired by the song 'Buk-hyang' and the place where the recording took place, Gaeko picked up his paintbrush and began letting his creative juices flow. This gave birth to three oil painting art pieces titled room1, room2, and room3, which is a reinterpretation of every corner of the recording studio. Gaeko wanted to express the studio as a place full of imagination and creativity rather than a dark confined place.
                        </TextDiv>
                        <TextDiv type={'bottom'}>
                            Now, Gaeko has partnered with renowned digital artist, Vector Meldrew, to create a newly colored, reworked digital version of this piece. In addition, Music Producer Boycold has mixed an exclusive track to go with this collection. Rebirth is the coming together of three artists, showing that music and art can be interpreted differently and transformed into something you never expected.
                        </TextDiv>
                        <FlexDiv type={11}><TextDiv type={'NftSmallTitle'}>Drop Date of Mousai Within</TextDiv></FlexDiv>
                        <TextDiv type={'center'}>
                            22.02.05  10:00 AM KST<br/>22.02.04  20:00 PM EST
                        </TextDiv>
                        <FlexDiv type={11}><TextDiv type={'NftSmallTitle'}>Drop Date of Studio Muse</TextDiv></FlexDiv>
                        <TextDiv type={'center'}>
                            22.02.10  10:00 AM KST<br/>22.02.09  20:00 PM EST
                        </TextDiv>
                        <Section id={'mousaiwithin'}></Section>
                        <TextDiv type={'PROJECT REBIRTH'}>Mousai Within</TextDiv>
                        <TextDiv type={'center'}>
                            Visualizing the integration of isolation and dance as a way to transmit creativity. Let it transform your consciousness.
                        </TextDiv>
                        <TextDiv type={'center'}>
                            “Mousai Within” features three muses from the digital artwork “Studio Muse”.<br/>Minting of each Muse NFT will be limited to 22 editions with a minting price of 0.22 ETH on the Ethereum mainnet.
                        </TextDiv>
                        <FlexDiv type={113}>
                            <FlexDiv type={114}>
                                <TextDiv type={'auction'}>Drop is ENDED</TextDiv>
                                <Buttom type={'opensea'} onClick={()=>window.open("https://opensea.io/collection/project-rebirth")}>Buy on Opensea</Buttom>
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv type={11}>
                            <FlexDiv type={111}>
                                <Img src={SUB1} type={'sub1'}/>
                                {isPc&&<TextDiv type={'subnft'}>Infinite Sounds</TextDiv>}
                                {/* <RandingTimer type={'Subnft'} deadline={'2022-02-5 10:00:00'}/> */}
                                {sub1?<Button onClick={()=>isPc?BuyNFT(1):Alert()}>Mint</Button>:<Button>Sold Out</Button>}
                            </FlexDiv>
                            {!isPc&&<TextDiv type={'NftSmallTitle'}>Infinite Sounds</TextDiv>}
                            {!isPc&&<TextDiv type={'center2'}>
                                The birth of infinite realms: sounds, loops, beats... Let the interplay between sound and moving image manifest your deepest emotions.
                            </TextDiv>}
                            <FlexDiv type={111}>
                                <Img src={SUB2} type={'sub2'}/>
                                {isPc&&<TextDiv type={'subnft'}>Pure Frequency</TextDiv>}
                                {/* <RandingTimer type={'Subnft'} deadline={'2022-02-5 10:00:00'}/> */}
                                {sub2?<Button onClick={()=>isPc?BuyNFT(2):Alert()}>Mint</Button>:<Button>Sold Out</Button>}
                            </FlexDiv>
                            {!isPc&&<TextDiv type={'NftSmallTitle'}>Pure Frequency</TextDiv>}
                            {!isPc&&<TextDiv type={'center2'}>
                                The purity of abstract art: a constantly evolving dance to strip ourselves of everything external and be left with spiritual awareness.
                            </TextDiv>}
                            <FlexDiv type={111}>
                                <Img src={SUB3} type={'sub3'}/>
                                {isPc&&<TextDiv type={'subnft'}>Higher Reverb</TextDiv>}
                                {/* <RandingTimer type={'Subnft'} deadline={'2022-02-5 10:00:00'}/> */}
                                {sub3?<Button onClick={()=>isPc?BuyNFT(3):Alert()}>Mint</Button>:<Button>Sold Out</Button>}
                            </FlexDiv>
                            {!isPc&&<TextDiv type={'NftSmallTitle'}>Higher Reverb</TextDiv>}
                            {!isPc&&<TextDiv type={'center2'}>
                                Sonic consciousness that articulates the relation between sound, realization and self.
                            </TextDiv>}
                        </FlexDiv>
                        {isPc&&<TextDiv type={'NftSmallTitle'}>Infinite Sounds</TextDiv>}
                        {isPc&&<TextDiv type={'center'}>
                            The birth of infinite realms: sounds, loops, beats... Let the interplay between sound and moving image manifest your deepest emotions.
                        </TextDiv>}
                        {isPc&&<TextDiv type={'NftSmallTitle'}>Pure Frequency</TextDiv>}
                        {isPc&&<TextDiv type={'center'}>
                            The purity of abstract art: a constantly evolving dance to strip ourselves of everything external and be left with spiritual awareness.
                        </TextDiv>}
                        {isPc&&<TextDiv type={'NftSmallTitle'}>Higher Reverb</TextDiv>}
                        {isPc&&<TextDiv type={'center'}>
                            Sonic consciousness that articulates the relation between sound, realization and self.
                        </TextDiv>}
                        <Section id={'studiomuse'}></Section>
                        <TextDiv type={'Main NFT'}>Studio Muse</TextDiv>
                        <FlexDiv type={112}>
                            <video controls loop muted={"muted"} autoplay={"autoplay"} playsInline width={isPc?"540":"100%"}>
                                <source src={MAIN} type={"video/mp4"}/>
                            </video>
                        </FlexDiv>
                        <TextDiv type={'center'}>
                            Based on an original painting of Gaeko’s studio and exploring isolation as a creative act.
                            With intentionality and focus on your task, you exude signals that communicate to the Muse your
                            readiness to receive transmissions. The Muse guides, aids, and assists your endeavor.
                            Fueled by the feelings of joy that your creations bring to it.
                        </TextDiv>
                        <TextDiv type={'center'}>
                            Auction of Studio Muse will start on 9th February 20:00 EST via <OpenseaLink onClick={()=>window.open("https://opensea.io/assets/0x5d0deef81dc5b7ede1706a079e7be35e19cf9dce/301")}>opensea.io</OpenseaLink>
                        </TextDiv>
                        <FlexDiv type={113}>
                            <FlexDiv type={114}>
                                <TextDiv type={'auction'}>Auction is ENDED</TextDiv>
                                <Buttom type={'opensea'} onClick={()=>window.open("https://opensea.io/assets/0x5d0deef81dc5b7ede1706a079e7be35e19cf9dce/301")}>See on Opensea</Buttom>
                                <TextDiv type={'auction'}>Edition: 1 of 1, Starting Price: 0.22 WETH</TextDiv>
                            </FlexDiv>
                        </FlexDiv>
                        <TextDiv type={'Artists'}>Artists</TextDiv>
                    </Bottom>
                </FlexDiv>
            </Section>
            <Section type={2}>
                <FlexDiv type={2}>
                    <FlexDiv type={'profile'}>
                        <Img src={L003} type={2}/>
                    </FlexDiv>
                    <FlexDiv type={21}>
                        <FlexDiv type={22}>
                            <TextDiv type={2}>
                                A man of many talents, Gaeko is not only a rapper – he’s a writer, producer, composer, and founder of Amoeba Culture. Considered to always be ahead of his time, he is much-loved by the public for his music and the influence he’s had upon the hip-hop genre. As well as his own successful music career, Gaeko was selected as a producer for three seasons of the hip-hop survival program, Show Me The Money, where he proved his credentials by guiding teams to first and second place.
                            </TextDiv>
                            <Buttom type={'read more'} onClick={()=>ReadMore(0)}>Read more</Buttom>
                        </FlexDiv>
                        <FlexDiv type={23}>
                            <TextDiv type={'KLR'}>
                                Korean Legendary Rapper
                            </TextDiv>
                            <TextDiv type={'GAEKO'}>
                                GAEKO
                            </TextDiv>
                            <FlexDiv type={231}>
                                <FlexDiv>
                                    <Img type={'sosial'} src={L004} onClick={()=>window.open("https://www.instagram.com/gaekogeem")}/>
                                    {isPc&&<FlexDiv type={24}>@gaekogeem</FlexDiv>}
                                </FlexDiv>
                                <FlexDiv>
                                    <Img type={'sosial'} src={L005} onClick={()=>window.open("https://twitter.com/gaekogeem")}/>
                                    {isPc&&<FlexDiv type={24}>@gaekogeem</FlexDiv>}
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
            </Section>
            <Section type={3}>
                <FlexDiv type={3}>
                    <FlexDiv type={31}>
                        {!isPc&&
                        <FlexDiv type={33}>
                            <FlexDiv type={'profile'}>
                                <Img src={L006} type={3}/>
                            </FlexDiv>
                        </FlexDiv>
                        }
                        <FlexDiv type={32}>
                            <FlexDiv type={321}>
                                <TextDiv type={3}>
                                    Vector Meldrew sees the world from a different perspective than most people and finds his own pleasure there. He enjoys sublimating the hypocrisy of a commercialized society into laughter. Pieces created by Vector Meldrew focus on realizing the world and stories centered on essential themes such as culture, identity, and consciousness. During his career, he has directed the ending credits of the movie GoldenEye of the 007 series and later collaborated with various companies in various
                                </TextDiv>
                                <Buttom type={'read more'} onClick={()=>ReadMore(1)}>Read more</Buttom>
                            </FlexDiv>
                            <FlexDiv type={322}>
                                <TextDiv type={'DADBHP'}>Digital Artist deﬁned by his pieces</TextDiv>
                                <TextDiv type={'VECTOR MELDREW'}>VECTOR MELDREW</TextDiv>
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv type={33}>
                            {isPc&&<Img src={L006} type={3}/>}
                            <FlexDiv type={331}>
                                <FlexDiv type={332} onClick={()=>window.open("https://www.instagram.com/VectorMeldrew")}>
                                    <Img type={'sosial'} src={L004}/>
                                    {isPc&&<FlexDiv type={24}>@vectormeldrew</FlexDiv>}
                                </FlexDiv>
                                <FlexDiv onClick={()=>window.open("https://twitter.com/vectorMeldrew")}>
                                    <Img type={'sosial'} src={L005}/>
                                    {isPc&&<FlexDiv type={24}>@vectormeldrew</FlexDiv>}
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                    <FlexDiv type={31}>
                        <FlexDiv type={'profile'}>
                            <Img src={L007} type={4}/>
                        </FlexDiv>
                        <FlexDiv type={34}>
                            <FlexDiv type={341}>
                                <TextDiv type={2}>
                                    Drawing attention as a rising powerhouse in the hip-hop scene, BOYCOLD is becoming one of Korea’s most sought-after music producers. In his fledgling careers, he’s already worked on a large number of tracks for famous rappers, including Crucial Star, Sik-K, BewhY, HAON, and Jay Park, with his most recognized work being Sik-K’s EP BOYCOLD and the tracks YOUTH and Your Night. Recently, he was also featured as a producer on Show Me The Money. His name and influence are
                                </TextDiv>
                                <Buttom type={'read more'} onClick={()=>ReadMore(2)}>Read more</Buttom>
                            </FlexDiv>
                            <FlexDiv type={342}>
                                <TextDiv type={'KBCP'}>Korean Blue-Chip Producer</TextDiv>
                                <TextDiv type={'BOYCOLD'}>BOYCOLD</TextDiv>
                                <FlexDiv type={343}>
                                    <Img type={'sosial'} src={L004} onClick={()=>window.open("https://www.instagram.com/boycold_")}/>
                                    {isPc&&<FlexDiv type={24}>@boycold_</FlexDiv>}
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
            </Section>
            <Section type={4}>
                <FlexDiv type={4}>
                    <TextDiv type={41}>
                        Founder's NFT{isPc?' ':<br/>}Giveaway
                    </TextDiv>
                    <TextDiv type={42}>
                        Join us now and become one of our founding members. Sign up and connect your wallet until Feb 13, 2022 09:59 EST to be eligible for a pair of Founder's NFT. Get ready to claim yours now and receive exclusive benefits on future projects by Halliday's.
                    </TextDiv>
                    <Buttom type={'see more'} onClick={() => {history.push("/event");}}>Learn more</Buttom>
                </FlexDiv>
            </Section>
            {''&&<Section type={4}>
                    <ColumnDiv type={'section4'}>
                        <TextDiv type={'FAQ'}>FAQ</TextDiv>
                        <ColumnDiv>
                            <Section4Content no={0}>How do I cancel an offer?</Section4Content>
                            <Section4Content no={1}>How do I accept offers for the Nifty I am selling?</Section4Content>
                            <Section4Content no={2}>How can I add/remove a Credit Card?</Section4Content>
                            <Section4Content no={3}>Can I get a refund on a Nifty?</Section4Content>
                            <Section4Content no={4}>Can I split payments while purchasing a Nifty?</Section4Content>
                            <Section4Content no={5}>How do I change my Password?</Section4Content>
                        </ColumnDiv>
                    </ColumnDiv>
                </Section>}
        </MainDiv>
    );
}
const MainDiv = styled.div`
  /* pc 환경 */
  @media screen and (min-width : 1440px) {
    min-width : 1440px;
  }
  /* 테블릿 환경 */
  @media screen and (max-width : 1220px) {
    min-width : 100vw;
  }
`;
const Section = styled.section`
    display : flex;
    justify-content : center;
    flex-direction : column;

    ${props=>props.type===1&&`
        padding-top : 100px;
        background : #1a1a1a;
    `}
    ${props=>props.type===2&&`
        background : #212121;
    `}
    ${props=>props.type===3&&`
        background : linear-gradient(#1a1a1a 70%, #212121 1%);
        padding-bottom : 200px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            padding-bottom : 40px;
        }
    `}
    ${props=>props.type===4&&`
        padding-top : 100px;
        padding-bottom : 100px;
        background : #1a1a1a;
        @media screen and (max-width : 768px) {
            padding-top : 0px;
            padding-bottom : 40px;
        }
    `}
`;
const Top = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    
    /* pc 환경 */
    @media screen and (min-width : 769px) {
        position : relative;
    }

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        flex-direction : column;
    }
`;
const Bottom = styled.div`
    display : flex;
    justify-content : center;
    flex-direction : column;
    border-top : 1px solid #ffffff;
    border-right : 1px solid #ffffff;
    border-left : 1px solid #ffffff;
    margin-top : 100px;
    padding : 0 100px;

    /* pc 환경 */
    @media screen and (max-width : 1220px) {
        padding : 0;
    }
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        padding : 0;
        margin : 0 25px;
        margin-top : 20px;
    }
`;
const OpenseaLink = styled.strong`
    font-family : RBD;
    &:hover {
        cursor : pointer;
    }
`;
const Img = styled.img`
    ${props=>props.type==='top'&&`
        width : 100%;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100vw;
        }
    `}
    ${props=>props.type==='close'&&`
        width : 50px;
        &:hover {
          cursor : pointer;
        }
    `}
    ${props=>props.type==='sosial'&&`
        width : 50px;
        &:hover {
          cursor : pointer;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 30px;
        }
    `}
    ${props=>props.type==='main'&&`
        width : 560px;
        filter : brightness(0.5);

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0px auto;
            width : 100%;
        }
    `}
    ${props=>props.type==='sub1'&&`
        width : 300px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0px auto;
            width : 100%;
        }
    `}
    ${props=>props.type==='sub2'&&`
        width : 300px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px auto 0;
            width : 100%;
        }
    `}
    ${props=>props.type==='sub3'&&`
        width : 300px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px auto 0;
            width : 100%;
        }
    `}

    ${props=>props.type===2&&`
        margin-left : 100px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100%;
            margin : 0 auto;
        }
    `}
    ${props=>props.type===3&&`
        width : 490px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100%;
            margin : 0 auto;
        }
    `}
    ${props=>props.type===4&&`
        margin-left : 100px;
        width : 490px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100%;
            margin : 0 auto;
        }
    `}
`;
const TextDiv = styled.div`
    position : ${props=>props.type==='top'&& 'absolute'};
    color : #a3a3a3;

    ${props=>props.type==='close'&&`
        font-size : 18px;
        font-family : RL;
        line-height : 40px;
        text-align : justify;
        text-align-last: left;
        margin : 0 auto;
        padding : 60px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            display : flex;
            align-items : center;
            font-size : 14px;
            line-height : 20px;
            padding : 40px 20px;
            margin : 0 auto;
            height : 60vh;
        }
    `}
    ${props=>props.type==='top'&&`
        font-size : 50px;
        font-family : RBLI;
        color : #ffffff;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-bottom : 50px;
        }
    `}
    ${props=>props.type==='top2'&&`
        font-size : 50px;
        line-height : 60px;
        font-family : 명조;
        width : 840px;
        text-align : center;
        margin : 200px auto;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            white-space : pre-line;
            width : 200px;
            font-size : 30px;
            line-height : 35px;
            text-align : right;
            margin : 25px 25px 25px auto;
        }
    `}
    ${props=>props.type==='youtube'&&`
        margin : 0 auto;
    `}
    ${props=>props.type==='center'&&`
        text-align : justify;
        text-align-last: left; 
        font-size : 18px;
        line-height : 30px;
        font-family : RL;
        margin : 20px 0 20px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 20px 100px 20px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px 25px 20px;
            font-size : 14px;
            line-height : 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 20px 10px 40px;
            font-size : 13px;
            line-height : 15px;
        }
    `}
    ${props=>props.type==='center2'&&`
        text-align : justify;
        text-align-last: left; 
        font-size : 18px;
        line-height : 30px;
        font-family : RL;
        margin : 20px 0 20px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 20px 100px 20px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px 0px 20px;
            font-size : 14px;
            line-height : 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0px 10px 40px;
            font-size : 13px;
            line-height : 15px;
        }
    `}
    ${props=>props.type==='bottom'&&`
        text-align : justify;
        text-align-last: left; 
        font-size : 18px;
        line-height : 30px;
        font-family : RL;
        margin : 20px 0 50px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 20px 100px 200px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px 25px 40px;
            font-size : 14px;
            line-height : 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0px 10px 40px;
            font-size : 13px;
            line-height : 15px;
        }
    `}
    ${props=>props.type==='subnft'&&`
        font-size : 18px;
        font-family : RBL;
        text-align : right;
        color : #ffffff;
        margin : 50px auto;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-bottom : 50px;
        }
    `}
    ${props=>props.type==='mainNftSmallTitle'&&`
        font-size : 18px;
        font-family : RBL;
        margin-top : 40px;
        margin-bottom : 14px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 100px 100px 0;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 18px;
            margin-left : 25px;
            margin-top : 20px;
            margin-bottom : 0px;
        }
    `}
    ${props=>props.type==='NftSmallTitle'&&`
        font-size : 18px;
        font-family : RBL;
        margin-top : 40px;
        margin-bottom : 14px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 100px auto 0;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 18px;
            margin-left : 0px;
            margin-top : 20px;
            margin-bottom : 0px;
        }
    `}
    ${props=>props.type==='auction'&&`
        text-align : center;
        font-size : 20px;
        line-height : 30px;
        font-family : RBL;
        margin : 10px auto 10px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 16px;
            line-height : 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            font-size : 14px;
            line-height : 15px;
        }
    `}
    ${props=>props.type==='Artists'&&`
        font-size : 50px;
        font-family : RBL;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 0 100px 0;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-left : 25px;
        }
    `}
    ${props=>props.type==='PROJECT REBIRTH'&&`
        font-size : 50px;
        font-family : RBL;
        margin-top : 100px;
        margin-bottom : 50px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 100px 100px 0;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-left : 25px;
            margin-top : 50px;
            margin-bottom : 30px;
        }
    `}
    ${props=>props.type==='Main NFT'&&`
        font-size : 50px;
        font-family : RBL;
        margin-top : 130px;
        margin-bottom : 100px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 100px 100px 0;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-left : 25px;
            margin-top : 30px;
            margin-bottom : 50px;
        }
    `}
    ${props=>props.type==='KLR'&&`
        font-size : 18px;
        font-family : RLI;
        color : #a1a1a1;
        margin-left : 95px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin-left : 0;
            margin-bottom : 7px;
            font-size : 14px;
            text-align : center;
        }
    `}
    ${props=>props.type==='GAEKO'&&`
        font-size : 150px;
        font-family : RBLI;
        color : #ffffff;
        line-height : 120px;
        margin-left : 50px;

        

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 60px;
            line-height : 45px;
            margin-left : 0;
            text-align : center;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            font-size : 50px;
            line-height : 45px;
            margin-left : 0;
            text-align : center;
        }
    `}
    ${props=>props.type==='DADBHP'&&`
        font-size : 18px;
        font-family : RLI;
        color : #a1a1a1;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            margin-left : 210px;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 14px;
            margin-bottom : 7px;
            text-align : center;
        }
    `}
    ${props=>props.type==='VECTOR MELDREW'&&`
        font-size : 130px;
        font-family : RBLI;
        color : #ffffff;

        /* 테블릿 환경 */
        @media screen and (max-width : 1439px) {
            font-size : 130px;
        }

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            line-height : 140px;
            text-align : right;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 50px;
            line-height : 45px;
            text-align : center;
        }
    `}
    ${props=>props.type==='KBCP'&&`
        font-size : 18px;
        font-family : RLI;
        color : #a1a1a1;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            margin-left : 110px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            text-align : center;
            margin-bottom : 7px;
            font-size : 14px;
        }
    `}
    ${props=>props.type==='BOYCOLD'&&`
        font-size : 130px;
        font-family : RBLI;
        color : #ffffff;
        
        /* 테블릿 환경 */
        @media screen and (max-width : 1439px) {
            font-size : 130px;
        }
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            line-height : 120px;
            margin-left : 76px;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 50px;
            line-height : 40px;
            margin-left : 0;
            text-align : center;
        }
    `}
    ${props=>props.type===2&&`
        text-align : justify;
        text-align-last: left; 
        line-height : 30px;
        font-size : 18px;
        font-family : RL;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 14px;
            line-height : 20px;
            overflow : hidden;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            font-size : 13px;
            line-height : 15px;
        }
    `}
    ${props=>props.type===3&&`
        text-align : justify;
        text-align-last: left; 
        line-height : 30px;
        font-size : 18px;
        font-family : RL;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 14px;
            line-height : 20px;
            margin : 0 25px;
            overflow : hidden;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            font-size : 13px;
            line-height : 15px;
            margin : 0 10px;
        }
    `}
    ${props=>props.type===41&&`
        font-size : 50px;
        font-family : RBLI;
        color : #ffffff;
        
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            margin-top : 80px;
            text-align : center;
            margin-bottom : 50px;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-top : 50px;
            margin-left : 25px;
            margin-bottom : 20px;
        }
    `}
    ${props=>props.type===42&&`
        text-align : justify;
        text-align-last: left; 
        font-size : 18px;
        line-height : 30px;
        font-family : RL;
        margin : 20px 0 20px;
        padding : 0px 100px 0 100px;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            margin : 20px 100px 20px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            padding : 0 0 0 0;
            margin : 20px 25px 20px;
            font-size : 14px;
            line-height : 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            padding : 0 0 0 0;
            margin : 0px 10px 40px;
            font-size : 13px;
            line-height : 15px;
        }
    `}
    ${props=>props.type==='FAQ'&&`
        font-size : 50px;
        font-family : RBL;
        color : #ffffff;
        margin : 50px auto;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 30px;
            margin-bottom : 50px;
        }
    `}
    ${props=>props.type==='section4 content'&&`
        font-size : 28px;
        color: #808080;
        margin : 25px 10px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height : 14px;
            margin : 10px 2px;
        }
    `}
`;
const FlexDiv = styled.div`
    display : flex;
    ${props=>props.type==='close'&&`
        flex-direction : column;
        align-items : flex-end;
        width : 1110px;
        margin : 0 auto;
        background : #212121;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 93vw;
        }
    `}
    ${props=>props.type==='profile'&&`
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            padding : 0 25px 0 25px;
        }
    `}
    ${props=>props.type===0&&`
        flex-direction : column;
        width : 1222px;
        margin : 0 auto;

        /* pc 환경 */
        @media screen and (max-width : 1220px) {
            width : 100vw;
        }
        
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100vw;
            margin : 0;
        }
    `}
    ${props=>props.type===2&&`
        border-right : 1px solid #ffffff;
        border-left : 1px solid #ffffff;
        padding : 100px 0;
        margin : 0 auto;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            width : 100vw;
        }
        /* pc 환경 */
        @media screen and (min-width : 1220px) {
            width : 1220px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            flex-direction : column;
            margin : 0 25px;
            padding : 30px 0;
        }
    `}
    ${props=>props.type===3&&`
        border-right : 1px solid #ffffff;
        border-left : 1px solid #ffffff;
        border-bottom : 1px solid #ffffff;
        margin : 0 auto;
        flex-direction : column;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            width : 100vw;
        }
        /* pc 환경 */
        @media screen and (min-width : 1220px) {
            width : 1220px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 25px;
        }
    `}
    ${props=>props.type===4&&`
        border-top : 1px solid #06C555;
        border-right : 1px solid #06C555;
        border-left : 1px solid #06C555;
        border-bottom : 1px solid #06C555;
        margin : 0 auto;
        flex-direction : column;
        
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            align-items : center;
            width : 100vw;
        }
        /* pc 환경 */
        @media screen and (min-width : 1220px) {
            align-items : center;
            width : 1220px;
        }
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 25px;
        }
    `}
    ${props=>props.type===11&&`
        align-items: start;
        display : flex;
        justify-content : space-between;
        flex-direction : row;  

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 25px;
            flex-direction : column;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0 10px;
            flex-direction : column;
        }
    `}
    ${props=>props.type===111&&`
        align-items: start;
        display : flex;
        justify-content : center;
        flex-direction : row; 

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            align-items: center;
            vertical-align : middle;
            margin : 0 auto;
            justify-content : center;
            flex-direction : column;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0 auto;
            flex-direction : column;
        }
    `}
    ${props=>props.type===112&&`
        align-items: start;
        display : flex;
        justify-content : center;
        margin-bottom : 50px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            padding : 0 25px 0;
            align-items: center;
            vertical-align : middle;
            margin : 0 auto 20px;
            justify-content : center;
            flex-direction : column;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0 auto;
            flex-direction : column;
        }
    `}
    ${props=>props.type===113&&`
        display : flex;
        justify-content : center;
        margin : 10px auto 100px;
        height : 250px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            height : 200px;
            padding : 0 0 0;
            align-items: center;
            vertical-align : middle;
            margin : 0 0 20px;
            justify-content : center;
            flex-direction : column;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            height : 170px;
            margin : 0 auto;
            flex-direction : column;
        }
    `}
    ${props=>props.type===114&&`
        display : flex;
        flex-direction : column;
        justify-content : center;
        text-align : center;
        margin : 0 10px 50px;
        height : 200px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            height : 150px;
            width : auto;
            align-items: center;
            vertical-align : middle;
            margin : 0 0 0px;
            justify-content : center;
            flex-direction : column;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0 auto;
            height : 120px;
            flex-direction : column;
        }
    `}
    ${props=>props.type===21&&`
        margin-right : 100px;
        margin-left : 30px;
        min-width : 490px;
        min-width : 490px;
        flex-direction : column;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : relative;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            min-width : 280px;
            margin : 30px auto 20px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            min-width : 180px;
        }
    `}
    ${props=>props.type===22&&`
        align-items: stretch;
        flex-direction : column;   

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 25px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 0 10px;
        }
    `}
    ${props=>props.type===23&&`
        flex-direction : column;
        bottom : 0;
        right : -110px;

        /* 테블릿 환경 */
        @media screen and (max-width : 1439px) {
            right : -50px;
        }
        /* pc 환경 */
        @media screen and (min-width : 769px) {
            right : -50px;
            position : absolute;
        }
    `}
    ${props=>props.type===231&&`
        margin-top : 40px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 80px;
            justify-content : space-between;
            margin : 40px auto 30px;
        }
    `}
    ${props=>props.type===24&&`
        font-family : RL;
        font-size : 18px;
        align-items: center;
        color : #a1a1a1;
        margin : 0 15px;
        &:hover {
          cursor : pointer;
        }
    `}
    ${props=>props.type===31&&`
        margin : 100px 0;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            flex-direction : column;
            margin : 0 0 40px 0;
        }
    `}
    ${props=>props.type===32&&`
        flex-direction : column;
        margin-left : 100px;
        margin-right : 30px;
        min-width : 490px;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : relative;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            min-width : 200px;
            margin : 15px 0;
        }
    `}
    ${props=>props.type===321&&`
        flex-direction : column;
        align-items: flex-end;
    `}
    ${props=>props.type===322&&`
        flex-direction : column;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
            bottom : 0;
            margin-left : -170px;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {

        }
    `}
    ${props=>props.type===33&&`
        flex-direction : column;
        margin-right : 100px;
        min-width : 490px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            min-width : 200px;
            margin-right : 0;
        }
    `}
    ${props=>props.type===331&&`
        margin-top : 40px;
        justify-content: flex-end;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            justify-content: space-between;
            margin : 40px auto 30px;
            width : 80px;
        }
    `}
    ${props=>props.type===332&&`
        margin-right : 20px;
        justify-content: flex-end;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin-right : 0;
        }
    `}
    ${props=>props.type===34&&`
        flex-direction : column;
        margin-left : 30px;
        margin-right : 100px;
        min-width : 490px;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : relative;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            min-width : 280px;
            margin-left : 0;
            margin-right : 0;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            min-width : 180px;
        }
    `}
    ${props=>props.type===341&&`
        flex-direction : column;
        align-items: flex-end;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 15px 25px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 380px) {
            margin : 15px 10px;
        }
    `}
    ${props=>props.type===342&&`
        flex-direction : column;
        bottom : 0;
        left : -40px;

        /* pc 환경 */
        @media screen and (min-width : 769px) {
            position : absolute;
        }
    `}
    ${props=>props.type===343&&`
        margin-top : 60px;
        margin-left : 140px;
        justify-content: center;
        
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 40px 0 30px;
        }
    `}
    ${props=>props.type==='section4 content'&&`
        width : 100%;
        justify-content : space-between;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
`;

const Buttom = styled.button`

    ${props=>props.type==='read more'&&`
        width : 170px;
        height : 50px;
        border : 1px solid #a1a1a1;
        border-radius : 10px;
        background-color : rgba(0, 0, 0, 0);
        font-family : RBD;
        font-size : 16px;
        color : #a1a1a1;
        margin : 20px 0;
          
        &:hover {
           border : 1px solid green;
           color : green;
           cursor : pointer;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 200px;
            height : 30px;
            margin : 20px auto;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 768px) {
            width : 130px;
            height : 30px;
            margin : 20px auto;
        }
    `}
    ${props=>props.type==='see more'&&`
        width : 170px;
        height : 50px;
        border : 1px solid #06C555;
        border-radius : 10px;
        background-color : rgba(0, 0, 0, 0);
        font-family : RBD;
        font-size : 16px;
        color : #06C555;
        margin : 20px 0 80px;
          
        &:hover {
           border : 1px solid green;
           color : green;
           cursor : pointer;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 200px;
            height : 30px;
            margin : 20px auto 50px;
        }
        /* 폴드 환경 */
        @media screen and (max-width : 768px) {
            width : 130px;
            height : 30px;
            margin : 20px auto 50px;
        }
    `}
    ${props=>props.type==='opensea'&&`
        width : 170px;
        height : 50px;
        border : 1px solid #06C555;
        border-radius : 10px;
        background-color : rgba(0, 0, 0, 0);
        font-family : RBD;
        font-size : 16px;
        color : #06C555;
        margin : 0 auto;
          
        &:hover {
           border : 1px solid green;
           color : green;
           cursor : pointer;
        }

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 170px;
            height : 50px;
        }
    `}
`;

const ToggleBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  font-size: 16px;
  color: #a0a0a0;
  position: fixed;
  font-family: PoppinsMedium;
  width: 100vw;
  height: 100%;
  background: rgba(21, 21, 21, 0.84);
  animation: ${(props) => props.toggle && toggleShow} 0.6s forwards;
  backdrop-filter: blur(40px);
`;
const toggleShow = keyframes`
  0% {transform: translateY(-200%);}
  100% {transform: translateY(0%);}
`;

const Line = styled.div`
  border-bottom : 1px solid #ffffff;
  width : 1020px;
  margin : 0 100px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
      width : 280px;
      margin : 0 auto;
  }
  /* 폴드 환경 */
  @media screen and (max-width : 380px) {
      width : 180px;
  }
`;
const ColumnDiv = styled.div`
    display : flex;
    flex-direction : column;
    ${props=>props.type==='section4'&&`
        margin : 50px auto;
    `}
    ${props=>props.type==='section4 text'&&`
        margin : 20px auto;
        width : 920px;
        font-family : RL;
        font-size : 14px;
        line-height : 25px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
    ${props=>props.type==='section4 text2'&&`
        margin : 20px auto;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
`;

const Section4Content = ({children, ...props}) =>{
    const [Toggle, setToggle] = useState(false);

    return <ColumnDiv>
        <FlexDiv type={'section4 content'} onClick={()=>setToggle(!Toggle)}>
            <TextDiv type={'section4 content'}>{children}</TextDiv>
            <TextDiv type={'section4 content'}>&or;</TextDiv>
        </FlexDiv>
        {Toggle&&
        <ColumnDiv type={'section4 text'}>
            <TextDiv>
                {Data[props.no][0]}
            </TextDiv>
            <ColumnDiv type={'section4 text2'}>
                <TextDiv>
                    {Data[props.no][1]}
                </TextDiv>
                <TextDiv>
                    {Data[props.no][2]}
                </TextDiv>
                <TextDiv>
                    {Data[props.no][3]}
                </TextDiv>
            </ColumnDiv>
            <TextDiv>
                {Data[props.no][4]}
            </TextDiv>
        </ColumnDiv>
        }
    </ColumnDiv>
}
const Button = styled.button`
    margin-top : 430px;
    position : absolute;
    background : rgba(0,0,0,0.3);
    border : 1px solid rgba(6, 197, 85, 1);
    border-radius: 11px;
    width : 160px;
    height : 50px;
    color : rgba(6, 197, 85, 1);
    font-family : RBD;
    font-size : 16px;
    cursor : pointer;

    &:hover {
        background : rgba(0,0,0,0.5);
        border : 1px solid rgba(6, 197, 85, 0.6);
    }

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        margin-top: 20px;
        position: inherit;
    }
`;
const AuctionButton = styled.button`
    background : rgba(0,0,0,0);
    border : 1px solid rgba(6, 197, 85, 1);
    border-radius: 11px;
    width : 160px;
    height : 50px;
    color : rgba(6, 197, 85, 1);
    font-family : RBD;
    font-size : 16px;
    cursor : pointer;
    margin : 0px auto 0px auto;

    &:hover {
        background : rgba(0,0,0,0.5);
        border : 1px solid rgba(6, 197, 85, 0.6);
    }
`;
const Data = [
    [
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ]
];

export default RandingPage;