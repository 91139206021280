import React from 'react';
import styled from "styled-components";

const Section1 = styled.section`
  text-align : left;
  padding: 736px 0 120px 0;
  font-family: PoppinsMedium;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding: 100vw 0 25vw 0;
  }
`;

const Content1 = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: #909090;
  width: 944px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 16px;
    line-height: 24px;
    width: 86vw;
    margin: 16px 0 16px 0;
  }
`;
const Content2 = styled.div`
  font-size: 37px;
  line-height: 55px;
  color: #FFFFFF;
  width: 944px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 20px;
    line-height: 30px;
    width: 86vw;
  }
`;

function AboutSection1(props) {
    return (
        <Section1>
          <Content1>
              The Halliday's Mission
          </Content1>
          <Content2>
              Halliday's NFT marketplace and social media platform were founded with the mission of connecting artists and fans while contributing to a shared economy.
          </Content2>
        </Section1>
    );
}

export default AboutSection1;