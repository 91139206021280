import React from 'react';
import styled from 'styled-components';
import FlexDiv from '../../common/styled/FlexDiv';
import TextDiv from '../styled/TextDiv';
import Ul from '../styled/TextUl.jsx';
import Li from '../styled/TextLi.jsx';
import { useMediaQuery } from "react-responsive";


function PrivacyPolicy(props) {
    const isPc = useMediaQuery({query: "(min-width:768px)"});
    return (
        <FlexDiv flexDirection={'column'} padding={'160px 0 0 0'} id="PrivacyPolicy">
            <TextDiv type={'title'}>Privacy Policy</TextDiv>
            <Div>
                <TextDiv type={'contentS'}>Halliday’s (“we” or “us”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Information (as defined below) we collect, why we collect it, and how we use and disclose it.</TextDiv>
                <TextDiv type={'contentS'} margin={'24px auto 0 auto'}>Your privacy matters to us, so please do take the time to get to know and familiarize yourself with our policies and practices. Please understand that we reserve the right to change any of our policies and practices at any time, but you can always find the latest version of this Privacy Policy here on this page. </TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>Personal Information We Collect</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>As used herein, “Personal Information” means information that identifies or is reasonably capable of identifying an individual, directly or indirectly, and information that is capable of being associated with an identified or reasonably identifiable individual. </TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitleS'}>1) Personal Information we collect from you</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We may collect the following categories of Personal Information directly from you:</TextDiv>
                <Ul>
                    <Li margin={'1px 0'} type={'contentS'}>Identification Information, such as name, email, phone number, postal address;</Li>
                    <Li margin={'1px 0'} type={'contentS'}>Correspondence, such as information you provide to us in correspondence, including account opening and customer support; and</Li>
                </Ul>
            </Div>
            <Div>
                <TextDiv type={'subTitleS'}>2) Personal Information we collect automatically</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We may collect the following categories of Personal Information automatically through your use of our services:</TextDiv>
                <Ul>
                    <Li margin={'1px 0'} type={'contentS'}>Online Identifiers, such as IP address, domain name;</Li>
                    <Li margin={'1px 0'} type={'contentS'}>Device Information, such as hardware, operating system, browser;</Li>
                    <Li margin={'1px 0'} type={'contentS'}>Usage Data, such as system activity, internal and external information related to Halliday's pages that you visit, clickstream information;</Li>
                    <Li margin={'1px 0'} type={'contentS'}>and Geolocation Data.</Li>
                </Ul>
                <TextDiv type={'contentS'}>Our automatic collection of Personal Information may involve the use of Cookies, described in greater detail below. </TextDiv>
            </Div>

            <Div>
                <TextDiv type={'subTitleS'}>3) Personal Information we collect from third parties</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We may collect and/or verify the following categories of Personal Information about you from third parties:</TextDiv>
                <Ul>
                    <Li margin={'1px 0'} type={'contentS'}>Identification Information, such as name, email, phone number, postal address</Li>
                    <Li margin={'1px 0'} type={'contentS'}>Additional Information, at the our discretion to comply with legal obligations.</Li>
                </Ul>
            </Div>
            <Div>
                <TextDiv type={'subTitleS'}>4) Accuracy and retention of Personal Information</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We take reasonable and practicable steps to ensure that your Personal Information held by us is (i) accurate with regard to the purposes for which it is to be used, and (ii) not kept longer than is necessary for the fulfillment of the purpose for which it is to be used. </TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>How We Use Your Personal Information</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We collect Personal Information about you in an attempt to provide you with the best experience possible, protect you from risks related to improper use and fraud, and help us maintain and improve our Services. We may use your Personal Information to:</TextDiv>
                <Ul type={1}>
                    <Li margin={'1px 0'} type={'contentS'}>Provide you with our Services. We use your Personal Information to provide you with our Services pursuant to the terms of our Terms of Use. For example, in order to facilitate fiat transfers out of your account, we need to know your financial information. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Comply with legal and regulatory requirements. We process your Personal Information as required by applicable laws and regulations. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Detect and prevent fraud. We process your Personal Information to detect and prevent fraud on your account, which is especially important given the irreversible nature of cryptocurrency transactions. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Protect the security and integrity of our Services. We use your Personal Information, including information about your device and your activity on Halliday's to maintain the security of your account and the Halliday's platform. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Provide you with customer support. We process your Personal Information when you contact our support team with questions about or issues with your account. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Market our products. We may contact you with information about our Services. We will only do so with your permission, which can be revoked at any time. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Other business purposes. We may use your Personal Information for additional purposes if that purpose is disclosed to you before we collect the information or if we obtain your consent. </Li>
                </Ul>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>How We Share Your Personal Information</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>We will not share your Personal Information with third parties, except as described below:</TextDiv>
                <Ul type={1}>
                    <Li margin={'1px 0'} type={'contentS'}>
                        Service Providers. We may share your Personal Information with third-party service providers for business or commercial purposes, including fraud detection and prevention, security threat detection, payment processing, customer support, data analytics, Information Technology, advertising and marketing, network infrastructure, storage, transaction monitoring. We share your Personal Information with these service providers only so that they can provide us with the services, and we prohibit our service providers from using or disclosing your Personal Information for any other purpose.
                        <Ul type={2}>
                            <Li margin={'1px 0'} type={'contentS'}>You authorize us to collect and share with our payments provider Dwolla, Inc. your Personal Information including full name, email address and financial information, and you are responsible for the accuracy and completeness of that data. Dwolla’s Privacy Policy is available here.</Li>
                        </Ul>
                    </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Law Enforcement. We may be compelled to share your Personal Information with law enforcement, government officials, and regulators. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Corporate Transactions. We may disclose Personal Information in the event of a proposed or consummated merger, acquisition, reorganization, asset sale, or similar corporate transaction, or in the event of a bankruptcy or dissolution. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Professional Advisors. We may share your Personal Information with our professional advisors, including legal, accounting, or other consulting services for purposes of audits or to comply with our legal obligations. </Li>
                    <Li margin={'1px 0'} type={'contentS'}>Consent. We may share your Personal Information with your consent.</Li>
                </Ul>
                <TextDiv type={'contentS'}>If we decide to modify the purpose for which your Personal Information is collected and used, we will amend this Privacy Policy.</TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>Cookies</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 24px auto'}>When you access Halliday's, we may make use of the standard practice of placing tiny data files called cookies, flash cookies, pixel tags, or other tracking tools (herein, “Cookies”) on your computer or other devices used to visit Halliday's. We use Cookies to help us recognize you as a customer, collect information about your use of Halliday's to better customize our services and content for you, and collect information about your computer or other access devices to: (i) ensure that your account security has not been compromised by detecting irregular, suspicious, or potentially fraudulent account activities; (ii) assess and improve our services and advertising campaigns.</TextDiv>
                <TextDiv type={'contentS'}>You also can learn more about cookies by visiting http://www.allaboutcookies.org, which includes additional useful information on cookies and how to block cookies on different types of browsers and mobile devices. Please note that if you reject cookies, you will not be able to use some or all of Halliday's. If you do not consent to the placing of Cookies on your device, please do not visit, access, or use Halliday's.</TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>Direct Marketing</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>Subject to applicable laws and regulations, we may from time to time send direct marketing materials promoting services, products, facilities, or activities to you using information collected from or about you. You may also opt-out of such communications by following the directions provided in any marketing communication. It is our policy to not provide your Personal Information for those third parties’ direct marketing purposes without your consent.</TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>Information Security</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>No security is foolproof, and the Internet is an insecure medium. We cannot guarantee absolute security, but we work hard to protect Halliday's and you from unauthorized access to or unauthorized alteration, disclosure, or destruction of Personal Information we collect and store. Measures we take include encryption of the Halliday's website communications with SSL; </TextDiv>
                <TextDiv type={'contentS'}>optional two-factor authentication; periodic review of our Personal Information collection, storage, and processing practices; </TextDiv>
                <TextDiv type={'contentS'}>nd restricted access to your Personal Information on a need-to-know basis for our employees, contractors and agents who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.</TextDiv>
            </Div>
            <Div>
                <TextDiv type={'subTitle'}>Contact Us</TextDiv>
                <TextDiv type={'contentS'} margin={'10px auto 0 auto'}>If you have questions or concerns regarding this policy or our processing of your Personal Information, please feel free to email us at info@hallidays.io.</TextDiv>
            </Div>
        </FlexDiv>
    );
}

const Div = styled.div`
    margin : 24px 0;
`;
export default PrivacyPolicy;