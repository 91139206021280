import React from 'react';
import styled from 'styled-components';
import AboutSection1 from "./styled/AboutSection1.jsx";
import AboutSection2 from "./styled/AboutSection2.jsx";
import AboutSection3 from "./styled/AboutSection3.jsx";
import AboutSection4 from "./styled/AboutSection4.jsx";
import AboutSection5 from "./styled/AboutSection5.jsx";
import AboutSection6 from "./styled/AboutSection6.jsx";
import Section1Img from "./img/aboutSection1.png";
import Section3Img from "./img/aboutSection3.png";
import Section6Img from "./img/aboutSection6.png";

const AboutMainDiv = styled.div`
  text-align: left;
`;

const Container1 = styled.article`
  background-image: url(${Section1Img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow:-20000px 0 0 0 rgba(0,0,0,0.6) inset;
  display : flex;
  justify-content: center;

`;
const Container2 = styled.article`
  background-color: rgba(28, 28, 28, 1);
  display : flex;
  justify-content: center;
`;
const Container3 = styled.article`
  background-image: url(${Section3Img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow:-20000px 0 0 0 rgba(0,0,0,0.6) inset;
  display : flex;
  justify-content: center;
`;
const Container4 = styled.article`
  background-color: #121212;
  display : flex;
  justify-content: center;
`;
const Container5 = styled.article`
  background-color: #1C1C1C;
  display : flex;
  justify-content: center;
`;
const Container6 = styled.article`
  background-image: url(${Section6Img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow:-20000px 0 0 0 rgba(0,0,0,0.6) inset;
  display : flex;
  justify-content: center;
  
`;
function AboutPage(props) {
    return (
        <AboutMainDiv>
          <Container1>
              <AboutSection1 />
          </Container1>
          <Container2>
              <AboutSection2 />
          </Container2>
          <Container3>
              <AboutSection3 />
          </Container3>
          <Container4>
              <AboutSection4 />
          </Container4>
        </AboutMainDiv>
    );
}

export default AboutPage;

