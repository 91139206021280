import React, { useState } from 'react';
import styled from 'styled-components';
import EventCUBE1 from './img/Event CUBE 1.gif';
import MEventCUBE1 from './img/M Event CUBE 1.gif';
import hallicube from './img/hallicube.png';
import Mhallicube from './img/M_hallicube.png';
import Event5_M11 from './img/Event5_M2.gif';
import Event511 from './img/Event5-2.gif';
import NSGK01 from './img/NS head Gaeko wire.png';
import NSGK02 from './img/NSVT03.png';
import MNSGK02 from './img/MNSGK02.png';
import NSVT01 from './img/NSVT01.png';
import MNSVT02 from './img/MNSVT03.png';
import ST01 from './img/ST04.png';
import MST01 from './img/ST04.png';
import ST021 from './img/AR01.png';
import MST021 from './img/MAR01.png';
import ST02 from './img/ST03.png';
import MST02 from './img/MST03.png';
import TV01 from './img/TV01.png';
import MTV01 from './img/MTV01.png';
import TV02 from './img/TV03.png';
import MTV02 from './img/MTV03.png';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import axios from 'axios';
import {web3, injected, NETWORKID} from '../../js/Web3.js';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import OGKP1 from './video/O_GK_P1.mp4';
import OVTN1 from './video/O_VT_N1_2.mp4';
import hallitv01 from './video/O_hallitv01.mp4';
import halliroom from './video/O_halliroom.mp4';
import REBIRTH3D from './video/REBIRTH 3D.mp4';

function EventComponent() {
    const history = useHistory();
    const [cookies, setCookie, removeCookie] = useCookies();
    const isPc = useMediaQuery({ query: "(min-width:768px)" });
    const {
        activate,
      } = useWeb3React();
    const [EventState, setEventState] = useState(0); // 0 : sign up, 1 : connectwallet, 2 : job done
    const { certmsg } = useSelector(({ certmsg }) => ({
        certmsg: certmsg.msg,
      }));

    const EventStateRun = async () => {
      const authorization = cookies.authorization;

      // 메타마스크 설치 확인
      if (!window.ethereum) {
       // 해당 토큰인증정보와 wallet 정보 조회
       await axios
       .post(
         process.env.REACT_APP_API_URL + "/user/login/authorization",{},
         {
           headers: {
             authorization: authorization,
           },
         }
       )
       .then((res) => {
         if (res.status === 200) {
            setEventState(1);
         }
        })
      } else {
        const _wallet = await web3.eth.getAccounts();
        /**
          @지갑연결조회 
        */
        axios.post(process.env.REACT_APP_API_URL + "/user/cert/authorization", { wallet: _wallet[0] },
        {
          headers: {
            authorization: authorization,
          },
        }).then((res)=>{
          if(res.status===200){
            const {cert, wallet_address} = res.data;
            //console.log("Event cert >>", cert);
            //console.log("Event wallet_address >>", wallet_address);
            setEventState(cert+1);
          }

          
        }).catch((err)=>{
          console.log(process.env.REACT_APP_API_URL + "/user/cert/authorization error >>> ",err);
        })
      }
    }
    const connectWallet = async () => {
        try {
            if (!window.ethereum) {
                // 설치x 설치 페이지로 이동
                Swal.fire({
                  icon: "info",
                  title: "You need to install Metamask",
                  text: `
                    Would you like to go to the installation page? (We do not support Metamask mobile app.)
                  `,
                  showDenyButton: true,
                  confirmButtonText: "Yes",
                  denyButtonText: "No",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                      window.open("https://metamask.io/download")
                    } else if (result.isDenied) {
                      //window.location.reload();
                    }
                });
            }else {
                const networkId = await web3.eth.net.getId();
                const _account = await web3.eth.getAccounts(); 
                activate(injected).then(async ()=>{
                //console.log("networkId >>", networkId);
                const chainId = web3.utils.numberToHex(NETWORKID);
      
                // ropsten test network 연결
                if(networkId!==NETWORKID){
                    //ropsten net 으로 변경
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chainId }],
                    });
                }

                // 메타마스크 서명
                const sign_cert_data = await web3.eth.personal.sign(certmsg, _account[0], "");
                const authorization = cookies.authorization;
                
                axios.post(process.env.REACT_APP_API_URL+'/user/sign/metamask', {
                    wallet_address : _account[0],
                    cert_data : sign_cert_data,
                    msg : certmsg
                },{
                    headers: {
                      authorization: authorization,
                    },
                })
                .then((res)=>{
                  //console.log(res);
                  if(res.status===200){
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Wallet Connected!",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then(()=>window.location.reload())
                  }
                })
                .catch((err)=>{
                    console.log("ConnectButton >> ConnectWallet >> /user/sign/metamask request error >>", err);
                })
            });
                
            }
          } catch (error) {
            console.log("ConnectButton >> ConnectWallet >> error", error);
          }
    }
    
    useEffect(()=>{
        EventStateRun();
    },[])
    return (
        <Main>
            <Article img={isPc?Event511:Event5_M11} type={'article top'}>
                <ColumnDiv type={'top'}>
                    <ColumnDiv>
                        <TextDiv type={1}>
                            FREE NFT GIVEAWAY EVENT
                        </TextDiv>
                        <TextDiv type={2}>
                            JOIN NOW
                        </TextDiv>
                        <ColumnDiv>
                            <TextDiv type={2}>
                                TO BECOME
                            </TextDiv>
                            <TextDiv type={2}>
                                A FOUNDING MEMBER!
                            </TextDiv>
                        </ColumnDiv>
                    </ColumnDiv>
                    <ColumnDiv type={1}>
                        <TextDiv type={3}>
                            Event Ended! Thank you for your support!
                        </TextDiv>
                    </ColumnDiv>
                </ColumnDiv>
            </Article>
            <Article type={'article bottom'}>
                <Section type={1}>
                    <ColumnDiv type={2}>
                        <TextDiv type={'section1 top'}>
                            FOUNDER'S NFT
                        </TextDiv>
                        <TextDiv type={'section1 content'}>
                            Halliday’s, a curated marketplace for non-fungible token(NFTs)
                            created in collaboration between K-celebrities and renown
                            digital artists, will reward everyone who RSVPs with free
                            Founder’s NFT to celebrate its first collaboration project
                            between ‘Gaeko’ of of Dynamic Duo and digital artist
                            ‘Vector Meldrew’. Every eligible account will receive one
                            ‘Halli Cube' NFT and another random NFT from the four different
                            types created to celebrate project ‘Rebirth’
                        </TextDiv>
                        <TextDiv type={'section1 top'}>
                            HOW TO RECEIVE FOUNDER'S NFT
                        </TextDiv>
                        <TextDiv type={'section1 content'}>- Create an account and connect your wallet by Feb 13th.
                            A random free Founder’s NFT will be available for minting after Feb 14th.
                            Exact date will be announced at <TwitterLink onClick={()=>window.open("https://twitter.com/hallidays_io/")}> @hallidays_io </TwitterLink></TextDiv>
                        <TextDiv type={'section1 content'}>- Wallet needs to be linked by Feb 13th. Registered accounts without
                            wallet link will not be eligible for the Founder’s NFT drop.
                            Please note that you may only link a single wallet to your account.</TextDiv>
                        <TextDiv type={'section1 content'}>- Check your event eligibility on this webpage. If you have
                            completed both sign up and wallet link, the button below
                            will be deactivated and display “Congratulation”.</TextDiv>
                        <ColumnDiv type={'section3 div'}>
                            <Button type={'Sign Up'}>Event Ended</Button>
                            <TextDiv type={'section3 content'}>Event has ended. If you want some, visit <TwitterLink onClick={()=>window.open("https://opensea.io/collection/hallidays-founders")}>our collection on Opensea</TwitterLink></TextDiv>
                        </ColumnDiv>
                        <TextDiv type={'section1 top content'}>Wanna see yours? See <TwitterLink onClick={()=>window.open("https://medium.com/@hallidays/how-to-check-your-founders-nft-d44b57517f7b")}> our Medium post.</TwitterLink></TextDiv>
                        <ColumnDiv type={'section1 top'}>
                            <TextDiv type={'section1 top title'}>Event Timeline</TextDiv>
                            <ColumnDiv type={'Event Timeline'}>
                                <TextDiv type={'section1 top subtitle'}>DURATION</TextDiv>
                                <TextDiv type={'section1 top content'}>22.01.27 00:00 AM KST ~ 22.02.13 23:59 PM KST</TextDiv>
                                <TextDiv type={'section1 top content'}>22.01.26 10:00 AM EST ~ 22.02.13 09:59 AM EST</TextDiv>
                            </ColumnDiv>
                            <ColumnDiv type={'Event Timeline'}>
                                <TextDiv type={'section1 top subtitle'}>MINT DATE</TextDiv>
                                <TextDiv type={'section1 top content'}>Minting will be available after Feb 14th.</TextDiv>
                                <TextDiv type={'section1 top content'}>Please check <TwitterLink onClick={()=>window.open("https://twitter.com/hallidays_io/")}>@hallidays_io</TwitterLink> for the exact date (TBA).</TextDiv>
                            </ColumnDiv>
                        </ColumnDiv>
                    </ColumnDiv>
                    <ColumnDiv type={3}>
                        <Img type={'one'} src={isPc?EventCUBE1:MEventCUBE1}/>

                        <ColumnDiv type={'section1 bottom'}>
                            <TextDiv type={'Halli Cube'}>Halli Cube</TextDiv>
                            <TextDiv type={'content'}>Digitized re-interpretation of Halliday’s logo “H” in the form of a spinning cube.</TextDiv>
                            <Img type={'one'} src={isPc?hallicube:Mhallicube}/>
                        </ColumnDiv>
                    </ColumnDiv>
                </Section>
                <Section type={2}>
                    <FlexDiv type={'item'}>
                        {!isPc&&
                        <video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={OVTN1} type={"video/mp4"}/>
                        </video>}
                        <ColumnDiv type={'content'}>
                            <TextDiv type={'name'}>N/S head Vector</TextDiv>
                            <TextDiv type={'content'}>Vector Meldrew shaking and nodding with his signature grin on his face. Does it mean a yes or no?</TextDiv>
                            <Img margin={isPc?'':'0 auto'} width={isPc?'345px':'234px'} src={NSVT01}/>
                            <Img src={isPc?NSGK02:MNSVT02}/>    
                        </ColumnDiv>
                        {isPc&&
                        <video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={OVTN1} type={"video/mp4"}/>
                        </video>}
                    </FlexDiv>
                    <FlexDiv type={'item'}>
                        <video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={hallitv01} type={"video/mp4"}/>
                        </video>
                        <ColumnDiv type={'content'}>
                            <TextDiv type={'name'} right={isPc?true:false}>HalliTV</TextDiv>
                            <TextDiv type={'content'}>Stay tuned for Halliday’s 1st NFT collaboration ‘REBIRTH’. DO NOT CHANGE THE CHANNEL!</TextDiv>
                            <Img margin={isPc?'0 0 0 auto':'0 auto'} width={isPc?'332px':'208px'} src={isPc?TV01:MTV01}/>
                            <Img src={isPc?TV02:MTV02}/>
                        </ColumnDiv>
                    </FlexDiv>
                    <FlexDiv type={'item'}>
                        {!isPc&&<video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={halliroom} type={"video/mp4"}/>
                        </video>}
                        <ColumnDiv type={'content'}>
                            <TextDiv type={'name'}>Halli Showtime</TextDiv>
                            <TextDiv type={'content'}>Are you guys ready? It's show time for Halliday's and the rest of the world as we launch our 1st collaboration project 'REBIRTH'.</TextDiv>
                            <Img margin={isPc?'':'0 auto'} width={'212px'} src={isPc?ST01:MST01}/>
                            <Img src={isPc?ST02:MST02}/>    
                        </ColumnDiv>
                        {isPc&&<video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={halliroom} type={"video/mp4"}/>
                        </video>}
                    </FlexDiv>
                    <FlexDiv type={'item'}>
                        <video width={isPc?'407px':'277px'} height={isPc?'407px':'277px'} controls loop muted={"muted"} autoplay={"autoplay"} playsInline>
                            <source src={REBIRTH3D} type={"video/mp4"}/>
                        </video>
                        <ColumnDiv type={'content'}>
                            <TextDiv type={'name'}>Animated Room1</TextDiv>
                            <TextDiv type={'content'}>Compounds of the painting trying to peel away, to be free, without restriction. Casual digitization of Gaeko’s painting ‘Room1’ by Halliday’s lead designer ‘Js Ye’. Yes, this had to be one of the rarest NFT amongst the Founder’s NFT collection.</TextDiv>
                            <Img src={isPc?ST021:MST021}/>
                        </ColumnDiv>
                    </FlexDiv>
                </Section>
                <Section type={3}>
                    <ColumnDiv type={'section3'}>
                        <ColumnDiv type={'section3 div'}>
                            <TextDiv type={'section3 top'}>
                                EXCLUSIVE BENEFITS
                            </TextDiv>
                            <ColumnDiv height={'146px'} type={'section3 content'}>
                                <TextDiv type={'section3 content'}>- Secure your spot on our whitelist for future drops created in collaboration with K-celebrities and renown digital artists. Numerous projects with top-tier celebrities and artists are underway.</TextDiv>
                                <TextDiv type={'section3 content'}>- Access to exclusive future on/offline events sponsored by Halliday’s</TextDiv>
                                <TextDiv type={'section3 content'}>- Eligibility to receive airdrop for free in the form of NFT or ERC20 tokens</TextDiv>
                                <TextDiv type={'section3 content'}>- Join our DAO</TextDiv>
                            </ColumnDiv>
                        </ColumnDiv>
                    </ColumnDiv>
                </Section>
                <Section type={4}>
                    {''&&<ColumnDiv type={'section4'}>
                        <TextDiv type={'FAQ'}>FAQ</TextDiv>
                        <ColumnDiv>
                            <Section4Content no={0}>How do I cancel an offer?</Section4Content>
                            <Section4Content no={1}>How do I accept offers for the Nifty I am selling?</Section4Content>
                            <Section4Content no={2}>How can I add/remove a Credit Card?</Section4Content>
                            <Section4Content no={3}>Can I get a refund on a Nifty?</Section4Content>
                            <Section4Content no={4}>Can I split payments while purchasing a Nifty?</Section4Content>
                            <Section4Content no={5}>How do I change my Password?</Section4Content>
                        </ColumnDiv>
                    </ColumnDiv>}
                </Section>
            </Article>
        </Main>
    );
}
const Main = styled.div`
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
            
    }
`;
const Article = styled.article`
    ${props=>props.type==='article top'&&`
        background-image: url(${props.img});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width : 100vw;
        height : 160vh;
        position : relative;
        display : flex;
        justify-content: flex-start
        flex-direction : column;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            justify-content : space-between;
            height : 100vh;
        }
    `}
    ${props=>props.type==='article bottom'&&`
        background : #1a1a1a;
    `}
`;

const TwitterLink = styled.strong`
    font-family : RBD;
    &:hover {
        cursor : pointer;
    }
`;

const FlexDiv = styled.div`
    display : flex;
    z-index : 1;
    margin : 0 auto;
    text-align : center;
    ${props=>props.type==='item'&&`
        margin : 22.5px 0;
        padding : 65px 120px;
        justify-content : space-between;
        border : 1px solid #a3a3a3;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 0 10px;        
            padding : 25px;
            flex-direction : column;
            height : 574px;
        }
    `}
    ${props=>props.type==='section4 content'&&`
        width : 973px;
        justify-content : space-between;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100%;
        }
    `}
`;
const ColumnDiv = styled.div`
    display : flex;
    flex-direction : column;
    ${props=>props.type==='section4 content'&&`
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 100%;
        }
    `}
    ${props=>props.type==='top'&&`
        margin : 100px auto 0;
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            height : 80vh;
            margin : auto;
            justify-content : space-between;
        }
    `}
    ${props=>props.type==='Event Timeline'&&`
        margin : 10px auto;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
            margin : 10px 0;
        }
    `}
    ${props=>props.type===1&&`
        margin-top : 20px;
    `}
    ${props=>props.type===2&&`
        margin : 0 120px 30px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 0 10px;        
        }
    `}
    ${props=>props.type===3&&`
        margin : 0 0px 30px;
        border : 1px solid #a3a3a3;
        padding : 60px 130px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            padding : 25px 25px;
            margin : 0 0 10px;        
        }
    `}
    ${props=>props.type==='content'&&`
        width : 550px;
        text-align : left;
        font-family : RL;
        justify-content: space-between;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
            height : 277px;
        }
    `}
    ${props=>props.type==='section1 top'&&`
        justify-content: space-between;
    `}
    ${props=>props.type==='section1 bottom'&&`
        height : 350px;
        justify-content: space-between;
        @media screen and (max-width : 768px) {
            height : 200px;
        }
    `}
    ${props=>props.type==='section3'&&`
        align-items : center;
        margin : 0 110px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 0 25px;
        }
    `}
    ${props=>props.type==='section3 div'&&`
        align-items : center;
        margin : 50px auto;
        
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px 0;
        }
    `}
    ${props=>props.type==='section3 content'&&`
        width : 990px;
        height : ${props.height};
        justify-content : space-between;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
    ${props=>props.type==='section4'&&`
        margin: 50px auto;
    
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin: 50px 0;
            width: 100%;
        }
    `}
    ${props=>props.type==='section4 text'&&`
        margin : 20px auto;
        width : 920px;
        font-family : RL;
        font-size : 14px;
        line-height : 25px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
    ${props=>props.type==='section4 text2'&&`
        margin : 20px auto;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
        }
    `}
`;
const TextDiv = styled.div`
    color: #fff;
    ${props=>props.type==='Event Timeline'&&`        
        font-size: 30px;
        line-height: 35px;
        text-align: center;
    `}
    ${props=>props.type===1&&`
        font-family : RLI;
        font-size : 50px;
        text-align: center;
    
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 22px;
        }
    `}
    ${props=>props.type===2&&`
        font-family : RBDI;
        font-size : 100px;
        line-height : 120px;
        text-align: center;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 50px;
            line-height : 60px;
        }
    `}
    ${props=>props.type===3&&`
        font-family : RL;
        font-size : 18px;
        line-height : 30px;
        text-align: center;
        color : #FFFFFF;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height : 30px;
        }
    `}
    ${props=>props.type==='Halli Cube'&&`
        font-family : RBLI;
        font-size : 50px;
        color : #A3A3A3;
        
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size: 22px;
            line-height: 26px;
        }
    `}
    ${props=>props.type===2&&``}
    ${props=>props.type==='name'&&`
        text-align : ${props.right&&'right'};
        font-size : 50px;
        font-family : RBLI;
        color : #a3a3a3;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 22px;
            line-height : 26px;
        }
    `}
    ${props=>props.type==='content'&&`
        font-size : 18px;
        color : #a3a3a3;
        text-align : justify;
        font-family : RL;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height: 14px;
        }
    `}
    ${props=>props.type==='section1 content'&&`
        font-size : 18px;
        font-family : RL;
        color : #a3a3a3;
        text-align : center;
        margin : 10px 0 10px 0;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height : 14px;
            text-align : justify;
        }
    `}
    ${props=>props.type==='section1 top'&&`
        margin : 70px auto;
        font-size : 50px;
        font-family : RBDI;
        text-align : center;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 22px;
            line-height : 26px;
            margin : 70px 0;
        }
    `}
    ${props=>props.type==='section1 top title'&&`
        margin : 44px auto;
        font-size : 30px;
        font-family : RBDI;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 40px auto 20px 0;
            font-size : 22px;
            line-height : 26px;
            color: #A3A3A3;
        }
    `}
    ${props=>props.type==='section1 top subtitle'&&`
        margin : 20px auto;
        font-size : 18px;
        font-family : RBL;
        color : #A3A3A3;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size: 12px;
            line-height: 14px;
            margin : 20px auto 20px 0;
            text-align : left;
        }
    `}
    ${props=>props.type==='section1 top content'&&`
        margin : 0 auto;
        font-size : 18px;
        font-family : RL;
        color : #A3A3A3;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size: 12px;
            line-height: 14px;
            margin : 0 auto 0 0;
            text-align : left;
        }
    `}
    ${props=>props.type==='section3 top'&&`
        margin : 40px auto;
        font-size : 50px;
        font-family : RBDI;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            margin : 20px auto;
            font-size : 22px;
            line-height : 26px;
        }
    `}
    ${props=>props.type==='section3 content'&&`
        font-size : 18px;
        text-align: justify;
        font-family : RL;
        color: #a3a3a3;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height : 14px;
        }
    `}
    ${props=>props.type==='section4 content'&&`
        font-size : 28px;
        color: #808080;
        margin : 25px 10px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 12px;
            line-height : 14px;
            margin : 10px 2px;
        }
    `}
    ${props=>props.type==='FAQ'&&`
        font-family : RBL;
        font-size : 50px;

        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            font-size : 22px;
        }
    `}
`;
const Section = styled.section`
    display : flex;
    margin : 0 auto;
    width : 1230px;
    
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 327px;
    }
    ${props=>props.type===1&&`
        flex-direction : column;
        justify-content : center;
    `}
    ${props=>props.type===2&&`
        flex-direction : column;
    `}
    ${props=>props.type===3&&`
    
    `}
    ${props=>props.type===4&&`
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : auto;
            margin : 0 25px;
        }
    `}
`;
const Img = styled.img`
    width : ${props=>props.width};
    margin : ${props=>props.margin};
    height : ${props=>props.height};
    ${props=>props.type==='one'&&`
        margin : 10px 0 80px;
        @media screen and (max-width : 768px) {
            width : 277px;
            margin : 0 0 10px;
        }
    `}
    ${props=>props.type==='card'&&`
        /* 모바일 환경 */
        @media screen and (max-width : 768px) {
            width : 277px;
            height : 277px;
        }
    `}
`;
const Button = styled.button`
    width: 156px;
    height: 49px;
    border-radius: 11px;
    background-color: #06c555;
    
    &:hover {
        cursor : pointer;
    }

    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 130px;
        height : 59px;
    }

    ${props=>props.type==='Sign Up'&&`
        border : none;
        color : #fff;
        font-size : 16px;
        font-family : RBD;
        margin : 0 auto 10px;
    `}
`;
const Section4Content = ({children, ...props}) =>{
    const [Toggle, setToggle] = useState(false);

    return <ColumnDiv type={'section4 content'}>
        <FlexDiv type={'section4 content'} onClick={()=>setToggle(!Toggle)}>
            <TextDiv type={'section4 content'}>{children}</TextDiv>
            <TextDiv type={'section4 content'}>&or;</TextDiv>
        </FlexDiv>
        {Toggle&&
            <ColumnDiv type={'section4 text'}>
                <TextDiv>
                    {Data[props.no][0]}
                </TextDiv>
                <ColumnDiv type={'section4 text2'}>
                    <TextDiv>
                        {Data[props.no][1]}
                    </TextDiv>
                    <TextDiv>
                        {Data[props.no][2]}
                    </TextDiv>
                    <TextDiv>
                        {Data[props.no][3]}
                    </TextDiv>
                </ColumnDiv>
                <TextDiv>
                    {Data[props.no][4]}
                </TextDiv>
            </ColumnDiv>
        }
    </ColumnDiv>
}
const Data = [
    [
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ],[
        "Cancelling an offer can be a time crunching matter as sellers are always looking to accept qualifying bids. We'd like to tell you the quickest way to get to yours bids, and how to move forword with officially canceling it.",
        '1. Log-in onto your Nifty Gateway Profile',
        '2. On the top right of our website, use the dropdown Menu(whith can be accessed by clicking on your name and current profile picture) to select Nifty Dashboard',
        '3. Browser yours Offers Made section',
        "Select the 'Calcel Offer' button on the specific Nifty you'd like"
    ]
];
export default EventComponent;