import React, { useState, useEffect, useCallback, createContext } from "react";
import styled, { keyframes } from "styled-components";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Swal from "sweetalert2";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import LogoImg2 from "./img/logo@2x.png";
import LogoImg3 from "./img/logo@3x.png";
import MetamaskIcon from "./img/Metamask-icon.png";
import WalletIcon from "./img/wallet-icon.png";
import CloseIcon from "./img/close.png";
import HamburgerIcon from "./img/hamburger.png";
import FlexDiv from "./styled/FlexDiv.jsx";
import Button from "../common/Button.jsx";
import { useCookies } from "react-cookie";
import Icon from "./Icon";
import TextDiv from "./styled/TextDiv";
import ConnectButton from "./ConnectButton.jsx";
import CopyButton from "./CopyButton";
import { useSelector } from "react-redux";
import useActions from "../../redux/lib/useAction";
import { changeLoginStatus } from "../../redux/module/status";
import { web3, NETWORKID } from "../../js/Web3.js";

function TopNav({ history, match, location }) {
  const { pathname } = location;
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const [top, setTop] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [loginState, setLoginState] = useState(false);
  const [connectState, setConnectState] = useState(0); // 0 : 연결x, 1 : 연결o 지갑 다름, 2 : 연결o 지갑 같음
  const [connectWallet, setConnectWallet] = useState(""); // 연결된 지갑 주소
  const [nickname, setNickname] = useState("");
  const [img, setImg] = useState("");
  const { state } = cookies;

  const { login_status } = useSelector(({ status }) => ({
    login_status: status.login_status,
  }));

  const [ChangeLoginStatus] = useActions([changeLoginStatus], []);
  const injected = new InjectedConnector({
    supportedChainIds: [2, 137, 80001],
  });

  const { activate } = useWeb3React();

  /*   const v010 = () =>{
    Swal.fire({
      icon: "info",
      title: "Coming soon",
      text : "Halliday's will open on January 27th with Release event"
    })
  } */
  const toggleRun = (data) => {
    setToggle(!toggle);
    if (data !== undefined) {
      if (data === pathname) window.scrollTo(0, 0);
      else if (data !== "on" && data !== "off")
        history.push({
          pathname: data,
          state: {
            path: pathname,
          },
        });
    }
  };

  const toggle3Run = async () => {
    if (!toggle3) {
      // 메타마스크 설치 확인
      if (!window.ethereum) {
        // 설치x 설치 페이지로 이동
        Swal.fire({
          icon: "info",
          title: "You need to install Metamask",
          text: `
            Would you like to go to the installation page? (We do not support Metamask mobile app.)
          `,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            window.open("https://metamask.io/download");
          } else if (result.isDenied) {
            //window.location.reload();
          }
        });
      } else {
        const networkId = await web3.eth.net.getId();
        const _wallet = await web3.eth.getAccounts();
        const authorization = cookies.authorization;

        /**
          @지갑연결조회 
        */
        axios
          .post(
            process.env.REACT_APP_API_URL + "/user/cert/authorization",
            { wallet: _wallet[0] },
            {
              headers: {
                authorization: authorization,
              },
            }
          )
          .then((res) => {
            const { cert, wallet_address } = res.data;
            setConnectState(cert);
            setConnectWallet(wallet_address);

            activate(injected).then(() => {
              console.log("networkId >>", networkId);
              const chainId = web3.utils.numberToHex(NETWORKID);

              // ropsten test network 연결
              if (networkId !== NETWORKID) {
                //ropsten net 으로 변경
                window.ethereum
                  .request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: chainId }],
                  })
                  .then(() => {
                    setToggle3(!toggle3);
                  });
              } else {
                setToggle3(!toggle3);
              }
            });
          })
          .catch((err) => {
            console.log(
              process.env.REACT_APP_API_URL +
                "/user/cert/authorization error >>> ",
              err
            );
          });
      }
    } else {
      setToggle3(false);
    }
  };

  // 스크롤 인식
  const updateScrollY = useCallback(() => {
    setScrollYPosition(window.scrollY || document.documentElement.scrollTop);
    if (scrollYPosition > 100) {
      setTop(true);
    }
  }, [scrollYPosition]);

  // 로그인 인증정보 확인
  const login = useCallback(async () => {
    try {
      const authorization = cookies.authorization;
      if (authorization !== undefined) {
        // 해당 토큰인증정보와 wallet 정보 조회
        await axios
          .post(
            process.env.REACT_APP_API_URL + "/user/login/authorization",
            {},
            {
              headers: {
                authorization: authorization,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              const { nickname, profile_picture, wallet_address, cert } =
                res.data;
              setImg(profile_picture);
              window.localStorage.setItem("profile_picture", profile_picture);
              setNickname(nickname);

              // 할일 : 지갑 연결 여부 확인 ( cert table )
              setConnectWallet(wallet_address);
              setConnectState(cert);

              //console.log("login cert >>", cert);
              //
              if (cert === 0) {
                //console.log('구분 cert 0')
                ChangeLoginStatus(1); // 연결x
              } else if (cert === 1) {
                //console.log('구분 cert 1')
                ChangeLoginStatus(2); // 연결o 지갑 다름
              } else if (cert === 2) {
                //console.log('구분 cert 2')
                ChangeLoginStatus(3); // 연결o 지갑 같음
              }
            } else {
              console.log("???");
            }
          })
          .catch((err) => {
            console.log(err);
          });
        setLoginState(state);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logout = useCallback(() => {
    removeCookie("authorization");
    setCookie("state", false);
    setLoginState(false);
    setNickname("");
    setImg("");
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        auth2
          .signOut()
          .then(auth2.disconnect())
          .catch((e) => console.log(e));
      }
    }
  }, [removeCookie]);

  const profile = useCallback(() => {
    history.push("my-profile");
  }, [history]);

  useEffect(() => {
    window.addEventListener("scroll", updateScrollY);
  }, [updateScrollY]);

  useEffect(() => {
    login(); // 로그인 정보 확인
  }, [login]);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });
  return (
    <>
      <MainDiv show={scrollYPosition} top={top}>
        <FlexDiv
          width={isPc ? "" : "90vw"}
          justify={"space-between"}
          height={isPc ? "60px" : ""}
          margin={isPc ? "6px 35px" : "2px auto"}
        >
          <FlexDiv
            width={"300px"}
            justify={"left"}
            alignItems={isPc ? "center" : ""}
          >
            <LogoImg1 isPc={isPc} onClick={() => history.push("/")} />
          </FlexDiv>
          <FlexDiv>
            {isPc ? (
              <NavVar>
                <ToggleContent
                  pathname={pathname}
                  type={"/about"}
                  onClick={() => {
                    if (pathname !== "/about") history.push("/about");
                    window.scrollTo(0, 0);
                  }}
                >
                  About
                </ToggleContent>
                {/* <ToggleContent
                  pathname={pathname}
                  type={"/rebirth"}
                  onClick={() => {
                    if (pathname !== "/rebirth") history.push("/rebirth");
                    window.scrollTo(0, 0);
                  }}
                >
                  REBIRTH
                </ToggleContent> */}

                {/* <ToggleContent
                pathname={pathname}
                type={"drops"}
                onClick={() => history.push("/drops")}
              >
                Drops
              </ToggleContent> */}
              </NavVar>
            ) : (
              <HamburgerImg onClick={() => toggleRun("on")} />
            )}
            {isPc &&
              (!loginState ? (
                <FlexDiv justify={"right"} width={"250px"}>
                  <Button
                    type={"Signup"}
                    onClick={() => history.push("/signUp")}
                  >
                    Sign up
                  </Button>
                  <Button type={"Login"} onClick={() => history.push("/login")}>
                    Login
                  </Button>
                </FlexDiv>
              ) : (
                <FlexDiv alignItems={"center"} width={"300px"}>
                  <FlexDiv alignItems={"center"} margin={"0 20px 0 0"}>
                    <Icon
                      borderRadius={"50px"}
                      width={"30px"}
                      cursor={"pointer"}
                      src={WalletIcon}
                      onClick={() => toggle3Run()}
                    />
                    <WalletDiv display={toggle3 ? "block" : "none"}>
                      <FlexDiv
                        alignItems={"center"}
                        justify={"space-between"}
                        margin={"20px 20px 50px"}
                      >
                        <FlexDiv flexDirection={"column"} margin={"5px"}>
                          <TextDiv width={"100px"} color={"black"}>
                            {nickname}
                          </TextDiv>
                          <TextDiv width={"100px"}>{nickname}</TextDiv>
                        </FlexDiv>
                        <Icon src={MetamaskIcon} />
                      </FlexDiv>
                      <FlexDiv flexDirection={"column"} margin={"10px 0"}>
                        {/* <FlexDiv width={'340px'} margin={'0 auto'}>
                        <FlexDiv flexDirection={"column"} margin={'0 5px'}>
                          <TextDiv textAlign={"center"}>DAI</TextDiv>
                          <Line />
                        </FlexDiv>
                        <FlexDiv flexDirection={"column"} margin={'0 5px'}>
                          <TextDiv textAlign={"center"}>wETH</TextDiv>
                          <Line />
                        </FlexDiv>
                      </FlexDiv> */}
                        <FlexDiv
                          width={"300px"}
                          flexDirection={"column"}
                          margin={"20px auto"}
                        >
                          {connectState === 0 ? (
                            <ConnectButton />
                          ) : (
                            <FlexDiv
                              margin={"0 auto 50px"}
                              background={"gray"}
                              borderRadius={"20px"}
                              width={"240px"}
                              height={"30px"}
                              alignItems={"center"}
                            >
                              <TextDiv
                                textAlign={"center"}
                                width={"160px"}
                                color={"#e0e0e0"}
                              >
                                {connectWallet}
                              </TextDiv>
                              <CopyButton wallet={connectWallet} />
                            </FlexDiv>
                          )}
                          {connectState === 2 ? (
                            <TextDiv textAlign={"center"} color={"black"}>
                              Wallet is connected.
                            </TextDiv>
                          ) : connectState === 0 ? (
                            <TextDiv textAlign={"center"} color={"red"}>
                              You should connect Metamask! Click above.
                            </TextDiv>
                          ) : (
                            <TextDiv textAlign={"center"} color={"red"}>
                              Wrong Wallet Address
                            </TextDiv>
                          )}
                        </FlexDiv>
                      </FlexDiv>
                    </WalletDiv>
                  </FlexDiv>
                  <FlexDiv
                    onMouseOver={() => setToggle2(!toggle2)}
                    onMouseOut={() => setToggle2(!toggle2)}
                  >
                    <FlexDiv
                      display={!toggle2 ? "flex" : "none"}
                      width={"150px"}
                      alignItems={"center"}
                    >
                      <ProfileImg
                        img={window.localStorage.getItem("profile_picture")}
                      />
                      <TextDiv width={"100px"} margin={"5px"}>
                        {nickname}
                      </TextDiv>
                    </FlexDiv>
                    <Ul display={toggle2 ? "block" : "none"}>
                      <Li onClick={profile}>Profile</Li>
                      <Li onClick={logout}>Log out</Li>
                    </Ul>
                  </FlexDiv>
                </FlexDiv>
              ))}
          </FlexDiv>
        </FlexDiv>
      </MainDiv>

      {toggle && (
        <ToggleBox toggle={toggle}>
          {loginState && (
            <FlexDiv
              alignItems={"center"}
              onClick={() => toggleRun("/my-profile")}
            >
              <ProfileImg
                img={
                  img
                    ? img
                    : "https://kr.object.ncloudstorage.com/nft-cdn/uerProfile/DefaultProfile.png"
                }
              />
              <TextDiv margin={"5px"}>{nickname}</TextDiv>
            </FlexDiv>
          )}
          <ToggleContent
            pathname={pathname}
            type={"/about"}
            onClick={() => toggleRun("/about")}
          >
            About
          </ToggleContent>
          {/* <ToggleContent
            pathname={pathname}
            type={"/rebirth"}
            onClick={() => toggleRun("/rebirth")}
          >
            REBIRTH
          </ToggleContent> */}

          {/* <ToggleContent
            pathname={pathname}
            type={"drops"}
            marginB={"50vw"}
            onClick={() => toggleRun("/drops")}
          >
            Drops
          </ToggleContent> */}
          {!loginState && (
            <Button type={"Signup"} onClick={() => toggleRun("/signUp")}>
              Sign up
            </Button>
          )}
          {!loginState && (
            <Button type={"Login"} onClick={() => toggleRun("/login")}>
              Login
            </Button>
          )}
          {loginState && (
            <Button type={"Login"} onClick={logout}>
              Log out
            </Button>
          )}
          <CloseImg onClick={() => toggleRun("off")} />
        </ToggleBox>
      )}
    </>
  );
}
const MainDiv = styled.nav`
  position: fixed;
  z-index: 2;
  width: 100vw;
  text-align: center;
  animation: ${(props) => (props.show < 100 ? props.top && boxHide : boxShow)}
    0.3s forwards linear;
  color: #ffffff;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    padding: 15px 0;
  }
`;
const LogoImg1 = styled.img.attrs((props) => ({
  src: props.isPc ? LogoImg2 || "" : LogoImg3 || "",
}))`
  height: 30px;
  &:hover {
    cursor: pointer;
  }
`;
const NavVar = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ToggleContent = styled.div`
  color: ${(props) => (props.pathname === props.type ? "#06C755" : "#FFFFFF")};
  font-family: PoppinsMedium;
  &:hover {
    color: #06c755;
    cursor: pointer;
  }

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    margin: 20px auto;
    margin-bottom: ${(props) => props.marginB};
  }
`;
const HamburgerImg = styled.img.attrs({
  src: HamburgerIcon || "",
})`
  z-index: 3;
  width: 30px;
  height: 30px;
`;
const ToggleBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  font-size: 16px;
  color: #a0a0a0;
  position: fixed;
  font-family: PoppinsMedium;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  animation: ${(props) => props.toggle && toggleShow} 0.6s forwards;
  backdrop-filter: blur(40px);
  touch-action: none;
`;
const CloseImg = styled.img.attrs({
  src: CloseIcon || "",
})`
  width: 23px;
  height: 23px;
  margin: 0 auto;
`;
const boxShow = keyframes`
  0% {background-color : rgba(0, 0, 0, 0);}
  100% {background-color : rgba(0, 0, 0, 1);}
`;
const boxHide = keyframes`
  0% {background-color : rgba(0, 0, 0, 1);}
  100% {background-color : rgba(0, 0, 0, 0);}
`;
const toggleShow = keyframes`
  0% {transform: translateY(-200%);}
  100% {transform: translateY(0%);}
`;
const Ul = styled.ul`
  text-align: left;
  list-style: none;
  margin: 0;
  color: #a0a0a0;
  background-color: #ffffff;
  padding: 0;
  display: ${(props) => props.display};
`;
const Li = styled.li`
  width: 130px;
  padding: 5px 10px;
`;

const WalletDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: left;
  list-style: none;
  color: #a0a0a0;
  background-color: #ffffff;
  top: 60px;
  margin: 0;
  padding: 0;
  width: 400px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 30%) 0px 5px 20px 0px;
  display: ${(props) => props.display};
`;

const ProfileImg = styled.div`
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 70px;
  border: 1px solid #ffffff;
  width: 30px;
  height: 30px;
`;
export default TopNav;
