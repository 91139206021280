import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import shadow from "../../img/shadow.png";
import styled, { css, keyframes } from "styled-components";

export default function Showcase(props) {
  const { data } = props;
  const isPc = useMediaQuery({ query: "(min-width:768px)" });

  return (
    <Container>
      {data.map((data) => {
        return (
          <StyledSection key={data.id}>
            <ImgDiv isUpcoming={data.isUpcoming}>
              <img
                className={data.title}
                src={isPc ? data.desktopImg : data.mobileImg}
                alt={data.title}
              />
              <Shadow src={shadow} alt="shadow" />
            </ImgDiv>
            <ContentsDiv>
              <TextDiv>
                <p>{data.artist}</p>
                <h3>{data.title}</h3>
              </TextDiv>
              <Button isUpcoming={data.isUpcoming}>
                {data.id === 1 ? (
                  <a href={data.link} target="_blank" rel="noreferrer">
                    See more
                  </a>
                ) : (
                  <Link to={data.link}>See more</Link>
                )}
              </Button>
            </ContentsDiv>
          </StyledSection>
        );
      })}
    </Container>
  );
}

const Container = styled.div``;

const StyledSection = styled.section`
  width: 100vw;
  height: 75vh;
  background-color: #1c1c1c;
  position: relative;
`;

const ImgDiv = styled.div`
  position: relative;
  filter: ${(props) => (props.isUpcoming ? "grayscale(0.5)" : "none")};

  img {
    width: 100vw;
    height: 75vh;
    object-fit: cover;
  }
`;

const Shadow = styled.img`
  position: absolute;
  left: 0;
  top: 0;

  @media screen and (max-width: 768px) {
    opacity: 0.7;
  }
`;

const ContentsDiv = styled.div`
  position: absolute;
  bottom: 15vh;
  left: 0;
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;

const Button = styled.button`
  width: 105px;
  height: 45px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  line-height: 50px;
  font-family: Archivo-Bold;
  background-color: #ffffff;
  color: black;
  cursor: pointer;
  display: ${(props) => (props.isUpcoming ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  a {
    display: block;
    color: inherit;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin: 7vh auto 5vh;
  }
`;

const TextDiv = styled.div`
  p,
  h3 {
    color: #ffffff;
    letter-spacing: 0.05em;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    font-family: Archivo-Regular;
  }

  h3 {
    font-size: 38px;
    line-height: 50px;
    font-family: Archivo-Bold;
  }
`;
