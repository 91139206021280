import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DefaultProfileImg from "./img/DefaultProfile.svg";
import DefaultBannerImg from "./img/default-banner.png";
import Shape from "./img/icon-Shape.png";
import leftArrow from "./img/icon-left-arrow.png";
import { useMediaQuery } from "react-responsive";
import { Cookies, useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function ProfileEdit() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const formData = new FormData();
  const cookie = new Cookies();
  const [textchk, setTextchk] = useState({byteLength : 0, maxByteLength : 500});
  const mailchk = useRef(true);
  const textOverFlow = useRef(false);
  const [profileImg, setProfileImg] = useState('');

  const hiddenFileInput = useRef(null);
  const [profile, setProfile] = useState({
    join_datetime : "join_datetime",
    mail_address : "mail_address",
    wallet_address : "wallet_address",
    nickname : "nickname",
    profile_picture : "profile_picture"
  });
  const isMo = useMediaQuery ({query: "(max-width:768px)"});
  const isPc1 = useMediaQuery ({query: "(max-width:1440px)"});
  
  function InputHandler(){
    hiddenFileInput.current.click();
  }

  function onChange(e){
    if(e.target.name==="userEmail"){
      const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if(regex.test(e.target.value)){
        mailchk.current=true;
      }else {
        mailchk.current=false;
      }
    }else if(e.target.name==="userIntroduction"){
      Textchk(e.target.value);
    }
    setProfile({...profile, [e.target.name]: e.target.value });
  }

  async function onFileChange(e){
    let _img = e.target.files[0];
    const formData = new FormData();
    formData.append('file', _img);
    await axios.post(process.env.REACT_APP_API_URL+"/upload/uploadProfileImage", formData)
    .then(res=>{
      console.log(res.data.profileImageUrl);
      setProfileImg(res.data.profileImageUrl);
      setProfile({...profile, profile_picture: res.data.profileImageUrl});
    })
    .catch(err=>{
      console.log("err >>", err);
    });
  }

  async function onUpdate(){
    const authorization = cookies.authorization;
    console.log("authorization >>", authorization);
    console.log("profile_picture >>", profile.profile_picture);
    window.localStorage.setItem("profile_picture", profileImg);
    axios.post(process.env.REACT_APP_API_URL + "/user/updateProfile", profile,
        {
          headers: {
            authorization: authorization,
          },
        }).then((res)=>{
          if(res.status===200){
            console.log("getProfile res.data >>", res.data);
            Swal.fire({
              icon : 'success',
              title : 'Profile changed'
            }).then(()=>{
              const {cert_data} = res.data;
              console.log("res.data >>> ", res.data);
              cookie.set('authorization', cert_data);
              cookie.set('state', true);
              window.location.reload();
            })
          } else {
            Swal.fire({
              icon : 'warning',
              title : 'Nickname is already taken'
            })
          }
        }).catch(()=>{
          console.log('getProfile err');
        })
  }

  function Textchk(text){
    const text_val = text; //입력한 문자
      const text_len = text_val.length; //입력한 문자수
    
      let totalByte=0;
      for(let i=0; i<text_len; i++){
        const each_char = text_val.charAt(i);
        const uni_char = escape(each_char) //유니코드 형식으로 변환
        if(uni_char.length>4){
          // 한글 : 2Byte
          totalByte += 2;
        }else{
          // 영문,숫자,특수문자 : 1Byte
          totalByte += 1;
        }
      }
      textOverFlow.current = totalByte>textchk.maxByteLength;
      setTextchk({...textchk, byteLength:totalByte});
  }

  const getProfile = async () => {
    const authorization = cookies.authorization;
    axios.post(process.env.REACT_APP_API_URL + "/user/getProfile", {},
        {
          headers: {
            authorization: authorization,
          },
        }).then((res)=>{
          const {result, cert} = res.data.result;
          console.log("edit getProfile result >>", result);
          console.log("edit getProfile cert >>", cert);
          setProfile(result);
          setProfileImg(result.profile_picture);
        }).catch(()=>{
          console.log('edit getProfile err');
        })
  }

  useEffect(()=>{
    getProfile();
  },[])
  
  return (
    <Main>
      <Img height={"180px"} img={DefaultBannerImg} width={"100%"} styleType={"banner"} />
      <Article>
        <Section margin={"30px auto 0 auto"}>
        {isMo?
          <FlexDiv flexwrap={"wrap"}>
            <TextDiv textalign={"center"} width={"100vw"} fontsize={"22px"}>Edit Profile</TextDiv>
            <TextDiv textalign={"center"} width={"280px"} fontsize={"16px"} fontfamily={"PoppinsLight"} margin={"32px auto"}>You can set preferred display name, create your profile URL and manage other personal settings.</TextDiv>
          </FlexDiv>
        :
          <FlexDiv justify={"space-between"} width={"1440px"} borderbottom={"2px solid #E6E8EC"} padding={isMo?"24px 25px":"24px 160px"}>
            <Link style={{ textDecoration: 'none' }} to={{
              pathname:"/my-profile",
            }}>
              <Button width={"158px"} height={"40px"} border={"2px solid #E6E8EC"}>
                  <Img width={"16px"} height={"16px"} img={leftArrow} margin={"auto 10px"}/>
                  <TextDiv margin={"auto 0"} fontfamily={"PoppinsBold"} fontsize={"14px"} color={"#FFFFFF"}>
                    Back to profile
                  </TextDiv>
              </Button>
            </Link>
            <FlexDiv justify={"space-between"} width={"180px"}>
                <TextDiv color={"#FFFFFF"} margin={"auto 0"}>Profile</TextDiv>
                <Img img={Shape} width={"24px"} height={"24px"} margin={"auto 0"}/>
                <TextDiv color={"#FFFFFF"} margintop={"0"} margin={"auto 0"} fontfamily={"PoppinsBold"} fontsize={"14px"}>Edit Profile</TextDiv>
            </FlexDiv>
          </FlexDiv>
        }
        </Section>
        <Section>
          <FlexDiv width={isMo?"300px":isPc1?"500px":""} flexwrap={isPc1?"wrap":""}>
            <ContentDiv isMo={isMo}>
              <FlexDiv flexwrap={"wrap"} width={isMo?"280px":"420px"}>
                <FlexDiv flexwrap={"wrap"} justify={isMo?"center":"left"} height={"250px"} width={isMo?"280px":"420px"}>
                  <ProfileImg img={profileImg?profileImg:DefaultProfileImg} width={"125px"} height={"125px"} margin={isMo?"0 20px":"0 40px 0 0"}/>
                  <div>
                    <TextDiv fontfamily={"PoppinsRegular"} fontsize={"16px"} textalign={isMo&&"center"}>Profile photo</TextDiv>
                    <TextDiv margin={"10px 0"} color={"#9E9E9E"} fontfamily={"PoppinsLight"} fontsize={"10px"} width={isMo?"280px":"120px"} textalign={isMo&&"center"}>We recommend an image of at least 400x400.</TextDiv>
                    <Button width={"64px"} height={"32px"} border={"2px solid #E6E8EC"} margin={isMo&&'0 auto'} onClick={()=>InputHandler()}>
                      <TextDiv fontfamily={"PoppinsLight"} margin={"auto"} fontsize={"15px"} color={"#FFFFFF"}>
                        Edit
                      </TextDiv>
                      <input type="file" name="profile_picture" onChange={(e)=>onFileChange(e)} ref={hiddenFileInput} style={{display:'none'}}/>
                    </Button>
                  </div>
                </FlexDiv>
              </FlexDiv>
              <TextDiv>WALLET</TextDiv>
              <InputDiv>
                <Input color={"#A9A9A9"} value={profile.wallet_address} placeholder="Wallet Address" readOnly/>
              </InputDiv>
              <TextDiv>EMAIL</TextDiv>
              <InputDiv>
                <Input color={"#A9A9A9"} value={profile.mail_address?profile.mail_address:""} name="userEmail" placeholder="User_email" readOnly/>
              </InputDiv>
              <TextDiv>NICKNAME</TextDiv>
              <InputDiv>
                <Input value={profile.nickname?profile.nickname:""} name="nickname" onChange={(e)=>onChange(e)} placeholder="User_Name" />
              </InputDiv>
              {/* <TextDiv>SOCIAL</TextDiv>
              <InputDiv>
                <SocialTextDiv>https://twitter.com/</SocialTextDiv>
                <Input margin={"auto 0"} width={"250px"} value={profile.userAcTwitter?profile.userAcTwitter:""} name="userAcTwitter" onChange={(e)=>onChange(e)} />
              </InputDiv>
              <InputDiv>
                <SocialTextDiv>https://instagram.com/</SocialTextDiv>
                <Input margin={"auto 0"} width={"250px"} value={profile.userAcInstagram?profile.userAcInstagram:""} name="userAcInstagram" onChange={(e)=>onChange(e)} />
              </InputDiv>
              <InputDiv>
                <SocialTextDiv>https://facebook.com/</SocialTextDiv>
                <Input margin={"auto 0"} width={"250px"} value={profile.userAcFacebook?profile.userAcFacebook:""} name="userAcFacebook" onChange={(e)=>onChange(e)} />
              </InputDiv>
              <InputDiv>
                <SocialTextDiv>Discord ID@</SocialTextDiv>
                <Input margin={"auto 0"} width={"310px"} value={profile.userAcDiscord?profile.userAcDiscord:""} name="userAcDiscord" onChange={(e)=>onChange(e)} />
              </InputDiv> */}
              <Button width={"190px"} height={"44px"} margin={isMo?"100px auto 0 auto":"100px 0 0 0"} bgcolor={"#06C755"} color={"#FFFFFF"} onClick={()=>onUpdate()}>
                <TextDiv margin={"auto"}>
                  Save Changes
                </TextDiv>
              </Button>
              {/*<TextDiv color={"#FFFFFF"} fontfamily={"PoppinsLight"} textalign={isMo&&"center"}>To update your settings you should sign message through your wallet. Click 'Save Changes' then sign the message</TextDiv>*/}
            </ContentDiv>
          </FlexDiv>
        </Section>
      </Article>
    </Main>
  );
}
const Main = styled.div`
  color : #FFFFFF;
  background-color : #1C1C1C;
`;
const Img = styled.img.attrs((props) => ({
  src: props.img,
}))`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${props=>props.margin};
`;
const ProfileImg = styled.div`
  background-image: url('${props=>props.img}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${props=>props.margin};
`;
const Article = styled.article`
`;
const Section = styled.section`
  display: flex;
  justify-content: ${props=>props.justify||"center"};
  width : ${props=>props.width};
  margin : ${props=>props.margin};
  padding : ${props=>props.padding};
`;
const Button = styled.button`
  display : flex;
  width : ${props=>props.width};
  height : ${props=>props.height};
  margin : ${props=>props.margin};
  background-color : ${props=>props.bgcolor||"rgba(0, 0, 255, 0)"};
  border : ${props=>props.border||"none"};
  color : ${props=>props.color};
  border-radius: 22px;
  
  &:hover {
    cursor:pointer;
    box-shadow:-20000px 0 0 0 rgba(0,0,0,0.4) inset;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "center"};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding : ${props=>props.padding};
  flex-wrap: ${props=>props.flexwrap};
  border-bottom : ${props=>props.borderbottom};
`;
const TextDiv = styled.div`
font-family : ${props=>props.fontfamily||"PoppinsMedium"};
font-size : ${props=>props.fontsize||"13px"};
color : ${props=>props.color};
margin-top : ${props=>props.margintop||"20px"};
margin : ${props=>props.margin};
width : ${props=>props.width};
text-align : ${props=>props.textalign};

  ${props=>props.textchk&&`
    display: flex;
  `}
`;
const ContentDiv = styled.div`
  margin : ${props=>props.isMo?"20px 60px":"80px 60px"};
  width : ${props=>props.isMo?"280px":"420px"};
`;
const InputDiv = styled.div`
  margin-top : ${props=>props.margintop||"10px"};
  display : flex;
  border-radius : 20px;
  border : 1px solid #E0E0E0;
  height : ${props=>props.height||"48px"};
  background-color : ${props=> props.backgroundColor || "#ffffff"};
  textarea::-webkit-input-placeholder {
    font-family: Aeonik;
  }
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 280px;
  }
`;
const Input = styled.input`
  margin : ${props=>props.margin || "auto 20px"};
  border : none;
  width : ${props=> props.width || "340px"};
  height : 30px;
  &:focus {
    outline:${props=>!props.outline&&"none"};
  }
  background-color : ${props=> props.backgroundColor};
  color : ${props=> props.color};
  &::-webkit-input-placeholder {
    font-family : Aeonik;
    font-size : 14px;
  }
`;
const Textarea = styled.textarea`
  border : none;
  width : ${props=>props.width};
  margin : 10px 20px;
  resize: none;
  color : ${props=>props.color};
  &:focus {outline:none;}
`;
const SocialTextDiv = styled.div`
  font-family : Aeonik-medium;
  margin : 14.3px 1px auto 20px;
  font-size : 14px;
  color : #A9A9A9;
`;
export default ProfileEdit;
