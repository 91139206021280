import React from "react";
import { useState } from "react";
import styled from "styled-components";
import FlexDiv from "../../common/styled/FlexDiv";
import { Link, useHistory } from "react-router-dom";
import TextDiv from "../../common/styled/TextDiv";
import Google from "../../common/styled/Google";
import Icon from "../../common/Icon";
import or from "../../common/img/or.svg";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

function SignUpSection(props) {
  const history = useHistory();
  const [signUpState, setSignUpState] = useState({
    mail_address: '',
    password : '',
  });
  const [emailState, setEmailState] = useState({status : false, color : ''});
  const [passState, setPassState] = useState({status : false, color : ''});
  const [passvState, setPassvState] = useState({status : false, color : ''});


  function onChange(e){
    const {name, value} = e.target;
    const regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if(regex.test(value)){
      setEmailState({...emailState, status : true, color : ''});
    } else {
      setEmailState({...emailState, status : false, color : 'red'});
      if(value.length===0){
        setEmailState({...emailState, color : ''});
      }
    }
    setSignUpState({...signUpState, [name]: value});
  }
  function onChangePass(e) {
    const {name, value} = e.target;
    //최소 8 자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자
    var regExpPw = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if(regExpPw.test(value)){
      setPassState({...passState, status : true, color : ''});
    } else {
      setPassState({...passState, status : false, color : 'red'});
      if(value.length===0){
        setPassState({...passState, color : ''});
      }
    }

    console.log("else...", passState)
    setSignUpState({...signUpState, [name]: value});
  }

  function onChangePassVerify(e) {
    const {value} = e.target;
    const {password} = signUpState;
    console.log(password);
    if(password===value){
      setPassvState({...passvState, status : true, color : ''});
    } else {
      setPassvState({...passvState, status : false, color : 'red'});
      if(value.length===0){
        setPassvState({...passvState, color : ''});
      }
    }
  }

  const signupRun = async () => {
    const {mail_address, password} = signUpState;
    console.log("email >> ", emailState.status);
    console.log("pass >> ", passState.status);
    console.log("passv >> ", passvState.status);
    console.log("signUpState email >> ", mail_address);
    console.log("signUpState passv >> ", password);
    
    if(!emailState.status){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Check your email address.',
        showConfirmButton: false,
        timer: 1500
      })
    } else if(!passState.status){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please choose a strong password.',
        showConfirmButton: false,
        timer: 1500
      })
    } else if(!passvState.status){
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Those passwords do not match. Try again.`,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      //console.log("url >> ", process.env.REACT_APP_API_URL);
      
      // 메일 중복 확인
      const {data} = await axios.post(process.env.REACT_APP_API_URL+'/user/chk',{mail_address : mail_address});
      
      console.log("data >> ", data);
      if(data){
        // 회원 가입 & 이메일 인증
        await axios.post(process.env.REACT_APP_API_URL+'/user/signUp/authentication',{
          mail_address : mail_address,
          password : password
        }).then((res)=>{
          console.log("회원 id >> ", res.data);

          history.push({ 
            pathname : '/authentication',
            state : {
              userid : res.data.id,
              email : res.data.mail
            }
          });
        }).catch((err)=>{
          console.log("오류 : ", err);
        })
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'The email is already signed up!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  };

  const isPc = useMediaQuery({query: "(min-width:768px)"});
  return (
    <Section>
        <ContentBox>
          <TextDiv
            fontFamily={"PoppinsRegular"}
            fontSize={"20px"}
            width={isPc?"350px":"80vw"}
            margin={isPc?"25px auto 15px":'20px auto'}
          >
            Sign up for Halliday’s
          </TextDiv>
          <Google />
          <FlexDiv alignItems={"center"} margin={"5px 0"}>
            <Line />
            <Icon src={or} margin={"10px"} />
            <Line />
          </FlexDiv>
          <InputBox>
            <Input type={'text'} name={'mail_address'} placeholder={"Email Address"} color={emailState.color}  onChange={(e)=>onChange(e)}/>
          </InputBox>
          <InputBox>
            <Input type={'password'} name={'password'} placeholder={"Password"} color={passState.color} onChange={(e)=>onChangePass(e)}/>
          </InputBox>
          <InputBox>
            <Input type={'password'} name={'password2'} placeholder={"Confirm Password"} color={passvState.color} onChange={(e)=>onChangePassVerify(e)}/>
          </InputBox>
          <TextDiv fontSize={"10px"} width={isPc?"350px":"95%"} margin={"0 auto"}>
            Minimum 8 characters containing at least one number and one special
            character
          </TextDiv>
          <TextDiv
              fontFamily={"PoppinsBold"}
              fontSize={isPc?"16px":'14px'}
              margin={isPc?"10px auto 0 auto":'25px 0 0 10px'}
          >
            If you already have an account, <StyledA href="/login">Login</StyledA>.
          </TextDiv>
          <TextDiv fontSize={isPc?"16px":'12px'} width={isPc?"350px":"95%"} margin={isPc?"25px auto":'20px auto'}>
            When you click “Sign up” you agree to{" "}
            <StyledA href="/terms">Terms and Conditions</StyledA> and <StyledA href="/terms#PrivacyPolicy">Privacy Policy.</StyledA>
          </TextDiv>
        </ContentBox>


      <FlexDiv justify={"space-between"} width={isPc?"350px":"95%"} margin={"0 auto"}>
          <Button onClick={()=>history.goBack()}>
            Back
          </Button>
          <Button color={"#FFFFFF"} background={"#06C755"} onClick={signupRun}>
              Next
          </Button>
      </FlexDiv>
    </Section>
  );
}

const Section = styled.section`
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
  
  }
`;

const Line = styled.div`
  border: 0.5px solid #ffffff;
  width: 150px;
  height: 0;
`;
const StyledA = styled.a`
  color: #ffffff;
  font-family : PoppinsBold;
`;
const InputBox = styled.div`
      display: flex;
      jutify-content: left;
      align-items: center;
      width: 350px;
      height: 50px;
      background-color: #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      margin: ${props=>props.margin || '5px auto'};
      padding: 14px;
      
      /* 모바일 환경 */
      @media screen and (max-width : 768px) {
        width: 95%;
      }
    `;
const Input = styled.input`
      border: none;
      height: 30px;
      color : ${props=>props.color};

      &:focus {
        outline: none;
      }

      &::-webkit-input-placeholder {
        font-family: PoppinsSemiBold;
        font-size: 14px;
      }
    `;
    const Button = styled.button`
      font-family: PoppinsSemiBold;
      color: ${(props) => props.color};
      background-color: ${(props) => props.background};
      border: none;
      width: 100px;
      height: 40px;
      border-radius: 4px;
      cursor : pointer;
      &:hover {
        box-shadow: -20000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
      }
    `;

    const ContentBox = styled.div`
    display : flex;
    justify-content : center;  
    flex-direction : column;
    
    `;
export default SignUpSection;
