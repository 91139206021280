import React from "react";
import { useState } from "react";
import styled from "styled-components";
//import {Mint, Approve, CreateAuction, CancelAuction, FinishAuction, Bid, GetAuction, GetBids} from "./js/AuctionFunction.js";

function AuctionComponent(props) {
  //console.log('aa');
  const [Data, setData] = useState({});

  const onChange = (e) => {
    const {name, value} = e.target;
    // console.log('onChange name >', name);
    // console.log('onChange value >', value);
    setData({
        ...Data,
        [name] : value
    });
  }
  return (
    <Main>
      {/* <Section>
        <ColumnDiv>
          <TextDiv>Mint</TextDiv>
          <input name={"mintUri"} onChange={onChange} placeholder="_tokenURI (string)"/>
          <Button onClick={()=>Mint(Data.mintUri)}>Mint</Button>
        </ColumnDiv>
      </Section>
      <Section>
        <ColumnDiv>
          <TextDiv>Approve</TextDiv>
          <input name={"ApproveTokenId"} onChange={onChange} placeholder="tokenId (uint256)"/>
          <Button onClick={()=>Approve(Data)}>Approve</Button>
        </ColumnDiv>
      </Section>
      <Section>
        <ColumnDiv>
          <TextDiv>Create Auction</TextDiv>
          <input name={"CreTokenId"} onChange={onChange} placeholder="_tokenId (uint256)"/>
          <input name={"CrePrice"} onChange={onChange} placeholder="_price (uint256)"/>
          <Button onClick={()=>CreateAuction(Data)}>Create Auction</Button>
        </ColumnDiv>
      </Section>
      <Section>
        <ColumnDiv>
          <TextDiv>Cancel Auction</TextDiv>
          <input name={"CanTokenId"} onChange={onChange} placeholder="_tokenId (uint256)"/>
          <Button onClick={()=>CancelAuction(Data)}>Cancel Auction</Button>
        </ColumnDiv>
      </Section>
      <Section>
        <ColumnDiv>
          <TextDiv>Finish Auction</TextDiv>
          <input name={"FinTokenId"} onChange={onChange} placeholder="_tokenId (uint256)"/>
          <Button onClick={()=>FinishAuction(Data)}>Finish Auction</Button>
        </ColumnDiv>
      </Section>
      <Section>
        <ColumnDiv>
          <TextDiv>Bid Auction</TextDiv>
          <input name={"BidPrice"} onChange={onChange} placeholder="Bid price"/>
          <input name={"BidTokenId"} onChange={onChange} placeholder="_tokenId (uint256)"/>
          <Button onClick={()=>Bid(Data)}>Bid Auction</Button>
        </ColumnDiv>
      </Section>
      <Section>
          <ColumnDiv>
            <TextDiv>Get Auction</TextDiv>
            <input name={"GAucTokenId"} onChange={onChange} placeholder="_tokenId"/>
            <Button onClick={()=>GetAuction(Data)}>Get Auction</Button>
          </ColumnDiv>
      </Section>
      <Section>
          <ColumnDiv>
            <TextDiv>Get Bids</TextDiv>
            <input name={"GBidTokenId"} onChange={onChange} placeholder="_tokenId"/>
            <Button onClick={()=>GetBids(Data)}>Get Bids</Button>
          </ColumnDiv>
      </Section> */}
    </Main>
  );
}
const Main = styled.article`
  background: #a3a3a3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top : 100px;
`;
const Section = styled.section`
  margin: 20px 0;
`;
const FlexDiv = styled.div`
  display: Flex;
`;
const ColumnDiv = styled.div`
  display: Flex;
  flex-direction: column;
`;
const TextDiv = styled.div``;
const Button = styled.button``;
export default AuctionComponent;
