import React from 'react';
import FlexDiv from '../common/styled/FlexDiv.jsx';
import TermsOfUse from './section/TermsOfUse.jsx';
import PrivacyPolicy from './section/PrivacyPolicy.jsx';
import Disclaimers from './section/Disclaimers.jsx';
import styled from 'styled-components';
import { useMediaQuery } from "react-responsive";
function TermsPage(props) {
    const isPc = useMediaQuery({query: "(min-width:768px)"});
    return (
        <FlexDiv background={'#1C1C1C'} flexDirection={isPc?'':'column'}>
            <Section>
                <FlexDiv flexDirection={isPc?'column':''} padding={'160px 0 0 0'}>
                    <Button href="#TermsOfUse">Terms of Use</Button>
                    <Button href="#PrivacyPolicy">Privacy Policy</Button>
                    <Button href="#Disclaimers">Disclaimers</Button>
                </FlexDiv>
            </Section>
            <Section>
                <TermsOfUse/>
                <PrivacyPolicy/>
                <Disclaimers/>
            </Section>
        </FlexDiv>
    );
}
const Section = styled.section`
`;
const Button = styled.a`
    margin : 12px 48px;
    border : none;
    color : #606060;
    cursor : pointer;
    text-decoration : none;
    background-color: rgba( 0, 0, 0, 0 );
    &:hover {
        color : #EEEEEE;
    }
    
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 100px;
        margin : 12px 10px;
    }
`;
export default TermsPage;