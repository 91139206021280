import React, { useCallback, useRef, useMemo, useState } from "react";
import styled from "styled-components";
import Con1Section from "./styled/article/Con1Section.jsx";
import Con2Section from "./styled/article/Con2Section.jsx";
import Con3Section from "./styled/article/Con3Section.jsx";
import FlexDiv from "../common/styled/FlexDiv.jsx";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import RefTarget from "./styled/article/RefTarget.jsx";
import Showcase from "./styled/article/Showcase.jsx";
import { showcaseData } from "../../data/showcaseData.js";

function HomePage(props) {
  const [hideArrow, setHideArrow] = useState(false);

  const ref = useRef(null);
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  const handleHideArrow = useCallback(
    (view) => {
      view ? setHideArrow(true) : setHideArrow(false);
    },
    [setHideArrow]
  );

  const moveToBottom = () => {
    if (ref.current) {
      ref.current.scrollIntoView({
        block: isPc ? "start" : "center",
        behavior: "smooth",
      });
    } else return;
  };

  const [targetRef, sliderInView] = useInView({
    threshold: 1,
  });

  useMemo(() => {
    handleHideArrow(sliderInView);
  }, [handleHideArrow, sliderInView]);

  return (
    <FlexDiv flexDirection={"column"} justify={"flex-start"}>
      <Showcase data={showcaseData} />
      <RefTarget ref={targetRef} />
      <Container3>
        <Con3Section />
      </Container3>
    </FlexDiv>
  );
}
const Container3 = styled.article`
  background: #1c1c1c;
  display: flex;
  justify-content: center;
`;
export default HomePage;
