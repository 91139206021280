import React from 'react';
import styled from "styled-components";

const Section3 = styled.section`
  padding : 330px 0 80px 0;
  font-family: PoppinsMedium;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding : 70vw 0 12vw 0;
  }
`;
const TopBox = styled.div`
  width : 944px;
  font-size: 20px;
  line-height: 30px;
  color: #909090;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 16px;
    line-height: 24px;
    width: 86vw;
  }
`;
const BottomBox = styled.div`
  width : 944px;
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 20px;
    line-height: 30px;
    width: 86vw;
    margin-top: 16px;
  }
`;

function AboutSection3(props) {
    return (
        <Section3>
           <TopBox>
             Our Vision
           </TopBox>
           <BottomBox>
               To bring worldwide mass-market exposure to K-celebrities by partnering them with exceptionally creative digital artists and collaborating to create NFT collections that inspire fans to engage.
           </BottomBox>
        </Section3>
    );
}

export default AboutSection3;