import React from 'react';
import styled from 'styled-components';

function TextLi({children, ...props}) {
    return (
        <Li {...props}>
            {children}
        </Li>
    );
}

const Li = styled.li`
margin : ${props=>props.margin || '24px 0'};
width : 720px;
    ${props=>props.type==='contentS'&&`
    font-family : PoppinsLight;
    font-size: 12px;
    line-height: 22px;
    color: #A0A0A0;
    `}
    ${props=>props.type==='contentM'&&`
    font-family : PoppinsLight;
    font-size: 14px;
    line-height: 26px;
    color: #A0A0A0;
    `}
        
    /* 모바일 환경 */
    @media screen and (max-width : 768px) {
        width : 282px;
    }
`;
export default TextLi;