import React, { forwardRef, useMemo } from "react";
import { useInView } from "react-intersection-observer";

const RefTarget = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        width: "100vw",
        height: "30px",
        background: "#1c1c1c",
      }}
    ></div>
  );
});

export default RefTarget;
