import React from "react";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";

function Loding({ children, ...props }) {
  return (
    <Main>
      <Div>
        <CenterBox>
          <Oval color="green" height={50} width={50} />
        </CenterBox>
        <Text>Waiting for Metamask...</Text>
      </Div>
    </Main>
  );
}
const Main = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  height: 100vh;
  background-color: rgba(61, 61, 61, 0.9);
  width: 100%;
  z-index: 99;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`;
const Text = styled.div`
  color: #fff;
  font-family: RL;
  font-weight: bold;
  text-align: center;
`;
const CenterBox = styled.div`
  width: 50px;
  margin: 30px auto;

  ${(props) =>
    props.type === "deploySuccess" &&
    `
        width : 88px;
    `}
`;

export default Loding;
