import React from 'react';
import styled from "styled-components";
import Section4Diagram1 from "../img/Section4Diagram1-A.png";
import Section4Diagram2 from "../img/Section4Diagram2.png";

const Section4 = styled.section`

  font-family: PoppinsMedium;
/* 모바일 환경 */
@media screen and (max-width : 768px) {
   
}
`;
const TopBox = styled.div`
  padding: 120px 0 160px 0;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    padding: 80px 0;
  }
`;
const Top1Content = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #909090; 

`;
const Top2Content = styled.div`
  font-size: 48px;
  line-height: 72px;
  color: #06C755;
  width: 444px;
  margin: 40px 0 16px 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
    width : 86vw;
  }
`;
const Top3Box = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 26px;
  color: #EEEEEE;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
  }
`;
const Top3Content1 = styled.div`
  font-family: PoppinsRegular;
  width: 444px;
  margin : 0 56px 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
    margin: 0;
  }
`;
const Top3Content2 = styled.div`
  font-family: PoppinsRegular;
  width: 444px;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width : 86vw;
  }
`;
const Diagram1 = styled.img.attrs({
  src: Section4Diagram1 || ""
})`
  width: 944px;
  margin: 80px 0 0 0;
  
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;

/* -------------------------------------------------- */

const BottomBox = styled.div`
  padding: 160px 0 180px 0;
`;
const BottomContentBox = styled.div`
  display: flex;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    display : block;
  }
`;
const BottomContent1 = styled.div`
  color: rgba(6, 199, 85, 1);
  font-size: 48px;
  line-height: 72px;
  width: 444px;
  margin-right: 56px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    font-size: 28px;
    line-height: 42px;
    width : 86vw;
    margin-right: 0;
  }
`;
const BottomContent2 = styled.div`
  font-family: PoppinsRegular;
  font-size: 14px;
  line-height: 26px;
  color: #EEEEEE;
  width: 444px;
  margin-top: 16px;

  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;
const Diagram2 = styled.img.attrs({
  src: Section4Diagram2 || ""
})`
  width: 944px;
  margin: 80px 0 0 0;
    
  /* 모바일 환경 */
  @media screen and (max-width : 768px) {
    width: 86vw;
  }
`;
function AboutSection4(props) {
    return (
        <Section4 id="BecomeACreator">
          <TopBox>
            <Top1Content>
              Join Halliday's
            </Top1Content>
            <Top2Content>
              Become a Creator
            </Top2Content>
            <Top3Box>
              <Top3Content1>
                The world of NFTs and Korean digital art is an exciting creative outlet that is beginning to attract mass-market attention. This new digital outlet offers you potential growth you won’t find anywhere else. However, early adoption is key to getting the most exposure.
                <br/><br/>
                Are you a celebrity but do not know what to create?
              </Top3Content1>
              <Top3Content2>
                Don't worry, that's why we are here. In partnership with renowned digital artists, our team will guide you in creating awesome, brand-focused digital assets that will generate a buzz in the marketplace.
                <br/><br/>
                You’ll continue to earn from your collections every time they’re resold on other marketplaces, with a cut of secondary trade coming back to you, allowing you to benefit for as long as the internet exists.
                <br/><br/>
                Get in touch today to start working with us: info@hallidays.io
              </Top3Content2>
            </Top3Box>
            <Diagram1/>
          </TopBox>
          <BottomBox>
            <BottomContentBox>
              <BottomContent1>
                Contribute to Charity
              </BottomContent1>
              <BottomContent2>
                Once you’ve created your NFT collection, we’ll ask you which charity you would like to donate proceeds to. A portion of every sale on Halliday’s platform is allocated to be donated to charity – it’s your work, and we want to benefit the charities you believe in.
              </BottomContent2>
            </BottomContentBox>
            <Diagram2/>
          </BottomBox>
        </Section4>
    );
}

export default AboutSection4;