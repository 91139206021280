import React from 'react';
import { useSelector } from 'react-redux';
import SaleAuctionComponent from '../components/sale/SaleAuctionComponent.jsx';
import useActions from '../redux/lib/useAction';
import {loding, complete} from '../redux/module/nftBuy.js';

function SaleAuctionContainer() {
    const { loding_type } = useSelector(({ nftBuy }) => ({
        loding_type: nftBuy.type,
      }));
    
      const [Loding, Complete] = useActions(
        [loding, complete],
        []
      );

      console.log("SaleContainer loding_type >>",loding_type);
    return (
        <SaleAuctionComponent
            loding_type={loding_type}
            Loding={Loding}
            Complete={Complete}
        />
    );
}

export default SaleAuctionContainer;