import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import styled, { css, keyframes } from "styled-components";
import FlexDiv from "../../../common/styled/FlexDiv";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import RandingTimer from "../../../common/RandingTimer";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import Web3 from "web3";
import { useState } from "react";
import { loding, complete } from "../../../../redux/module/nftBuy.js";
import hajungwoo_active from "../../img/hajungwoo_desktop.png";
import yura_active from "../../img/yura_desktop.png";
import cheetah_active from "../../img/cheetah_desktop.png";
import gaeko_active from "../../img/gaeko_active.mp4";
import gaeko_mobile_active from "../../img/gaeko_mobile.png";
import hajungwoo_mobile_active from "../../img/hajungwoo_mobile.png";
import yura_mobile_active from "../../img/yura_mobile.png";
import cheetah_mobile_active from "../../img/cheetah_mobile.png";
import mousai_active from "../../img/mousai_desktop.png";
import mousai_mobile_active from "../../img/mousai_mobile.png";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

const imgArr = [
  {
    img: gaeko_active,
    mobile: gaeko_mobile_active,
    status: "DONE",
    link: "/rebirth",
  },
  {
    img: mousai_active,
    mobile: mousai_mobile_active,
    status: "NOW LIVE",
    outLink: "https://mousai.club/",
  },
  {
    img: hajungwoo_active,
    mobile: hajungwoo_mobile_active,
    status: "upcoming",
  },
  { img: yura_active, mobile: yura_mobile_active, status: "upcoming" },
  { img: cheetah_active, mobile: cheetah_mobile_active, status: "upcoming" },
];

const Con2Section = forwardRef((props, ref) => {
  const history = useHistory();

  const status = useSelector((state) => state.nftBuy.type);
  const dispatch = useDispatch();

  const onLoding = useCallback(() => dispatch(loding()), [dispatch]);
  const onComplete = useCallback(() => dispatch(complete()), [dispatch]);

  const [swiper, setSwiper] = useState(null);

  // const web3 = new Web3 (Web3.givenProvider);
  // const Contract = new web3.eth.Contract(abi, CA);

  // const BuyNft = async () => {
  //   const account = await web3.eth.getAccounts();
  //   console.log("account >>>", account[0]);
  //   console.log('BuyNft start >>', status);
  //     Swal.fire({
  //         title: '가격은 0.1 ETH 입니다.',
  //         text : '구매하시겠습니까?',
  //         showCancelButton: true,
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Buy',
  //     }).then((result)=>{
  //         if (result.isConfirmed) {
  //           onLoding();
  //           Contract.methods.safeMint("0x51d4Cd8bE307dF20FFA9482765b37cFA3F495b4a","asd").send({
  //             from : account[0],
  //             value : 1e17
  //           })
  //           .then((receipt) => {
  //             console.log("receipt >>", receipt);
  //             console.log("status >>", receipt.status);
  //             console.log("transactionHash >>", receipt.transactionHash);
  //             if(receipt.status){
  //               Swal.fire({
  //                 icon: "success",
  //                 title: 'Mint 되었습니다.',
  //                 text : `transactionhash >> ${receipt.transactionHash}`,
  //               })
  //             }
  //             onComplete();
  //           })
  //           .catch(()=>{
  //             onComplete();
  //           })
  //         }
  //     })
  // }
  const [viewRef, inView] = useInView({
    threshold: 1.0,
    triggerOnce: true,
  });

  SwiperCore.use([Autoplay]);

  useMemo(() => {
    if (inView && swiper) {
      swiper.slideTo(4, 500);
    }
  }, [inView, swiper]);

  const moveToLink = (url) => {
    if (url) {
      if (url[0] === "/") {
        history.push(url);
      } else {
        window.open("https://mousai.club/", "_blank");
      }
    } else return;
  };
  const [sliderViewRef, sliderInView] = useInView({
    threshold: 0.5,
  });

  const videoEl = document.getElementsByClassName("slide_video");

  useMemo(() => {
    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].play();
    }
  }, [videoEl]);

  const isPc = useMediaQuery({ query: "(min-width:768px)" });
  return (
    <MainDiv ref={viewRef}>
      <FlexDiv
        margin={"0 auto"}
        flexDirection={"column"}
        paddingT={isPc ? "55px" : "40px"}
      >
        <div ref={ref}>
          <TitleDiv>
            <p className="big">NFT STUDIO</p>
            <p className="small">PAST & UPCOMING PROJECTS</p>
          </TitleDiv>
          <StyledSlider
            centeredSlides={true}
            speed={3000}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            loop={true}
            slidesPerView={"auto"}
            spaceBetween={30}
            navigation={false}
            pagination={false}
            scrollbar={false}
            initialSlide={0}
            autoHeight={true}
            grabCursor={true}
            loopedSlides={4}
            onSwiper={(swiper) => setSwiper(swiper)}
            breakpoints={{
              768: {
                spaceBetween: 0,
              },
            }}
          >
            {imgArr.map((data, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                  onClick={() =>
                    moveToLink(data.link ? data.link : data.outLink)
                  }
                >
                  {isPc && data.link ? (
                    <SliderVideo
                      className="slide_video"
                      idx={idx}
                      alt="slider_video"
                      link={data.link}
                      autoPlay={true}
                      muted
                      loop={true}
                    >
                      <source src={data.img}></source>
                    </SliderVideo>
                  ) : (
                    <SliderImg
                      className="img"
                      src={isPc ? data.img : data.mobile}
                      idx={idx}
                      alt="slider_img"
                      link={data.link}
                      outLink={data.outLink}
                    ></SliderImg>
                  )}
                  {(data.status === "DONE" || data.status === "NOW LIVE") && (
                    <IconWrapper
                      className="icon_wrapper"
                      status={data.status}
                      color={data.status === "DONE" ? "#faff0c" : "#06c555"}
                    >
                      <Icon status={data.status}></Icon>
                      <span>{data.status}</span>
                    </IconWrapper>
                  )}
                </SwiperSlide>
              );
            })}
          </StyledSlider>
        </div>
      </FlexDiv>
    </MainDiv>
  );
});

const activeAnimation = keyframes`
from {
  opacity:0.3;
} to {
  opacity: 0.8;
}
`;

const notActiveAnimation = keyframes`
from {
  opacity: 0.8;
} to {
  opacity:0.3;
}
`;

const textAnimation = keyframes`
0% {
  opacity: 0.5;
} 10% {
  opacity: 0.6;
} 20% {
  opacity: 0.7;

} 30% {
  opacity: 0.8;

} 50% {
  opacity: 0.9;

} 60% {
  opacity: 1;
}70% {
  opacity: 0.9;
}80% {
  opacity: 0.8;
} 90% {
  opacity: 0.7;
} 100% {
  opacity: 0.6;
}
`;

const MainDiv = styled.div`
  background: #1c1c1c;
  width: 100vw;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  color: #ffffff;
  padding: 0 0 28px 142px;
  .big {
    font-family: "RBDI";
    font-size: 50px;
    font-weight: 900;
    margin: 0;
    margin-right: 20px;
  }
  .small {
    font-family: "RBLI";
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 0 28px 36px;
    .big {
      font-size: 22px;
      margin-right: 10px;
    }
    .small {
      font-size: 12px;
      margin-bottom: 2px;
    }
  }
`;

const StyledSlider = styled(Swiper)`
  height: 400px;
  width: 100%;
  padding: 0 80px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  .swiper-wrapper {
    .swiper-slide {
      width: 720px;
      display: flex;
      justify-content: center;
      .img,
      .slide_video {
        animation: ${notActiveAnimation} 2s;
        animation-fill-mode: forwards !important;
      }
      .icon_wrapper {
        align-items: center;
        div {
          opacity: 0.5;
        }
        span {
          opacity: 0.5;
        }
      }
    }
    .swiper-slide-active {
      .img,
      .slide_video {
        animation: ${activeAnimation} 3s;
        animation-fill-mode: forwards !important;
      }
      .icon_wrapper {
        align-items: center;
        div {
          animation: ${textAnimation} infinite 1s;
          animation-fill-mode: forwards !important;
        }
        span {
          animation: ${textAnimation} infinite 1s;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 350px;
    .swiper-wrapper {
      .swiper-slide {
        width: 250px;
        .img {
          width: 100%;
        }
      }
      .swiper-slide-active {
        width: 250px;
        .img {
          width: 100%;
        }
      }
    }
  }
`;

const SliderImg = styled.img`
  height: 335px;
  position: relative;
  ${(props) => (props.link || props.outLink) && "cursor: pointer;"};

  @media screen and (max-width: 768px) {
    height: 300px;
    width: 200px;
  }
`;

const SliderVideo = styled.video`
  height: 325px;
  position: relative;
  ${(props) => props.link && "cursor: pointer;"};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 80px;
  display: flex;

  span {
    color: ${(props) => props.color};
    font-size: 18px;
    font-family: RBLI;
    margin-left: 10px;
    font-weight: 900;
  }
  @media screen and (max-width: 768px) {
    top: 10px;
    right: 15%;
    letter-spacing: -0.5px;
    span {
      font-size: 12px;
    }
  }
`;

const Icon = styled.div`
  position: relative;
  background: ${(props) => (props.status === "DONE" ? "#faff0c" : "#06c555")};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  @media screen and (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
`;
export default Con2Section;
