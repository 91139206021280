import React from "react";
import styled from "styled-components";
import detail_logo from "./img/mousai_detail_logo.png";
import art_background from "./img/art_background.png";
import ex_background from "./img/ex_background.png";
import art_img from "./img/art_img.png";
import card from "./img/card.png";
import muse_background from "./img/muse_background.png";
import muse_intro from "./img/muse_intro.png";
import mousai1 from "./img/mousai1.png";
import mousai2 from "./img/mousai2.png";
import mousai3 from "./img/mousai3.png";
import mousai4 from "./img/mousai4.png";
import mousai5 from "./img/mousai5.png";
import mousai6 from "./img/mousai6.png";
import mousai7 from "./img/mousai7.png";
import mousai8 from "./img/mousai8.png";
import mousai9 from "./img/mousai9.png";

const mousaiList = [
  mousai1,
  mousai2,
  mousai3,
  mousai4,
  mousai5,
  mousai6,
  mousai7,
  mousai8,
  mousai9,
];

export default function Mousai() {
  return (
    <PageContainer>
      <LogoSection>
        <LogoImg></LogoImg>
      </LogoSection>
      <Background>
        <StorySection>
          {/* <StoryBack></StoryBack> */}
          <Story>
            <StoryTitle>MOUSAI STORY</StoryTitle>
            <StoryText>
              The year is unknown. On the horizon a streak of light, and below
              it 10,000 MOUSAI stepping foot into Earth’s atmosphere.
            </StoryText>
            <StoryMainWrapper>
              <StoryMain>
                <MainTitle>
                  WHAT IF APOLLO, DIONYSUS, AND THE NINE MUSES EXISTED TODAY?
                </MainTitle>
                <StoryText>
                  MOUSAI’s appearance marks the beginning of a prominent period
                  in mankind’s art history. Leaving remarkable achievements in
                  art, music, dance as well as fashion and pop-culture, they
                  have since fallen in love with K-pop (and of course, K-soju).
                </StoryText>
              </StoryMain>
              <StoryMain>
                <MainTitle>
                  INSPIRING YOUNG ARTISTS TO LEAD THE NEW WEB3.0 REVOLUTION{" "}
                </MainTitle>
                <StoryText>
                  Rich and famous, their garish displays have caught the
                  attention of millions around the world, and they decide to
                  disappear...into Arcadia: an island in Greece, located at the
                  southern edge of the Aegean Sea.
                </StoryText>
              </StoryMain>
            </StoryMainWrapper>
          </Story>
        </StorySection>
        <ArtSection>
          {/* <ArtBack></ArtBack> */}
          <ArtContents>
            <ArtImg></ArtImg>
            <ArtDescribe>
              <ArtTitle>
                <TitleList>HAND</TitleList>
                <TitleList>DRAWN</TitleList>
                <TitleList>ART</TitleList>
              </ArtTitle>
              <ArtContentsList>
                <ArtContent>
                  <ArtContentTitle>SPECIAL EFFECTS</ArtContentTitle>
                  <ArtContentText>
                    Visual effects to illuminate MOUSAI
                  </ArtContentText>
                </ArtContent>
                <ArtContent>
                  <ArtContentTitle>SYMBOL OF NINE MOUSAI</ArtContentTitle>
                  <ArtContentText>
                    Unique symbols and color of the eyes reflects their
                    affiliation to the nine muses
                  </ArtContentText>
                </ArtContent>
                <ArtContent>
                  <ArtContentTitle>SOOM /SU:M/</ArtContentTitle>
                  <ArtContentText>
                    Mana-like magical Dionysus energy that fuel artistic
                    inspiration
                  </ArtContentText>
                </ArtContent>
                <ArtContent>
                  <ArtContentTitle>FASHION</ArtContentTitle>
                  <ArtContentText>
                    From buzzy streetwear to luxury fashion, MOUSAI love to
                    express themselves
                  </ArtContentText>
                </ArtContent>
              </ArtContentsList>
            </ArtDescribe>
          </ArtContents>
        </ArtSection>
        <CardSection>
          <CartList>
            <Card></Card>
            <Card></Card>
            <Card></Card>
            <Card></Card>
            <Card></Card>
          </CartList>
          <CartText>
            <StoryTitle>UNIQUE DRAWN ART</StoryTitle>
            <CardDescribe>
              The year is unknown. On the horizon a streak of light, and below
              it 10,000 MOUSAI stepping foot into Earth’s atmosphere.
            </CardDescribe>
          </CartText>
        </CardSection>
      </Background>
      <SectionWrapper>
        <MuseBackground>
          <StoryTitle>SYMBOL OF MOUSAI</StoryTitle>
          <CardDescribe>
            The year is unknown. On the horizon a streak of light, and below it
            10,000 MOUSAI stepping foot into Earth’s atmosphere.
          </CardDescribe>
        </MuseBackground>
        <MuseContent>
          <MuseEmpty></MuseEmpty>
          <MuseText>
            <DrawnArtImg>
              <ArtTitle>
                <TitleList>HAND</TitleList>
                <TitleList>DRAWN</TitleList>
                <TitleList>ART</TitleList>
              </ArtTitle>
            </DrawnArtImg>
            <MuseCardList>
              {mousaiList.map((mousai, idx) => {
                return <MuseCard key={idx} src={mousai}></MuseCard>;
              })}
            </MuseCardList>
          </MuseText>
        </MuseContent>
      </SectionWrapper>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  background: #34000a;
  overflow-x: hidden;
`;

const LogoSection = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImg = styled.div`
  height: 70%;
  width: 70%;
  background: url(${detail_logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StorySection = styled.section`
  font-family: ABEL;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

// const StoryBack = styled.div`
//   width: 150%;
//   height: 100%;
//   position: absolute;
//   background: #210303;
//   top: 0;
//   left: -20%;
//   transform: rotate(-16.79deg);
//   z-index: 2;
// `;

const Story = styled.div`
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StoryTitle = styled.h3`
  font-size: 4rem;
  line-height: 5rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: #fccfb9 1px 0 10px;
  margin: 0 0 0.9rem 0;
`;

const StoryText = styled.span`
  color: #ffffff;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.2em;
  line-height: 1.5rem;
  width: 29rem;
`;

const StoryMainWrapper = styled.ul`
  width: 29rem;
  margin-top: 18px;
`;

const StoryMain = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainTitle = styled.h4`
  color: #ffffff;
  text-shadow: #fccfb9 1px 0 10px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 30px;
  width: 19rem;
  margin: 18px 0;
`;

const ArtSection = styled.section`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const ArtBack = styled.div`
//   background-image: url(${art_background});
//   background-size: contain;
//   background-position: center;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
// `;

const Background = styled.div`
  background: url(${ex_background});
  background-size: 230rem 230rem;
  background-repeat: no-repeat;
  background-position: center;
`;

const ArtContents = styled.div`
  width: 76rem;
  height: 48.3rem;
  background: url(${art_background});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: start;
`;

const ArtImg = styled.div`
  background: url(${art_img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left 0 bottom 0;
  width: 44.8rem;
  height: 48.3rem;
`;

const ArtDescribe = styled.div`
  height: 100%;
`;

const ArtTitle = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 1.1rem 0;
`;

const TitleList = styled.li`
  font-size: 7rem;
  line-height: 6.2rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: #fccfb9 1px 0 10px;
`;

const ArtContentsList = styled.ul`
  height: 100%;
  list-style-type: none;
  width: 25rem;
`;

const ArtContent = styled.li`
  margin-bottom: 2.3rem;
`;

const ArtContentTitle = styled.h3`
  font-weight: 400;
  font-size: 1.7rem;
  font-family: BBO;
  line-height: 2.3rem;
  color: #ffffff;
`;

const ArtContentText = styled.p`
  color: #ffffff;
  font-family: ABEL;
  font-size: 0.8rem;
  line-height: 1.6rem;
  letter-spacing: 0.2em;
`;

const CardSection = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CartList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  li {
    &:first-child {
      top: 2rem;
      left: 6rem;
    }
    &:last-child {
      top: 2rem;
      right: 6rem;
    }
    &:nth-child(2) {
      top: 1rem;
      left: 3rem;
    }
    &:nth-child(4) {
      top: 1rem;
      right: 3rem;
      z-index: 2;
    }
    &:nth-child(2n) {
      width: 16rem;
      height: 22rem;
    }
    &:nth-child(3) {
      width: 18rem;
      height: 25rem;
      z-index: 3;
    }
  }
`;

const Card = styled.li`
  width: 15rem;
  height: 21rem;
  background: url(${card});
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
`;

const CartText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
`;

const CardDescribe = styled.p`
  width: 29rem;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.5rem;
  letter-spacing: 0.2em;
  text-align: center;
  margin-top: 2.9rem;
`;

const MuseBackground = styled.section`
  width: 100vw;
  height: 100vh;
  background: url(${muse_background});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SectionWrapper = styled.div`
  width: 100vw;
  height: 200vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MuseContent = styled.div`
  width: 87.3rem;
  height: 180vh;
  border: 1px solid white;
  border-radius: 10px;
`;

const MuseText = styled.div`
  width: 87.3rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  jusyify-content: space-around;
`;

const MuseEmpty = styled.div`
  width: 87.3rem;
  height: 90vh;
  background: transparent;
`;

const DrawnArtImg = styled.div`
  width: 85rem;
  height: 45vh;
  background: url(${muse_intro});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 5.8rem;
`;

const MuseCardList = styled.ul`
  list-style-type: none;
  width: 85rem;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const MuseCard = styled.li`
  height: 40vh;
  width: 11%;
  background: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
