import React, { useState } from "react";
import styled from "styled-components";
import FlexDiv from "../common/styled/FlexDiv";
import { useHistory } from "react-router-dom";
import LogoImg2 from "../common/img/logo@2x.png";
import Icon from "../common/Icon";
import SignUpSection from "./styled/SignUpSection.jsx";
import LoginSection from "./styled/LoginSection.jsx";
import AuthenticationSection from "./styled/AuthenticationSection.jsx";
import SetProfileSection from "./styled/SetProfileSection.jsx";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

function LoginPage({ type }) {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies();
  const isPc = useMediaQuery({ query: "(min-width:768px)" });

  useEffect(() => {
    const authorization = cookies.authorization;
    //console.log('로그인 상태 체크 aa >>', authorization)
    if (authorization) {
      history.goBack();
    }
  }, []);

  return (
    <FlexDiv
      alignItems={"center"}
      height={"100vh"}
      background={"rgba(0, 0, 0, 0.9)"}
      color={"#FFFFFF"}
    >
      <ContentBox>
        <FlexDiv
          justify={"left"}
          alignItems={"center"}
          width={isPc ? "350px" : "80vw"}
          margin={"0 auto"}
        >
          {isPc && <Icon src={LogoImg2} width={"140px"} />}
        </FlexDiv>
        {type === "login" && <LoginSection />}
        {type === "signUp" && <SignUpSection />}
        {type === "authentication" && <AuthenticationSection />}
        {type === "setProfile" && <SetProfileSection />}
      </ContentBox>
    </FlexDiv>
  );
}
const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
  height: 600px;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  background: rgba(66, 66, 66, 0.8);

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    margin: 10vh 0;
  }
`;

export default LoginPage;
