import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Check_suc from "../../../common/img/check_suc.png";
import Check_err from "../../../common/img/check_err.png";

export default function Con3Section(props) {
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(0); // 0 : 미입력, 1 : 가입가능, 2 : 아이디 중복, 3 : 이메일 양식 오류

  function CollectEmail() {
    if (emailStatus === 1) {
      // 이메일 양식 pass
      axios
        .post(process.env.REACT_APP_API_URL + "/user/email/collection", {
          email: email,
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            // 등록 성공
            alert("Registration successfully completed");
            //window.location.reload();
          } else if (res.status === 204) {
            // 이메일 중복
            alert("Email is Already Registered");
          }
        })
        .catch((err) => {
          alert("오류");
          console.log("/email/collectEmail err >>>", err);
        });
    } else {
      // 미입력, 이메일 양식 오류
      alert("Please check your e-mail.");
    }
  }

  const onChange = async (event) => {
    const regex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const {
      target: { value },
    } = event;
    setEmail(value);
    // 가입 가능한 ID
    if (regex.test(value)) {
      setEmailStatus(1);
    } else if (value.length === 0) {
      setEmailStatus(0);
    } else {
      setEmailStatus(3);
    }
  };
  return (
    <MainDiv>
      <TopSection>
        <TopContent>Look out for the next drop!</TopContent>
      </TopSection>
      <CenterSection>
        <CenterContent>
          Subscribe for the latest news, exclusive drops & our social
          activities.
        </CenterContent>
      </CenterSection>
      <BottomSection>
        <BottomInputBox>
          <BottomInput
            type="text"
            placeholder="Email Address"
            onChange={onChange}
          />
          <CheckIconBox>
            {emailStatus === 0 ? (
              ""
            ) : emailStatus === 1 ? (
              <IconSuc />
            ) : (
              <IconErr />
            )}
          </CheckIconBox>
        </BottomInputBox>
        <StyledButton onClick={() => CollectEmail()}>Subscribe</StyledButton>
      </BottomSection>
    </MainDiv>
  );
}
const MainDiv = styled.section`
  min-width: 100vw;
  justify-content: center;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    min-width: 100vw;
  }
`;
const TopSection = styled.div`
  display: flex;
  justify-content: center;
`;
const TopContent = styled.div`
  font-family: RBL;
  font-size: 48px;
  line-height: 72px;
  color: #06c755;
  margin: 120px 0 0 0;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin: 80px auto 0;
  }
`;
const CenterSection = styled.div`
  display: flex;
  justify-content: center;
`;
const CenterContent = styled.div`
  font-family: RL;
  font-size: 18px;
  line-height: 24px;
  color: #eeeeee;
  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 86vw;
    margin: 10vw auto;
    text-align: center;
  }
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0 150px 0;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    display: block;
    padding: 32px 0 100px 0;
  }
`;
const BottomInputBox = styled.div`
  width: 320px;
  height: 52px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 84vw;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
`;
const BottomInput = styled.input`
  border: none;
  width: 230px;
  height: 90%;
  &:focus {
    outline: none;
  }

  font-family: RL;
  font-size: 14px;

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    width: 70%;
    ::-webkit-input-placeholder {
      text-align: center;
      padding-left: 60px;
    }
  }
`;
const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-family: RBD;
  color: #ffffff;
  width: 120px;
  height: 52px;
  background: #06c755;
  margin: 0 0 0 8px;

  &:hover {
    box-shadow: -1000px 0 0 0 rgba(0, 0, 0, 0.4) inset;
    cursor: pointer;
  }

  /* 모바일 환경 */
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    font-size: 14px;
    width: 84vw;
    height: 56px;
    -ms-transform: translateX(-50%);
    transform: translateX(9.5%);
  }
`;
const CheckIconBox = styled.div`
  width: 20px;
  height: 20px;
`;
const IconSuc = styled.img.attrs({
  src: Check_suc || "",
})`
  height: 14px;
  margin: 19px 0;
`;
const IconErr = styled.img.attrs({
  src: Check_err || "",
})`
  height: 18px;
  margin: 17px 0;
`;
